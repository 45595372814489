const discountReducer = (
    state = { discounts: [], loading: false, error: false, uploading: false, updateLoading: false },
    action) => {

        switch (action.type) {
            case 'DUPLOAD_START':
                return { ...state, error: false, uploading: true };
            case 'DUPLOAD_SUCCESS':
                return { ...state, discounts: [action.data, ...state.discounts], uploading: false, error: false };
            case 'DUPLOAD_FAIL':
                return { ...state, uploading: false, error: true }

            //belongs to discounts.jsx
            case 'DRETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'DRETREIVING_SUCCESS':
                return { ...state, discounts: action.data, loading: false, error: false };
            case 'DRETREIVING_FAIL':
                return { ...state, loading: false, error: true };

            case 'DDOWNLOAD_START':
                return { ...state, loading: true, error: false};
            case 'DDOWNLOAD_SUCCESS':
                return { ...state, discounts: action.data, loading: false, error: false };
            case 'DDOWNLOAD_FAIL':
                return { ...state, loading: false, error: true }; 


            //belongs to discounts.jsx
            case 'DCOMMENT_START':
                return { ...state, loading: true, error: false};
            case 'DCOMMENT_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'DCOMMENT_FAIL':
                return { ...state, loading: false, error: true };


            case "DUPDATING_START":
                return {...state,updateLoading: true, error: false};

            case "DUPDATING_SUCCESS":
                //localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return {...state, updateLoading: false, error: false}

            case "DUPDATING_FAIL":
                return {...state,updateLoading: true, error: true}

            case 'DDELETE_START':
                return { ...state, loading: true, error: false};
            case 'DDELETE_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'DDELETE_FAIL':
                return { ...state, loading: false, error: true };
                
            default:
                return state;
        }
    };

    
export default discountReducer;