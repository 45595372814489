import React, {useState,useRef,useEffect} from 'react';
import "./DiscountShare.css";

import { UilScenery, UilPlayCircle, UilLocationPoint, UilSchedule, UilTimes } from "@iconscout/react-unicons";
import {useDispatch,useSelector} from "react-redux";
import {uploadDiscount, uploadImage} from "../../actions/UploadAction";
import { v4 } from "uuid";
import {defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo} from '../standardImages/StandardImages'


const DiscountShare = ({location, choosePopUp}) => {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.postReducer.loading);
    const [image,setImage] = useState(null);
    const [profilePicture,setProfilePicture] = useState(null);
    const desc = useRef();
    const location1 = useRef();
    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;

    //handle image changes
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setImage(img);
        }
    };

    const imageRef = useRef();

    //handle post upload
    const handleUpload = async (event) => {
        event.preventDefault();

        if (image === null || image === undefined) {
            return;
        }

        //post data
        const newDiscount = {
            ownerId: user._id,
            businessName: user.businessName,
            description: desc.current.value,
            location: location1.current.value
        };

        if(newDiscount.description === "" ){
            return
        }

        if (image){
            const fileName = "posts/images/" + v4() + image.name;
            const imageDat = {
                fileName : fileName,
                file : image,
            }
            //newPost.image = fileName;
            try{
                //dispatch(uploadImage(imageDat))
                const imageUrl = await uploadImage(imageDat);
                imageUrl().then((url) =>{
                    newDiscount.image = url;
                    
                    dispatch(uploadDiscount(newDiscount))
                    choosePopUp(1)
                })
            }catch (err) {
                console.error(err)
            }
        }else{
            newDiscount.image = "";
            dispatch(uploadDiscount(newDiscount))
            choosePopUp(1)
        }
        resetShare();
    };

    const resetShare = () => {
        setImage(null);
        desc.current.value = "";
        location1.current.value = "";
        imageRef.current.value = "";
    };


  return (
    <div className="DiscountShare">
        <div className="forum-close-button">
            <div className="header-post-create">
                <h6>Kampanya Oluştur</h6>
            </div>

            <img src={CloseButton} alt="" onClick={() => choosePopUp(1)}/>
        </div>

        <div className="post-create-menu">
            
            

            <div className="post-photo-area">
                {!image 
                    ? (<> <img className="photo-image" src={PlusLogo} alt="" onClick={() => imageRef.current.click()}/>
                        <p>Medya, Fotoğraf, Video ekleyin</p> </>) 

                : (
                    <div className="previewImage">
                        <UilTimes className="close-btn-preview" onClick={() => {
                                                          setImage(null)
                                                        imageRef.current.value = ""}} />
                        <img src={URL.createObjectURL(image)} alt="preview" />
                    </div>
                )}
                
                <div style={{display: "none"}}>
                    <input type="file" ref={imageRef} onChange={onImageChange} required />
                </div>
                
            </div>

            <div className="post-desc-area">
                <h6>Göderiniz İçin</h6>
                <textarea type="text" className="post-desc settings-profile-textarea" placeholder="Buraya yazabilirsiniz" required
                ref = {desc}/>
            </div>

            <div className="post-location-area">
                <h6>Konum Girin</h6>
                <input type="text" className="post-location settings-profile-birthday" placeholder="konum" required ref={location1}/>
            </div>

            <button className="button post-share-btn" onClick={handleUpload} disabled={loading}>Paylaş</button>
        </div>
    </div>
  )
}

export default DiscountShare