import React, {useState,useRef,useEffect} from 'react';
import "./FormShare.css";
import {useDispatch,useSelector} from "react-redux";

import {defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo} from '../standardImages/StandardImages'

import {uploadImage,uploadForumPost} from "../../actions/UploadAction";

import { UilTimes } from "@iconscout/react-unicons";
import { v4 } from "uuid";

const FormShare = ({location, choosePopUp}) => {
  const upperTitle = useRef();
  const lowerTitle = useRef();
  const message = useRef();
  const media = useRef();
  const [image,setImage] = useState(null);
  const loading = useSelector((state) => state.forumReducer.loading);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.authReducer.authData);


  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]){
        let img = event.target.files[0];
        setImage(img);
    }
  };


    const handleUpload = async (event) => {
      event.preventDefault();

      if (image === null || image === undefined) {
        return;
    }

      //post data
      const newPost = {
          ownerId: user._id,
          username: user.username,
          description: message.current.value,
          upperTitle: upperTitle.current.value,
          lowerTitle: lowerTitle.current.value,
      };

      if(newPost.description === "" || newPost.upperTitle === "" ){
        return
    }

      if (image){
        const fileName = "forum/images/" + v4() + image.name;
        const imageDat = {
            fileName : fileName,
            file : image,
        }
        //newPost.image = fileName;
        try{
            //dispatch(uploadImage(imageDat))
            const imageUrl = await uploadImage(imageDat);
            imageUrl().then((url) =>{
                newPost.image = url;
                dispatch(uploadForumPost(newPost))
                choosePopUp(4)
            })
        }catch (err) {
            console.error(err)
        }
      }else{
          newPost.image = "";
          dispatch(uploadForumPost(newPost))
          choosePopUp(4)
      }
      resetShare();
    
    };

    const resetShare = () => {
      setImage(null);
      message.current.value = "";
      upperTitle.current.value = "";
      lowerTitle.current.value = "";
      media.current.value = "";
    };

  return (
    <div className="FormShare">
        <div className="forum-close-button">
          <div className="header-activity-create">
              <h6>Forum Oluştur</h6>
          </div>
            <img src={CloseButton} alt="" onClick={() => choosePopUp(4)}/>
        </div>
        <div className="forum-create-menu">
            

            <div className="forum-write-area"> 
                <input className="upper-title settings-profile-birthday" type="text" placeholder="Başlık" ref={upperTitle}/>
                <input className="lower-title settings-profile-birthday" type="text" placeholder="Alt Başlık" ref={lowerTitle}/>
                <textarea className="forum-desc settings-profile-textarea" type="text" placeholder="Buraya yazabilirsiniz" ref={message}/>
            </div>

            <div className="forum-media-area">
              
                {!image 
                  ? (<> <img className="form-photo-image" src={PlusLogo} alt="" onClick={() => media.current.click()}/>
                      <p>Medya, Fotoğraf, Video ekleyin</p> </>) 

              : (
                  <div className="previewImage">
                      <UilTimes className="close-btn-preview" onClick={() => {
                        setImage(null)
                        media.current.value = ""}} />
                      <img src={URL.createObjectURL(image)} alt="preview" />
                  </div>
              )}

              <div style={{display: "none"}}>
                <input type="file" ref={media} onChange={onImageChange} required />
              </div>

            </div>

            
            <button className="button post-share-btn" onClick={handleUpload} disabled={loading}>Paylaş</button>

        </div>
        
    </div>
  )
}

export default FormShare