import React from "react";
import { Modal, useMantineTheme } from "@mantine/core";
import SearchBar from "../searchBar/SearchBar.jsx";


/*<br/>
            <br/>
            <br/>
            <br/>
            <br/>*/

const FollowersModal = ({ modalOpened, setModalOpened, location }) => {
    const theme = useMantineTheme();
    return (
        <Modal overlayColor={
            theme.colorScheme === "dark" 
                ? theme.colors.dark[9]
                : theme.colors.gray[2]
        }
        overlayOpacity = {0.55}
        overlayBlur = {3}
        size = "55%"
        opened = {modalOpened}
        onClose = {() => setModalOpened(false)}
        >
            <SearchBar location={location} />
        </Modal>
    );
};

export default FollowersModal;