import React, { useState } from 'react';
import "./BusinessAuth.css";
import { BusinessLogo,AuthPictureBs,AmazoiLogoBs,EyeBs,PersonBs,ClosedEyeBs } from "../../../components/standardImages/StandardImages";

import { logInBusiness,signUp } from "../../../actions/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";

const BusinessAuth = () => {
    const initialState = {
        email: "",
        password: "",
    };

    const loading = useSelector((state) => state.authReducer.loading);
    const error = useSelector((state) => state.authReducer.error);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState(initialState);
    const [passwordVisibility,setPasswordVisibility] = useState(false);

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(logInBusiness(data,navigate));  
    }

    const handleVisibility = (e) => {
        e.preventDefault();
        setPasswordVisibility((prevState) => !prevState)
    }

    return (
        <div className="Auth">
            <div id="upper-picture">
            <img src={AuthPictureBs} alt="" />
            </div>

            <div className="">
            </div>


            <div className="middle">
                <div className="">
                        </div>
                        
                <div className="main-panel">
                    <div className="logo-and-quote">
                        
                        <img className="Logo" src={BusinessLogo} alt="" />
                        
                        <span className="businessQuote">
                        Kafelerin ortak adresi...
                        </span>
                    </div>
                    
                    <div className="to-hr-page ">
                            <Link className="link" to="../auth">
                                <span className="hr-circle">
                                    <p className="hr-circle-p">St</p>
                                </span>
                            </Link>
                        </div>
                    

                    <div className="inputArea">
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="input-area">
                                <div className="business-input-container">
                                    <p>E- Posta Adresi</p>
                                    <input required type="text" 
                                    className="emailInput" 
                                    placeholder="example@hacettepe.edu.tr"
                                    name="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    />
                                </div>
                                <img className="svg-img" src={PersonBs}/>
                            </div>    
                            <div className="input-area">
                                <div className="business-input-container">
                                    <p>Şifre</p>
                                    <input required type={passwordVisibility ? "text" : "password"} 
                                        className="passwordInput" 
                                        placeholder="......"
                                        name="password"
                                        value={data.password}
                                        onChange={handleChange}
                                        />
                                    </div>
                                    <img className="svg-img" src={passwordVisibility ? ClosedEyeBs : EyeBs} onClick={handleVisibility}/>
                            </div> 

                            <span 
                            style={{
                                color : "red",
                                fontSize: "12px",
                                alignSelf: "flex-end",
                                marginRight: "5px",
                                display: error ? "block" : "none",
                            }}>
                                *Eposta veya şifre yanlış
                            </span>
                            
                            <div className="login-btn">
                                <button
                                    className="businessButton infoButton"
                                    type="submit"
                                    disabled={loading}>
                                        {loading ? "Loading..." : "Giriş"}
                                </button>
                            </div>
                        </form>
                        <br/>
                        <div className="auth-links">
                        <Link className="business-link-font" to="../reset-password">Şifremi unuttum</Link>
                        <Link className="business-link-font" to="../business/register">Yeni kullanıcı</Link>
                        </div>
                    </div>

                    
                    
                </div>
                    <a href="https://www.amazoisoftware.com/">
                        <div className="logo">
                            <img src={AmazoiLogoBs} alt=""/>
                        </div>
                    </a>
                </div>


            <div className=""></div>
        </div>
    )
};

export default BusinessAuth;