import React, {useRef,useState,useEffect} from 'react';
import "./ModalDiscount.css";

import * as StandardImages from '../standardImages/StandardImages'
/*
import Comment from '../../img/comment.png';
import Share from '../../img/share.png';
import Heart from '../../img/like.png';
import Dislike from '../../img/notlike.png';*/
import moment from 'moment';

import ShareModal from '../shareModal/ShareModal';
import EditDiscountModal from '../editDiscountModal/EditDiscountModal'

import { likeDiscount } from '../../api/DiscountRequests';
import { sendDiscountComment } from '../../actions/DiscountAction';

import { useSelector, useDispatch } from 'react-redux';
import Comment from '../comment/Comment'

import { Link } from "react-router-dom"


/*
    photo can be max sized

    psot area = max-height: 592px;

    border-bottom-right-radius: 2rem;
*/

const ModalDiscount = ({data}) => {
    const commentRef = useRef('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const [liked,setLiked] = useState(data.likes.includes(user._id));
    const [likes,setLikes] = useState(data.likes.length)
    const [commentButton,setButtonPressed] = useState(false);
    const comments = data.comments
    //const uploaded = new Date(data.createdAt._nanoseconds);

    const [modalOpened1,setModalOpened1] = useState(false);
    const [modalOpened2,setModalOpened2] = useState(false);

    const handleLike = () => {
        likeDiscount(data.discountId,{
            ownerId: user._id,
            mediaUrl: user.profilePicture || "",
            username: user.isBusiness ? user.businessName : user.username,
        });
        setLiked((prev) => !prev);
        liked ? setLikes((prev) => prev-1) : setLikes((prev) => prev + 1)
    };

    const handleCommentButton = () => {
        setButtonPressed((prev)=> !prev);
    }

    const handleComment = () => {
        setButtonPressed(false);

        const comment = {
            ownerId : user._id,
            username : user.isBusiness ? user.businessName : user.username,
            description : commentRef.current.value,
            mediaUrl: user.profilePicture || ""
        }
        try {
            dispatch(sendDiscountComment(data.discountId,comment))
            comments.push(comment);
        }catch (err){
                console.error(err);
        } 
    }

  return (
    <div className="ModalDiscount">
    {data.mediaUrl ? (<span className="modal-discount-photo">
            <span className="dated">
                {moment(data.timestamp).fromNow()}
            </span>
            <img 
                src={data.mediaUrl ? data.mediaUrl : ""}
                alt= "" 
                /> 
        </span>) : ""}
        
        

        <div className="modal-info-area">
            <div className="modal-detail">
                <span>
                <Link className="link" to={"../profile/"+data.ownerId} >
                <b className="username">{data.username} </b>
            </Link>
                </span>
                <span className="modal-description">{data.description}</span>
            </div>
            <span style={{ color: "var(--gray)",fontSize:"12px"}}>
                {likes === 0 ? "Bunu ilk beğenen sen ol" : (likes)+" likes" }
            </span>

        </div>

        

        

        <div className="modal-postReact">
            <img 
                src={liked ? StandardImages.Heart : StandardImages.Dislike}
                alt=""
                style={{ cursor:"pointer"}}
                onClick={handleLike}
            />
            <img 
                src={StandardImages.Comment} 
                alt= "" 
                style={{ cursor:"pointer"}}
                onClick={handleCommentButton} 
            />
            <div className="post-edit-delete">
                {user._id === data.ownerId ? (<>
                    <img className="shareBtn" src={StandardImages.Pencil} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened2((prev) => !prev)} />
                </>
                ) : ""} 
                <img className="shareBtn" src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened1((prev) => !prev)} />
            </div>  
        </div>
        
        {commentButton 
            ? <div className="writeComment">
                <span className="comment-user-title" onClick={handleCommentButton} style={{cursor:"pointer"}}><b>{user.username} </b></span>
                <input ref={commentRef} className="TextArea"/>
                <button 
                    onClick={handleComment} 
                    className="button comment-submit"
                    type="submit"
                >send</button>
            </div> 
            : ""}

            { comments.length > 0 && (
                <div className="comments">
                    <div className="comments-inside">
                        { comments.map((comment,id) => {
                            return <Comment key={id} data={comment} />
                            })
                        }
                    </div>
                </div>
                )}

          <ShareModal modalOpened={modalOpened1} setModalOpened={setModalOpened1} data={ "https://www.geyikapp.com:3000/discount/"+ data.discountId } />
        <EditDiscountModal modalOpened={modalOpened2} setModalOpened={setModalOpened2} data={data} />

    </div>
  );
};

export default ModalDiscount