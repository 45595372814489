import React, {useEffect,useState,useRef} from 'react';
import {useSelector,useDispatch} from 'react-redux';
import "./Chats.css";
import {getUserChats} from '../../actions/ChatActions';

import { defaultProfileImage,NewChat } from '../standardImages/StandardImages';
import Chatbox from '../chatBox/Chatbox';
import ChatUser from '../chatUser/ChatUser';

const Chats = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const chats = useSelector((state) => state.chatReducer.chats);
    const dispatch = useDispatch();
    const [chatsVisibility, setChatVisibility] = useState(true);

    const messagesEndRef = useRef(null)


    useEffect(() => {
        dispatch(getUserChats(user._id));
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    },[])

    if (chats === null) {
        dispatch(getUserChats(user._id));
        messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
    }

    /* <Chatbox key={id} data={chat}/> right: 28vw;
      {visibilities[id] ? "" : (visibilities.forEach((check,id2) => changeBool(check,id,id2))
              console.log(visibilities)) }
    */

    /* <ChatUser chat={chat} id={id} /> 
    
      <div key={id} className="opened-chats"> 
              <div className="white-circle-chats">
                  <img src={defaultProfileImage} alt="" onClick={() => handleVisible(id)}/>
              </div>
              { visibilities[id] && <Chatbox key={id} data={chat}/> }
          </div>
    */

  return (
    <div className={chatsVisibility ? "Chats-close" :"Chats"}>

      <div className="chats-inner"> 
        <div className="chats-inner-ul">
          {((!chats || chats.length === 0) || chatsVisibility) ? "" : chats.slice(0,100).map((chat,id) => {
            return ( 
              <ChatUser id={id} chat={chat} />
            )
          })}
        </div>
      </div>
      
      <div ref={messagesEndRef} />

      <div className="create-chat" onClick={() => setChatVisibility((prev) => !prev)}>
          <img src={NewChat} alt="" />
        </div>
    </div>
  )
}

export default Chats