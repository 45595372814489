import React from 'react';
import "./Toolbar.css";
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Alarm,Users,SettingsIcon,Calendar,Cart,AddUser } from '../standardImages/StandardImages';


const Toolbar = ({choosePopUp}) => {
    const user = useSelector((state) => state.authReducer.authData);

    if(user.isBusiness){
        return (
            <div className="Toolbar"> 
                <ul>
                    <li>
                        <div className="Tool" onClick={() => choosePopUp(1)}>
                            <div className="white-circle">
                                <img src={Alarm} alt=""/>
                            </div>
                            <b>Bildirimlerim</b>
                        </div>
                    </li>
                    <li>
                        <Link to={"../business/profile/"+user._id+"/settings"}>
                            <div className="Tool">
                                <div className="white-circle">
                                    <img src={SettingsIcon} alt=""/>
                                </div>
                                <b>Profil Ayarlarım</b>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
        )
    }else{
        return (
            <div className="Toolbar"> 
                <ul>
                    <li>
                        <div className="Tool" onClick={() => choosePopUp(1)}>
                            <div className="white-circle">
                                <img src={Users} alt=""/>
                            </div>
                            <b>Arkadaşlarım</b>
                        </div>
                    </li>
                    <li>
                        <div className="Tool" onClick={() => choosePopUp(2)}>
                            <div className="white-circle">
                                <img src={AddUser} alt=""/>
                            </div>
                            <b>Takipçilerim</b>
                        </div>
                    </li>
                        <li>
                        <div className="Tool" onClick={() => choosePopUp(3)}>
                            <div className="white-circle">
                                <img src={Alarm} alt=""/>
                            </div>
                            <b>Bildirimlerim</b>
                        </div>
                    </li>
                        <li>
                        <div className="Tool" onClick={() => choosePopUp(4)}>
                            <div className="white-circle">
                                <img src={Cart} alt=""/>
                            </div>
                            <b>İlanlarım</b>
                        </div>
                    </li>
                        <li>
                        <div className="Tool" onClick={() => choosePopUp(5)}>
                            <div className="white-circle">
                                <img src={Calendar} alt=""/>
                            </div>
                            <b>Katıldığım Etkinlikler</b>
                        </div>
                    </li>
                        <li>
                        <Link to={"../profile/"+user._id+"/settings"}>
                            <div className="Tool">
                                <div className="white-circle">
                                    <img src={SettingsIcon} alt=""/>
                                </div>
                                <b>Profil Ayarlarım</b>
                            </div>
                        </Link>
                    </li>
                </ul>
            </div>
          )
    }

  
}

export default Toolbar