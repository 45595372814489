import React, {useEffect, useState, useRef } from 'react';
import "./Share.css"

import User from "../User/User";
import { useSelector, useDispatch } from "react-redux";

import  {SearchGlass} from '../standardImages/StandardImages'


import { getSecretUsers } from '../../api/UserRequests';
import { getAllBusiness } from '../../api/BusinessRequests';

import { createChat,findChat,getUserChats,sendMessage } from '../../actions/ChatActions';


const Share = ({data, setModalOpened}) => {

  const user = useSelector((state) => state.authReducer.authData);
  const {chats,chat} = useSelector((state) => state.chatReducer);
  const ref = useRef(null);
  const [input,setInput] = useState("");
  const [persons, setPersons] = useState([]);
  const dispatch = useDispatch();

  const onChange = (event) => {
    let temp = event.target.value
    setInput(temp.toLowerCase(),);
  }

  const fetchPersons = async () => {
    var data
    if(user.isBusiness){
      data = await getAllBusiness();
    }else{
      data = await getSecretUsers();
    }
    setPersons(data.data);
  };

  useEffect(() => {
    fetchPersons();
  }, [input === ""]);

    const handleChatOpen = async (person) => {
      const data = { 
          senderId : user._id,
          receiverId : person._id 
      }

      try{
          const a = await findChat(user._id, person._id)
          if (a === "no chat found"){
              
              dispatch(createChat(data));
          }else{
              //
          }
          dispatch(getUserChats(user._id));
          
      }catch(e){console.error(e);}
  }

    const handleSubmit = (person) => {
      
      try{
        if(chat && chat.members.includes(person._id)){
            const newMessage = {
              chatId: chat.chatId,
              senderId: user._id,
              text: data,
              type: "text"
            }

          const aaa = dispatch(sendMessage(newMessage));
          
          setModalOpened((prev) => !prev)
        }else{
          chats.forEach((chat) => {
            if (!chat.members.includes(person._id)){
              //
            }else{
              const newMessage = {
                chatId: chat.chatId,
                senderId: user._id,
                text: data,
                type: "text"
              }
    
            const aaa = dispatch(sendMessage(newMessage));
            
            setModalOpened((prev) => !prev)
            }
          });
        }

        

      }catch(e){
        console.error(e)
      };  
    };

  const handleClick = (person) => {
    handleChatOpen(person)
    handleSubmit(person)
  }

  return (
    <div className="Share" ref={ref}>

    <h6>"Arkadaşlarım"</h6>

    <div className="SearchBar">
          <div className="search-area">
              <input type="text" placeholder="Arkadaşlarını ara" value={input} onChange={onChange} />
              <img className="search-logo" src={SearchGlass} alt="" />
          </div>
          
      </div>
      
    {
      
        <div className="FollowersCard-list" >
            {persons.filter((item) => {
              const searchItem = input //.toLowerCase();
              const firstname = item.firstname || " ";
              const lastname = item.lastname || " ";
              const username= item.username || " ";
              const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
      
              if(searchItem === ""){
                return true;
              }
      
              return (
                searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
              );
              }).map((person, id) => {
              if (user.following.includes(person._id)) {
                return (
                  <div className="share-person-area"  key={id} onClick={() => handleClick(person)}> 
                    <User person={person}/>
                  </div>
                  )
              };
              })
            }
        </div>
        
        }

    </div>
  )
}

export default Share