import React, { useState,useEffect } from 'react';
import './Notification.css'
import moment from 'moment'
import { Link } from 'react-router-dom';

import {activityJoinAction} from '../../api/ActivityRequests';
import {useSelector} from 'react-redux'
import {defaultProfileImage, Picture} from "../standardImages/StandardImages"

const Notification = ({notification}) => {
  const user = useSelector((state) => state.authReducer.authData);

  const handleClick = async (val) => {
    await activityJoinAction(notification.contentId,{
      joinerId: notification.senderId,
      answer: val
    })
  }

  
    if (notification.type === "postComment" || 
    notification.type === "activityComment" || 
    notification.type === "advertisementComment" ||
    notification.type === "discountComment" ||
    notification.type === "forumComment"){

      let type = "";
      switch (notification.type){

        case "postComment":
          type = "post";
          break;

        case "activityComment":
          type = "activity";
          break;

        case "advertisementComment":
          type = "advertisement";
          break;

        case "discountComment":
          type = "discount";
          break;

        case "forumComment":
          type = "forum";
          break;

      }
      /*
      {/* <Link to={ "../" + type + "/" + notification.contentId } className="notification-link"> </Link>*/

      return (
        <Link to={ "../" + type + "/" + notification.contentId } className="notification-link">
          <div className="Notification">
            <div className="notification-info-area">
              <div className="Notification-user-photo">
                <img src={notification.mediaUrl ? notification.mediaUrl : defaultProfileImage } alt=""/>
              </div>
    
              <div className="notification-colum">
                <span className="username">
                    {notification.username}
                </span>
                <span className="NotificationData">
                    {type + "'unuza " + notification.comment +" yorumunu yaptı."}
                </span>
                <span className="timesclale">
                  {moment(notification.timestamp.toDate()).fromNow() /*.fromNow()*/}
                </span>
              </div>
            </div>
    
            <div className="notification-svg">
              <img src={Picture} alt=""/>
            </div>
          </div>
        </Link>
      )
    }else if (notification.type === "activityJoinReq"){
      return (

        <div className="Notification notification-link">
            <div className="notification-info-area">
              <div className="Notification-user-photo">
                <img src={notification.mediaUrl ? notification.mediaUrl : defaultProfileImage } alt=""/>
              </div>
    
              <div className="notification-colum">
                <span className="username">
                    {notification.username}
                </span>
                <span className="NotificationData">
                    {" etkinliğinize katılmak istiyor."}
                </span>
                <span className="timesclale">
                  {moment(notification.timestamp.toDate()).fromNow() /*.fromNow()*/}
                </span>
                <div className="activity-accept-buttons">
                  <button type="button" className="button activity-button" onClick={ () => handleClick(true) } >Kabul Et</button>
                  <button type="button" className="button activity-button" onClick={ () => handleClick(false) } >Reddet</button>
                </div>
              </div>
            </div>
    
            <div className="notification-svg">
              <Link to={ "../activity/" + notification.contentId } className="notification-link">
                <img src={Picture} alt=""/>
              </Link>
            </div>
          </div>
      )
    }else{
      let type = "";
      switch (notification.type){
        case "postLike":
          type = "post";
          break;
        case "activityLike":
          type = "activity";
          break;
        case "advertisementLike":
          type = "advertisement";
          break;
        case "discountLike":
          type = "discount";
          break;
        case "forumLike":
          type = "forum";
          break;

      }
      return (
        <Link to={ "../" + type + "/" + notification.contentId } className="notification-link">
          <div className="Notification">
            <div className="notification-info-area">
              <div className="Notification-user-photo">
                <img src={notification.mediaUrl ? notification.mediaUrl : defaultProfileImage } alt=""/>
              </div>
    
              <div className="notification-colum">
                <span className="username">
                    {notification.username}
                </span>
                <span className="NotificationData">
                    {type +"'unuzu beğendi"}
                </span>
                <span className="timesclale">
                  {moment(notification.timestamp.toDate()).fromNow() /*.fromNow()*/}
                </span>
              </div>
            </div>
    
            <div className="notification-svg">
              <img src={Picture} alt=""/>
            </div>
          </div>
        </Link>
      )
    }
  
}

export default Notification