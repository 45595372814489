import axios from "axios";

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const getUser = (userId) => API.get('/user/'+userId);

export const getSecretUser = (userId) => API.get('/user/'+userId+'/secret');

export const updateUser = (id,FormData) => API.put('/user/'+id, FormData);

/*export const getAllUsers = () => API.get('/user');*/

export const followUser = (id,Data) => API.put('/user/'+id+'/follow',Data);

export const unfollowUser = (id, Data) => API.put('/user/' + id + '/unfollow',Data);

export const addVisitor = (id,Data) => API.put('/user/'+id+'/visit',Data);

export const getUsersDict = () => API.get('/user/dict');

export const getSecretUsers = () => API.get('/user/secret');

export const getUserIkData = (id) => API.get('/user/'+id+'/ik');

export const updateUserIkData = (id,Data) => API.put('/user/' + id + '/ik',Data);