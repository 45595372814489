const chatReducer = (
    state = { chats: [], chat: null, message: null, loading: false, error: false, uploading: false },
    action) => {
        switch (action.type) {
            
            case 'CREATECHAT_START':
                return { ...state, error: false, uploading: true };

            case 'CREATECHAT_SUCCESS':
                return { ...state, error: false, uploading: false };

            case 'CREATECHAT_FAIL':
                return { ...state, error: true, uploading: false };



            case 'GETCHAT_START':
                return { ...state, error: false, uploading: true };

            case 'GETCHAT_SUCCESS':
                return { ...state, chats: action.data, error: false, uploading: false };

            case 'GETCHAT_FAIL':
                return { ...state, error: true, uploading: false };




            case 'FINDCHAT_START':
                return { ...state, error: false, uploading: true };

            case 'FINDCHAT_SUCCESS':
                return { ...state, chat: action.data, error: false, uploading: false };

            case 'FINDCHAT_FAIL':
                return { ...state, error: true, uploading: false };



            case 'SENDMESSAGE_START':
                return { ...state, error: false, uploading: true };

            case 'SENDMESSAGE_SUCCESS':
                return { ...state, error: false, uploading: false };

            case 'SENDMESSAGE_FAIL':
                return { ...state, error: true, uploading: false };




            case 'GETMESSAGE_START':
                return { ...state, error: false, uploading: true };

            case 'GETMESSAGE_SUCCESS':
                return { ...state, message: action.data ,error: false, uploading: false };

            case 'GETMESSAGE_FAIL':
                return { ...state, error: true, uploading: false };


                
            default:
                return state;
        }
    };

    
export default chatReducer;
