import axios from 'axios';

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const getPersonalPosts = (id) => API.get('/posts/'+id+'/timeline');

export const getTimelinePosts = () => API.get('/posts');

export const likePost = (id,data) => API.put('/posts/'+id+'/like',data)

export const sendComment = (id,data) => API.put('/posts/'+id+'/comment',data);

export const getPost = (id) => API.get('/posts/'+id);

export const updatePost = (id,data) => API.put('/posts/'+id, data);

export const deletePost = (id,data) => API.put('/posts/delete/'+id, data);