import React, { useState,useEffect} from 'react';
import "./ProfileActivities.css";

import ActivityTinyCard from '../activityTinyCard/ActivityTinyCard';
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { getPersonalActivities } from '../../actions/ActivityAction';
import ProfileActivityCard from '../profileActivityCard/ProfileActivityCard';



const ProfileActivities = () => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const [myActivities,setActivities] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        
       const aas = getPersonalActivities(params.id)
       aas().then((activities) =>{
        setActivities(activities.filter((act) => act.ownerId === params.id));
        setLoading(false);
    });

    },[])


    /* 
    {
                    tempactivities.map((post,id) => {
                        return <ProfileActivityCard data={post} key={id}/>
                    })
                }
                */
    return (
        <div className="ProfileActivities">
            { loading 
                ? "Fetching posts...."
                : myActivities.map((post,id) => {
                    return <ProfileActivityCard data={post} key={id}/>
                }) }
                

        </div>
    )
}

export default ProfileActivities