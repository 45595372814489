import * as NotificationApi from "../api/NotificationRequests";

export const deleteNotification = (id,formData) => async (dispatch) => {
    dispatch({type: "DELNNOT_START"})
    try {
        const {data} = await NotificationApi.deleteNotification(id, formData)
        dispatch({type:"DELNOT_SUCCESS", data: data})
    } catch (e) {
        
        dispatch({type:"DELNOT_FAIL"})
        console.error(e);
    }
}