import React, { useState,useEffect} from 'react';
import { addVisitor } from '../../actions/UserAction';
import { addBusinessVisitor } from '../../actions/BusinessAction';
import './Profile.css';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import ProfileDetails from '../../components/profileDetails/ProfileDetails';

// burada ilem var profileLeft'i koy bir yerlere

const Profile = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const params =useParams();
    const [userId,setUserId] = useState(params.id)
    const dispatch = useDispatch();
    const [white,setWhite] = useState(false);

    useEffect(() => {
        if(params.id){
            setUserId(params.id)
            if (userId !== user._id){
                if(user.isBusiness){
                    dispatch(addBusinessVisitor(params.id, {visitorId: user._id}));
                }else{
                    dispatch(addVisitor(params.id,{visitorId:user._id}))
                }  
            }
        }
    })

    if(params.id && params.id !== userId){
        window.location.reload();
    }


    return (
        <div className="Profile">

            <div className="Profile-upper">
                <UpperPanel white={white}/>
            </div>
            <div className="Profile-lower">
                <ProfileSide location="profile"/>
                <ProfileDetails />
                <RightSide white={white} setWhite={setWhite}/> 
            </div>

            <Chats/>
            
        </div>
    );
};

export default Profile;