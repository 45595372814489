import React, { useState,useEffect} from 'react';
import "./BusinessProfileDetails.css";
import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,Person,NewChat,AddUser} from "../standardImages/StandardImages"

import { followUser, unfollowUser } from '../../actions/UserAction';
import { followBusiness,unfollowBusiness } from '../../actions/BusinessAction';

import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { getUser } from '../../actions/UserAction';
import { getBusiness } from '../../actions/BusinessAction';

import BusinessProfileBio from '../businessProfileBio/BusinessProfileBio';
import TinyDiscount from '../tinyDiscount/TinyDiscount';

const BusinessProfileDetails = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const params =useParams();
    const isUser = user._id === params.id;
    const [person,setPerson] = useState(user);
    const profileUserId = params.id;
    const [infoPageNmr, setInfoPageNmr] = useState(0);
    const dispatch = useDispatch();
    /*
    const [following, setFollowing] = useState(
        person.followers.includes(user._id)
    );
    */

    const fetchProfileUser = async () =>{
        if (!profileUserId || profileUserId === user._id){
            setPerson(user);
            //setFollowing(person.followers.includes(user._id))
        }else{
            //console.log("fetching profile user...");
            //console.log(profileUserId)
            var ref
            ref = await getBusiness(profileUserId);
            ref().then((userData) => setPerson(userData))
            //setFollowing(person.followers.includes(user._id))
            //console.log(person1)
        }
      }

    useEffect(() => {
        try{
            fetchProfileUser();
            //setFollowing(person.followers.includes(user._id));
        }catch(err){
            console.error(err)
        }
        },[isUser]);

    /*
    if(following !== person.followers.includes(user._id)){
        setFollowing(person.followers.includes(user._id))
    }
    */

    /*
    const handleFollow = () => {
        following 
        ? dispatch(unfollowBusiness(person._id, user))
        : dispatch(followBusiness(person._id, user));
        setFollowing((prev) => !prev);
    }
    */

    let { discounts, loading } = useSelector((state) => state.discountReducer);

    if (params.id) discounts = discounts.filter( (discount) => discount.ownerId === params.id);

    return (
        <div className="ProfileDetails">

            <div className="profile-profile-container">


                <div className="profile-user-statistics">
                    { /* <span className="profile-user-statistic">Takip Edilenler <b>{person.following.length}</b></span>
                         <span className="profile-user-statistic">Takipçi Sayısı <b>{person.followers.length}</b></span> */}
                </div>


                { /* isUser ? ""
                        : following
                            ? (<div className="UnfollowButton profile-user-following-true" onClick={handleFollow}>Takiptesin</div> )
                            : (<div className="button profile-user-following-false" onClick={handleFollow}>Takip Et</div> )
                */}


                <div className="profile-profile-image">
                    <img src={ 
                        person.profilePicture
                        ? person.profilePicture
                        : defaultProfileBusiness
                    } alt="ProfileImage" />
                </div>

                {isUser ? "" : (
                    <div className="profile-connect-buttons">
                        <button className="profile-interaction-button" >
                            <img src={ Person } alt="" />
                        </button>
                        <button className="profile-interaction-button" >
                            <img id="profile-other-new-chat" src={ NewChat } alt="" />
                        </button>
                        <button className="profile-interaction-button" >
                            <img src={ AddUser } alt="" />
                        </button>
                    </div>
                ) }

                <div className="profile-profile-desc">
                    <span className="profile-user-statistic">{ person.businessName.charAt().toUpperCase()+person.businessName.slice(1) }</span>
                    

                    <div className="profile-profile-description">
                    <span className="profile-user-bio">{person.bio}</span>
                    </div>
                </div> 

                <div className="profile-buttons">
                
                    <button className={infoPageNmr === 0 ? "button profile-btn" : "button-closed profile-btn"} onClick={() => setInfoPageNmr(0)}> Kampanyalar </button>

                    <button className={infoPageNmr === 1 ? "button profile-btn" : "button-closed profile-btn"} onClick={() => setInfoPageNmr(1)}> Hakkında </button>
                </div>

            </div>

            <div className="profile-right-info-container">
                {infoPageNmr === 0 && (
                    <div className="profile-posts-container">
                        <h6>Fotoğraflar ve Videolar</h6>
                        <div className="profile-posts">
                            { loading 
                                ? "Fetching posts...."
                                : discounts.map((post,id) => {
                                    return <TinyDiscount data={post} key={id} className="profile-post-photo"/>
                                }) 
                            } 
                        </div>
                    </div>
                )}
                {infoPageNmr === 1 && (
                    <div className="profile-bio"> 
                        <BusinessProfileBio />
                    </div>
                )}
            </div>


        </div>
    )

};

export default BusinessProfileDetails;