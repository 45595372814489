import React from 'react';
import {Modal, useMantineTheme} from '@mantine/core';
import { PurplePlusButton } from '../standardImages/StandardImages';
import moment from 'moment';
import EditDiscount from '../editDiscount/EditDiscount';
import { useSelector } from 'react-redux';


const EditDiscountModal = ({modalOpened,setModalOpened,data}) => {
    const theme = useMantineTheme();
    const loading = useSelector((state) => state.discountReducer.updateLoading)

    if( loading ){
        setModalOpened(false)
    }

    /*overlayColor={
            theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity = {0.55}
        overlayBlur = {3}*/

    return (
        <Modal 
        size = '55%'
        opened={modalOpened}
        onClose={() => setModalOpened(false)} 
        >
            <EditDiscount data={data} />
        </Modal>
    );
};

export default EditDiscountModal