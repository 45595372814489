import React, {useRef,useState, useEffect} from 'react';
import "./Chatbox.css";
import Messages from "../messages/Messages.jsx";
import {useSelector,useDispatch} from "react-redux";
import { db } from '../../firebase.js';
import { deleteDoc,doc } from "firebase/firestore";

import { getSecretUser } from '../../actions/UserAction';

import {sendMessage,deleteChat} from "../../actions/ChatActions";

import { CloseButton2,PlusLogo,OnlineBar,Attention, Trash } from '../standardImages/StandardImages';

const Chatbox = ({data}) => {

    const text = useRef("");
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const [guest,setGuest] = useState({
      guest: ""
    })
    const [isClosed, setIsClosed] = useState(false);
    const guestId = data.members[0] === user._id 
                        ? data.members[1]
                        : data.members[0]

    const aFunc = async () => {
      const guestData = await getSecretUser(guestId);
      guestData().then((data) => {
        setGuest(data);
      })
    }   

    useEffect(() => {
      aFunc();
    },[guest === null])

    const handleCleanup = () => {
        
      try{
          const deleteNoti = async () => {
              dispatch(deleteChat(data.chatId,{userId: user._id}))
          }
          deleteNoti(); 
      }catch (err){
          console.error(err);
      }
      
  }

    const handleSubmit = (e) => {
        if(e.key === "Enter"){
          const newMessage = {
            chatId: data.chatId,
            senderId: user._id,
            text: text.current.value,
            type: "text",
            mediaUrl: user.profilePicture,
            username: user.username,
            receieverId: guestId
          }
  
        try{
          const aaa = dispatch(sendMessage(newMessage));
          text.current.value = "";
        }catch(e){
          console.error(e)
        };
      };
    };

    const handleClose = () => {
      setIsClosed((prev) => !prev)
    }

    /*
    
    <img className="online-bar" src={OnlineBar} alt="oline" /> 
    <button className="" style={{display: "none"}} onClick={handleSubmit}>Send</button>

    */

    
      return (
        <div className={isClosed ? "ChatBox" : "ChatBox-closed"}  >
          <div className="absolute-part">
            <div className="upper-border">   
              <div className={true ? "online-box" : "offline-box"}upper-cover> <span></span> </div>
              <div className="infoArea" >
                  <div className="chat-info">
                    <h6> {guest.username} </h6>
                    <span>{true ? "Şu anda aktif" : "offline"}</span>
                  </div>
                  <div className="chat-logos">
                    <img src={Attention} style={{ "cursor" : "pointer" }} alt=""/>
                    <img src={Trash} style={{ "width" : "2rem", "height" : "2rem", "cursor" : "pointer" }} alt="" onClick={handleCleanup}/>
                    <img className="close-button" src={CloseButton2} alt="" onClick={handleClose} style={{cursor:"pointer"}}/>
                  </div>
    
                </div>
            </div>
            
    
            { isClosed ? (<>
                <Messages chatId={data.chatId}/>
                <div className="SendArea">
                    <div className="white-circle-chatbox">
                      { /* <img src={PlusLogo} alt=""/> */ }
                    </div>
                    <input type="text" placeholder="write message" ref={text} onKeyDown={handleSubmit}/>
                </div>
              </>) : "" }
          </div>
        </div>
      )
    

  
}

export default Chatbox