import React, {useEffect,useRef} from 'react';
import { getTimelinePosts,getPersonalPosts } from '../../actions/PostsAction';
import Post from '../Post/Post';
import {useSelector, useDispatch} from "react-redux";
import "./Posts.css";
import {useParams} from "react-router-dom";

import ViewportList from "react-viewport-list";

const Posts = ({location}) => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);

    useEffect(() => {
        location === "homePage"
        ? dispatch(getTimelinePosts())
        : dispatch(getPersonalPosts(params.id));
    },[]);

    

    let { posts,loading } = useSelector((state) => state.postReducer);

    if (!posts || posts.length === 0) return 'No Posts';

    if (params.id) posts = posts.filter( (post) => post.ownerId === params.id);
    /*

    posts.map((post,id) => {
                    return <Post data={post} key={id} />
                })

    */

    return (
        <div className="Posts" ref={ref}>

            {loading 
                ? "Fetching posts...."
                : <ViewportList viewportRef={ref} items={posts} itemMinSize={10} margin={8}>
                    {(post,id) => {
                        return <Post data={post} key={id} />
                    }}
                </ViewportList>
            }

        </div>
    );
};

export default Posts;