import { legacy_createStore as createStore, applyMiddleware, compose, } from "redux";
import thunk from "redux-thunk";
import { rootReducer } from "../reducers";

import  secureLocalStorage  from  "react-secure-storage";

/* kaydederken ve alırken şifrele*/ 
/*
function saveToLocalStorage(store) {
    try {
        const serializedStore = JSON.stringify(store);
        window.localStorage.setItem('store', serializedStore);
        secureLocalStorage.setItem("object", {
		    message:  "This is testing of local storage",
	    });
    } catch (err) {
        console.error(err);
    }
}

function loadFromLocalStorage(){
    try {
        const serializedStore = window.localStorage.getItem('store');
        if (serializedStore === null) return undefined;
        console.log(serializedStore);
        console.log(JSON.parse(serializedStore));
        return JSON.parse(serializedStore);
    }catch (err) {
        console.error(err);
        return undefined;
    }
}*/


function saveToLocalStorage(store) {
    try {
        /*const serializedStore = 
        window.localStorage.setItem('store', serializedStore);*/
        secureLocalStorage.setItem("object", JSON.stringify(store));
    } catch (err) {
        console.error(err);
    }
}

function loadFromLocalStorage(){
    try {
        /*const serializedStore = window.localStorage.getItem('store');
        if (serializedStore === null) return undefined;
        console.log(serializedStore);
        console.log(JSON.parse(serializedStore));
        return JSON.parse(serializedStore);*/
        const serializedStore = secureLocalStorage.getItem("object");
        if(serializedStore === null){
            return undefined;
        } 
        return JSON.parse(serializedStore);
    }catch (err) {
        console.error(err);
        return undefined;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const persistedState = loadFromLocalStorage();
/*{
    activityReducer
: 
{activities: [], myActivities: [], loading: false, error: false, uploading: false},
authReducergetUser,
: 
{chats: [], chat: null, message: null, loading: false, error: false, uploading: false},
discountReducer
: 
{discounts: [], loading: false, error: false, uploading: false},
forumReducer
: 
{forums: [], loading: false, error: false, uploading: false},
marketplaceReducer
: 
{ads: [], loading: false, error: false, uploading: false},
postReducer
: 
{posts: [], loading: false, error: false, uploading: false},
}*/ // loadFromLocalStorage();

const store = createStore(rootReducer,persistedState, composeEnhancers(applyMiddleware(thunk)));

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;