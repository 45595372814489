import React, { useState,useEffect,useRef} from 'react'
import "./RegisterPage.css";
import { authPicture,AmazoiLogo,defaultProfileImage,PurplePlusButton,ClosedEye,Eye,Person } from "../../components/standardImages/StandardImages";

import { signUp } from "../../actions/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { v4 } from "uuid";

const RegisterPage = () => {
    const initialState = {
        username: "",
        email: "",
        password: "",
        confirmPass: "",
        bio:"",
        birthday: "",
        university: "Hacettepe Üniversitesi",
        department: "",
        hobbies: "",
        location: "",
        image: "",
        firstname: "",
        lastname: "",
        gender: "",
    };

    const loading = useSelector((state) => state.authReducer.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState(initialState);
    const [confirmPass,setConfirmPass] = useState(true);
    const [confirmEmail,setConfirmEmail] = useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(false);
    const [profilePicture,setProfilePicture] = useState(null);
    const imageRef = useRef();
    const [page,setPage] = useState(true);
    const defaultPicture = defaultProfileImage;

    //reset form
    const resetForm = () => {
        setData(initialState);
        setConfirmPass(true);
        setConfirmEmail(true);
    };

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const register = async () => {
            data.isBusiness = false;

            if (profilePicture){
                
                const fileName = "profilePic/images/"+ v4() + profilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
                try {
                    dispatch(signUp( data, pictureData, navigate ))
                    
                }catch (err) {
                    console.error(err);
                }
            }else{
                data.image = "";
                dispatch(signUp( data, null, navigate))
            }
            
            //dispatch(verify(data, navigate))
            resetForm();
        };

        await register();
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setProfilePicture(img)
        }
    };

    const handleVisibility = (e) => {
        e.preventDefault();
        setPasswordVisibility((prevState) => !prevState)
    }

    const handleNextPage = () => {
        setConfirmPass(true);
        setConfirmEmail(true);

        if(data.email.endsWith("m") || data.email.endsWith("r")){
            data.password === data.confirmPass 
            ? setPage(false)
            : setConfirmPass(false);
        }else{
            setConfirmEmail(false);
        }

        
    }


    return (
        <div className="Auth">
            <div id="upper-picture">
            <img src={authPicture} alt="" />
            </div>

            <div className="">
            </div>


            <div className="middle">
                <div className="register-upper-desc">
                {page 
                    ? (<h6 className="register-description register-upper-desc">Profilinizi oluşturalım</h6>) 
                    : (<h6 className="register-description register-upper-desc">Arkadaşlarınızın hakkında daha çok bilgi <br/> edinebilmesi için biyografi ekleyin </h6>)
                }
                    
                </div>
                
                <div className="register-main-panel">
                    {page 
                        ? (<div className="register-page-one">
                            <form className="register-form" onSubmit={handleSubmit}>
                            <div style={{ "position" : "relative"}}>
                                <div className="settings-profile-image-container">
                                    <div className="settings-profile-image">
                                        <img src={ 
                                            profilePicture
                                            ? URL.createObjectURL(profilePicture)
                                            : defaultPicture 
                                        } alt="ProfileImage" /> 
                                    </div>
                                    <div style={{display: "none"}}>
                                        <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} />
                                    </div>
                                </div>
                                <div className="settings-change-profile-image">
                                    <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                                </div>
                            </div>

                                <h6 className="register-description register-profile-desc">Arkadaşlarınızın sizi daha iyi <br/> tanıması için resminizi ekleyin</h6>

                                        

                                <div className="single-row">
                                    <div className="register-input-area">
                                        <div className="input-container">
                                            <p>İsim</p>
                                            <input required type="text" 
                                            className="emailInput tiny-input" 
                                            placeholder="İsminiz"
                                            name="firstname"
                                            value={data.firstname}
                                            onChange={handleChange}
                                            />
                                        </div>
                                    </div>  

                                    <div className="register-input-area">
                                        <div className="input-container">
                                            <p>Soyisim</p>
                                            <input required type="text" 
                                            className="emailInput tiny-input" 
                                            placeholder="Soyisminiz"
                                            name="lastname"
                                            value={data.lastname}
                                            onChange={handleChange}
                                            />
                                        </div>
                                    </div>  
                                </div>


                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Kullanıcı adınız</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="kullanıcı adınız"
                                        name="username"
                                        value={data.username}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>


                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>E- Posta Adresi</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="example@hacettepe.edu.tr"
                                        name="email"
                                        value={data.email}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  
                                
                                
                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Şifre</p>
                                        <input required type={passwordVisibility ? "text" : "password"}
                                            className="passwordInput" 
                                            placeholder="......"
                                            name="password"
                                            value={data.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <img className="svg-img" src={passwordVisibility ? ClosedEye : Eye} onClick={handleVisibility}/>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Şifre (Tekrar)</p>
                                        <input required type={passwordVisibility ? "text" : "password"}
                                            className="passwordInput" 
                                            placeholder="......"
                                            name="confirmPass"
                                            value={data.confirmPass}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <img className="svg-img" src={passwordVisibility ? ClosedEye : Eye} onClick={handleVisibility}/>
                                </div> 

                                <span 
                                style={{
                                    color : "red",
                                    fontSize: "12px",
                                    alignSelf: "flex-end",
                                    marginRight: "5px",
                                    display: confirmPass ? "none" : "block",
                                }}>
                                    *Confirm password is not same
                                </span>
                                <span 
                                style={{
                                    color : "red",
                                    fontSize: "12px",
                                    alignSelf: "flex-end",
                                    marginRight: "5px",
                                    display: confirmEmail ? "none" : "block",
                                }}>
                                    *Email must be a hacettepe email address
                                </span>


                                <div className="register-login-btn">
                                    <div
                                        className="button infoButton"
                                        onClick={() => handleNextPage()} >
                                        Devam
                                    </div>
                                </div>

                                <div className="auth-links">
                                    <Link className="link-font" to="../auth">Giriş Yap</Link>
                                </div>
                            </form>

                            
                        </div>)
                        : (<div className="personal-info">
                            <form className="register-form" onSubmit={handleSubmit}>

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Biyografi</p>
                                        <textarea type="text" 
                                        className="register-textarea" 
                                        placeholder="enter your biography"
                                        name="bio"
                                        value={data.bio}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  


                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Doğum Günü</p>
                                        <input required type="date" 
                                        className="emailInput" 
                                        min="1985-01-01"
                                        placeholder="GG/AA/YYYY"
                                        name="birthday"
                                        value={data.birthday}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Cinsiyet</p>
                                        <input type="text"
                                            className="passwordInput" 
                                            placeholder="Cinsiyetiniz"
                                            name="gender"
                                            value={data.gender}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 
                                
                                
                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Üniversite</p>
                                        <input type="text"
                                            className="passwordInput" 
                                            placeholder="Hacettepe Üniversitesi"
                                            name="university"
                                            value={data.university}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Bölüm</p>
                                        <input type="text"
                                            className="passwordInput" 
                                            placeholder="Bölümünüz"
                                            name="department"
                                            value={data.department}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Hobiler</p>
                                        <input type="text"
                                            className="passwordInput" 
                                            placeholder="Hobileriniz"
                                            name="hobbies"
                                            value={data.hobbies}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Konum</p>
                                        <input type="text"
                                            className="passwordInput" 
                                            placeholder="Konumunuz"
                                            name="location"
                                            value={data.location}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-login-btn">
                                    <div
                                        className="button infoButton"
                                        onClick={() => setPage(true)} >
                                        Geri
                                    </div>
                                        
                                    {
                                    <button
                                        className="button infoButton"
                                        type="submit"
                                        disabled={loading}>
                                        {loading ? "Loading..." : "Devam"}
                                    </button>
                                    }
                                </div>
                            </form>
                        </div>)
                    }

                        
                        
                </div>
                <a href="https://www.amazoisoftware.com/">
                    <div className="logo">
                        <img src={AmazoiLogo} alt=""/>
                    </div>
                </a>
                    
                </div>


            <div className="">
            <span></span>
            </div>
        </div>
    )
}

export default RegisterPage;