import React, { useState,useEffect,useRef} from 'react'
import "./ResetPassword.css"
import { Logo,authPicture,AmazoiLogo,Eye,Person } from "../../components/standardImages/StandardImages";
import { resetPassword } from '../../actions/AuthAction';
import { useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';


const ResetPassword = () => {
    const [data,setData] = useState({email:""});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const loading = false;

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        const resPas = async () =>{
            await resetPassword(data, navigate);
        }

        resPas();
        
    };

  return (
    <div className="ResetPassword">
        <div id="upper-picture">
            <img src={authPicture} alt="" />
        </div>

        <div className=""> </div>

        <div className="reset-password-main-panel">
            <div className="">
                <h6 className="register-description register-upper-desc">Şifremi Unuttum</h6>
            </div>

            <div className="reset-password-info-area">
                <h6 className="register-description register-upper-desc">Lütfen Şifresini değiştirmek istediğiniz <br/> hesabın email adresini giriniz</h6>

                <div className="register-input-area">
                    <div className="input-container">
                        <p>E- Posta Adresi</p>
                        <input required type="text" 
                        className="emailInput tiny-input" 
                        placeholder="example@hacettepe.edu.tr"
                        name="email"
                        value={data.email}
                        onChange={handleChange}
                        />
                    </div>
                </div> 

                <div className="reset-password-button">
                    <button
                        className="button infoButton"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={loading}>
                            {loading ? "Loading..." : "Gönder"}
                    </button>
                </div>
                
                
            </div>

            <a href="https://www.amazoisoftware.com/">
                <div className="logo">
                    <img src={AmazoiLogo} alt=""/>
                </div>
            </a>   
            
        </div>

        <div className=""> </div>

    </div>

    

  )
}

export default ResetPassword