import React, {useEffect,useRef} from 'react';
import { getTimelineDiscounts } from '../../actions/DiscountAction';
import Discount from '../discount/Discount';
import {useSelector, useDispatch} from "react-redux";
import "./Discounts.css";
import {useParams} from "react-router-dom";

import ViewportList from "react-viewport-list";

const Discounts = () => {
    const params = useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);

    

    useEffect(() => {
        dispatch(getTimelineDiscounts(params.id));
    },[]);

    let {discounts,loading} = useSelector((state) => state.discountReducer);

    if (!discounts || discounts.length === 0) return 'No Discounts'

    if (params.id) discounts = discounts.filter((discount) => discount.userId === params.id);


    /*

    discounts.map((discount,id) =>{ 
                    return <Discount key={id} data={discount}/>
            }
            )

    */
    return (
        <div className="Discounts" ref={ref}>
            {loading 
                ? "Fetching discounts...."
                : <ViewportList viewportRef={ref} items={discounts} itemMinSize={10} margin={8}>
                    {(discount,id) =>{ 
                        return <Discount key={id} data={discount}/>
                }}
                </ViewportList>
                
                
        }
        </div>
    );
};

export default Discounts