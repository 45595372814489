const forumReducer = (
    state = { forums: [], loading: false, error: false, uploading: false, updateLoading: false },
    action) => {
        switch (action.type) {
            case 'FUPLOAD_START':
                return { ...state, error: false, uploading: true };
            case 'FUPLOAD_SUCCESS':
                return { ...state, forums: [action.data, ...state.forums], uploading: false, error: false };
            case 'FUPLOAD_FAIL':
                return { ...state, uploading: false, error: true }

            //belongs to forums.jsx
            case 'FRETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'FRETREIVING_SUCCESS':
                return { ...state, forums: action.data, loading: false, error: false };
            case 'FRETREIVING_FAIL':
                return { ...state, loading: false, error: true };

            case 'FDOWNLOAD_START':
                return { ...state, loading: true, error: false};
            case 'FDOWNLOAD_SUCCESS':
                return { ...state, forums: action.data, loading: false, error: false };
            case 'FDOWNLOAD_FAIL':
                return { ...state, loading: false, error: true }; 


            //belongs to forums.jsx
            case 'FCOMMENT_START':
                return { ...state, loading: true, error: false};
            case 'FCOMMENT_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'FCOMMENT_FAIL':
                return { ...state, loading: false, error: true };


            case "FUPDATING_START":
                return {...state,updateLoading: true, error: false};

            case "FUPDATING_SUCCESS":
                //localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return {...state, updateLoading: false, error: false}

            case "FUPDATING_FAIL":
                return {...state,updateLoading: true, error: true}

            case 'FDELETE_START':
                return { ...state, loading: true, error: false};
            case 'FDELETE_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'FDELETE_FAIL':
                return { ...state, loading: false, error: true };
                
            default:
                return state;
        }
    };

    
export default forumReducer;