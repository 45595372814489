import * as ChatApi from "../api/ChatRequests";

export const createChat = (data1) => async (dispatch) => {
    dispatch({type: "CREATECHAT_START"})
    try {
        const {data} = await ChatApi.createChat(data1)
        dispatch({type:"CREATECHAT_SUCCESS", data: data})
    } catch (e) {
        
        dispatch({type:"CREATECHAT_FAIL"})
        console.error(e);
    }
}

export const getUserChats = (id) => async (dispatch) => {
    dispatch({type: "GETCHAT_START"})
    try {
        const {data} = await ChatApi.getUserChats(id)
        dispatch({type:"GETCHAT_SUCCESS", data: data})
    } catch (e) {
        
        dispatch({type:"GETCHAT_FAIL"})
        console.error(e);
    }
}

export const deleteChat = (id,formData) => async (dispatch) => {
    dispatch({type: "DELCHAT_START"})
    try {
        const {data} = await ChatApi.deleteChat(id, formData)
        dispatch({type:"DELCHAT_SUCCESS", data: data})
    } catch (e) {
        
        dispatch({type:"DELCHAT_FAIL"})
        console.error(e);
    }
}
/*
export const findChat = (firstId,secondId) => async (dispatch) => {
    dispatch({type: "FINDCHAT_START"})
    try {
        const {data} = await ChatApi.findChat(firstId,secondId)
        dispatch({type:"FINDCHAT_SUCCESS", data: data})
    } catch (e) {
        console.error(e);
        dispatch({type:"FINDCHAT_FAIL"})
    }
}*/

export const findChat = async (firstId,secondId) => {

    try {
        const {data} = await ChatApi.findChat(firstId,secondId)
        return data;
    } catch (e) {
        console.error(e);
    }
}

export const sendMessage = (data1) => async (dispatch) => {
    dispatch({type: "SENDMESSAGE_START"})
    try {
        const {data} = await ChatApi.sendMessage(data1); 
        dispatch({type: "SENDMESSAGE_SUCCESS"})
    }catch (e) {
        
        dispatch({type: "SENDMESSAGE_FAIL"})
        console.error(e);
    }
}

export const getMessage = (id) => async (dispatch) => {
    dispatch({type: "GETMESSAGE_START"})
    try {
        const {data} = await ChatApi.getMessage(id)
        dispatch({type:"GETMESSAGE_SUCCESS", data: data})
    } catch (e) {
        
        dispatch({type:"GETMESSAGE_FAIL"})
        console.error(e);
    }
}