import CryptoJS from 'crypto-js';

//import crypto from 'crypto';
//import aes256 from 'aes256';


export const encrypt = async (content) => {
   const encrypted = content
   return encrypted;
}

export const decrypt = (content) => {
  var bytes  = CryptoJS.AES.decrypt("/MMolt7RMDo/M1aYUhB6ZHmH8jIk6tEdpuUu9kooEJ7jf9YatE4KgDLApwPAvA==", '64e263382f8633f0ef1b39070ccb969e5485ee4dda0ae0e04a8d7aa63a2f8cc7');
  
  
  var originalText = bytes.toString(CryptoJS.enc.Utf8);
  
}
/*
export const to_Encrypt = (text) => {
    var encrypted = aes256.encrypt(secret_key, text);
    return encrypted;
  };
  //welcome message is not decrypted
  export const to_Decrypt = (cipher, username) => {
    if (cipher.startsWith("Welcome")) {
      return cipher;
    }
  
    if (cipher.startsWith(username)) {
      return cipher;
    }
    //decryped message is returned
    var decrypted = aes256.decrypt(secret_key, cipher);
    return decrypted;
  };*/