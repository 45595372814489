import React, {useState} from 'react'
import "./BusinessSettings.css";

import UpperPanel from '../../../components/upperPanel/UpperPanel';
import BusinessProfileSide from '../../../components/businessProfileSide/BusinessProfileSide';
import BusinessRightSide from '../../../components/businessRightSide/BusinessRightSide';
import Settings from '../../../components/settings/Settings'


const BusinessSettings = () => {
    const [white,setWhite] = useState(false);

    return (
        <div className="SettingsPage ">

            <div className="Settings-upper">
              <UpperPanel white={white}/>
            </div>

            <div className="Settings-lower business-settings">
                <BusinessProfileSide/>

                <Settings />

                <BusinessRightSide white={white} setWhite={setWhite}/> 
            </div>

        </div>
      )
};

export default BusinessSettings;