import React, {useState} from 'react'
import { useSelector } from 'react-redux';
import "./Message.css";

const Message = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);

  return (
    <div className="Message">

      <div className={data.senderId === user._id 
                      ? "MyMessage" 
                      : "GuestMessage"}>
                      
                      <p>{data.content}</p>
      </div>
    </div>
  )
}

export default Message;