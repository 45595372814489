import React, {useEffect,useState} from 'react';
import "./ActivityTinyCard.css";
import moment from 'moment';

import {storage} from "../../firebase"
import { ref, getDownloadURL} from "firebase/storage"

import { PurplePlusButton } from '../standardImages/StandardImages';

import { useSelector, useDispatch } from 'react-redux';

const ActivityTinyCard = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);

  return (
    <div className="ActivityTinyCard">
        <div className="activity-post-image-tn">
            {data.mediaUrl ? (<img 
                src={data.mediaUrl ? data.mediaUrl : ""}
                alt= "" 
            /> ) : ""}
            

            <div className="activity-tn-info">
                <div className="activity-tn-inside">
                        <h4>{data.title}</h4>
                        <h6>{moment(data.timestamp).fromNow()}</h6>
                        <h6>{data.location}</h6>
                        <p>{data.description}</p>
                    </div>
                <div className="activity-tn-plus">
                    { (data.requested.includes(user._id) || data.members.includes(user._id)) ? "" : <img src={PurplePlusButton} alt=""/>}
                </div>
            </div>
        </div>
    </div>
  )
}

export default ActivityTinyCard