import React, {useState} from 'react'
import "./Forum.css";


import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import ForumPosts from '../../components/forumPosts/ForumPosts'

const Forum = () => {
  const [white,setWhite] = useState(false);
  
  return (
    <div className="Forum">
      <div className="Forum-upper">
        <UpperPanel white={white}/>
      </div>
      <div className="Forum-lower">
        <ProfileSide/>
        <ForumPosts/>
        <RightSide white={white} setWhite={setWhite}/> 
      </div>

      <Chats/>
    </div>
  )
}

export default Forum