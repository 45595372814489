import axios from 'axios';
import {getKey,getKeyUserId} from './PrivateKeyContainer';

// http://18.157.178.87/

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
},(error) => {
    console.error(error)
});

export const getAllActivities = () => API.get('/activities');

export const getPersonalActivities = (id) => API.get('/activities/personal/'+id);

export const getActivity = (id) => API.get('/activities/'+id);

export const deleteActivity = (id,data) => API.put('/activities/delete/'+id,data);

export const updateActivity = (id,data) => API.put('/activities/'+id,data);

export const addCommentActivity = (id,data) => API.put('/activities/'+id+'/comment',data);

export const addLikeActivity = (id,data) => API.put('/activities/'+id+'/like',data);

export const sendJoinRequest = (id,data) => API.put('/activities/'+id+'/join',data);

export const activityJoinAction = (id,data) => API.put('/activities/'+id+'/joinaction',data);