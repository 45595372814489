import React, {useState, useEffect} from 'react';
import "./ChatUser.css";
import { defaultProfileImage } from '../standardImages/StandardImages';
import Chatbox from '../chatBox/Chatbox';
import {useSelector} from "react-redux";
import { getSecretUser } from '../../actions/UserAction';

const ChatUser = ({chat,id}) => {
  
    const user = useSelector((state) => state.authReducer.authData);
    const [visible, setVisible] = useState(false);
    const [guest,setGuest] = useState({
      guest: ""
    })
    const guestId = chat.members[0] === user._id 
                        ? chat.members[1]
                        : chat.members[0]

    const aFunc = async () => {
      const guestData = await getSecretUser(guestId);
      guestData().then((data) => {
        setGuest(data);
      })
    }   

    useEffect(() => {
      aFunc();

    },[guest === ""])

    

  return (
    <div className="ChatUser">
        <div className="white-circle-chats">
            <img src={guest.profilePicture
              ? guest.profilePicture
              : defaultProfileImage } alt="" onClick={() => setVisible((prev) => !prev)}/>
        </div>
        {visible && <Chatbox data={chat}/>}
    </div>
    
  )
}

export default ChatUser