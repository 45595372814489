import axios from 'axios';

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {

    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const signUp = (Data) => API.post('auth/register', Data);

/*
export const logIn = (Data) => API.post('auth/login',Data);

export const logout = (Data) => API.post('auth/logout',Data);

export const forgotPassword = (Data) => API.post('auth/forgot-password',Data);

export const updateMail = (Data) => API.post('auth/update-email',Data);

export const updatePassword = (Data) => API.post('auth/update-password',Data);
*/

export const verifyEmail = (Data) => API.post('auth/verify',Data);

export const updateUserDetails = (id, Data) => API.post('auth/' + id +'/update-user-details',Data)