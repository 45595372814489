import axios from 'axios';
import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});
export const getTimelineDiscounts = () => API.get('/discounts');

export const likeDiscount = (id,data) => API.put('/discounts/'+id+'/like',data);

export const commentDiscount = (id,data) => API.put('/discounts/' + id+'/comment',data);

export const getDiscount = (id) => API.get('/discounts/'+id);

export const updateDiscount = (id,data) => API.put('/discounts/'+id, data);

export const deleteDiscount = (id,data) => API.put('/discounts/delete/'+id,data);

