import React, {useState, useEffect} from 'react';
import "./BusinessSingleDiscount.css";

import BusinessRightSide from '../../../components/businessRightSide/BusinessRightSide';
import BusinessProfileSide from '../../../components/businessProfileSide/BusinessProfileSide';
import UpperPanel from '../../../components/upperPanel/UpperPanel'

import { useParams } from 'react-router-dom'

import Discount from '../../../components/discount/Discount';
import { getDiscount } from '../../../actions/DiscountAction';

const BusinessSingleDiscount = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const discountId = params.id;
    const [discount,setDiscount] = useState(null);

    const aFunc = async () => {
        const discountData = await getDiscount(discountId);
        discountData().then((data) => {
            setDiscount(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[discount === null])


    return (
        <div className="DiscountPage">
            <div className="Discount-upper">
                <UpperPanel white={white}/>
            </div>
            <div className="Discount-lower business-discounts">
                <BusinessProfileSide/>
                <div className="">
                    {discount === null ? "discount getiriliyor..." : (<Discount data={discount} />) }
                </div>
                <BusinessRightSide white={white} setWhite={setWhite}/> 
            </div>

        </div>
    );
};

export default BusinessSingleDiscount;