import * as CafeteriaApi from "../api/CafeteriaRequests";

export const geTheMenu = () => async (dispatch) => {
    dispatch({type: "MENU_START"})
    try {
        const {data} = await CafeteriaApi.getMenu();
        
        dispatch({type: "MENU_SUCCESS", data: data});
    }catch(err){
        
        dispatch({type: "MENU_ERROR"})
        console.error(err)
    }

}