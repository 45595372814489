import React, { useState,useEffect} from 'react'
import "./TinyPost.css";
import { useSelector, useDispatch } from 'react-redux';
import PostModal from '../postModal/PostModal';

const TinyPost = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const [modalOpened,setModalOpened] = useState(false);

    return (
        <div className="TinyPost">
            <img src={data.mediaUrl} alt="" onClick={() => setModalOpened((prev) => !prev)}/>

            <PostModal data={data} modalOpened={modalOpened} setModalOpened={setModalOpened} />
        </div>
    )
}

export default TinyPost