import React from 'react'
import './Comment.css'
import moment from 'moment'

const comment = ({data}) => {
  return (
    <div className="Comment">
      
      <div className="comment-inside">
      <b>{data.username} </b> 
      <p>{data.description}</p>
          
      </div>
      <p style={{ color: "var(--gray)",fontSize:"12px"}}>
            {moment(new Date(data.timestamp)).fromNow()/*.fromNow()*/}
        </p>
      
    </div>
  )
}

export default comment