import React, {useState} from 'react'
import "./MarketplacePage.css";

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import MarketplacePosts from '../../components/marketplacePosts/MarketplacePosts';

const MarketplacePage = () => {
  const [white,setWhite] = useState(false);

  return (
    <div className="MarketplacePage">
            <div className="marketplacePage-upper">
               <UpperPanel white={white}/>
            </div>
            <div className="marketplacePage-lower">
                <ProfileSide/>
                <MarketplacePosts/>
                <RightSide white={white} setWhite={setWhite}/> 
            </div>

            <Chats/>
        </div>
  )
}

export default MarketplacePage