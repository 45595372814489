import * as BusinessApi from "../api/BusinessRequests.js";

export const updateBusiness = (id, FormData) => async (dispatch) => {
    dispatch({type: "BUPDATING_START"})
    try {
        const {data} = await BusinessApi.updateBusiness(id, FormData);
        dispatch({type: "BUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "BUPDATING_FAIL"})
    }
}

export const followBusiness = (id, Data) => async (dispatch) => {
    dispatch({type: "BFOLLOW_USER", data: id});
    BusinessApi.followBusiness(id, Data);
    
}

export const unfollowBusiness = (id, Data) => async (dispatch) => {
    dispatch({type: "BUNFOLLOW_USER", data: id});
    BusinessApi.unfollowBusiness(id, Data);
    
}

export const getBusiness = (id) => async (dispatch) => {
    
    try {
        const {data} = await BusinessApi.getBusiness(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const addBusinessVisitor = (id, Data) => async (dispatch) => {
    dispatch({type: "BADDING_START"})
    try {
        const {data} = await BusinessApi.addBusinessVisitor(id, Data);
        dispatch({type: "BADDING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "BADDING_FAIL"})
        console.error(err)
    }
}