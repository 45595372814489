import axios from 'axios';

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const getAllAds = () => API.get('/ads');

export const likeAd = (id,data) => API.put('/ads/'+id+'/like',data)

export const sendAdComment = (id,data) => API.put('/ads/'+id+'/comment',data);

export const getAdvertisement = (id) => API.get('/ads/'+id);

export const updateAdvertisement = (id,data) => API.put('/ads/'+id,data);

export const deleteAdvertisement = (id,data) => API.put('/ads/delete/'+id,data);
