import  secureLocalStorage  from  "react-secure-storage";

const setKeyAndId = () => {
    const keys = secureLocalStorage.getItem("key")

    if (keys === null){
        return undefined;
    } 
    const data = JSON.parse(keys);
    setKey(data.key);
    setKeyUserId(data.userid);
}


let privateKey
let userId

export const getKey = () => {
    setKeyAndId();
    return privateKey;
}

export const setKey = (key) => {
    privateKey = key;
}

export const getKeyUserId = () => {
    setKeyAndId();
    return userId;
}

export const setKeyUserId = (Id) => {
    userId = Id;
}
