import React,{useState,useEffect,useRef} from 'react'
import "./ChatsSearchbar.css";

import { getSecretUsers } from '../../api/UserRequests';

import { useSelector } from 'react-redux';

import { ChatsPng } from '../standardImages/StandardImages';


const ChatsSearchbar = ({persons, setPersons}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const [isEmpty,setIsEmpty] = useState(true); 
    const myRef = useRef();

    useEffect(() => {
      fetchPersons();
    }, []);

  const fetchPersons = async () => {
      const data = await getSecretUsers();
      setPersons(data.data);
    };


    const onChange = (event) => {
      
      if(event.target.value === ""){
          setIsEmpty(true);
          fetchPersons();
      }else{
          setIsEmpty(false);
      }

      setPersons(persons.filter((item) => {
          const searchItem = myRef.current.value.toLowerCase();
          const fullName = item.firstname.toLowerCase();
          return (
            searchItem && fullName.startsWith(searchItem) && item._id !== user._id
          );
        }));
    }

    /* <div className="vl"> </div> */

  return (
    <div className="chatsSearchbar">
      <div className="new-chat-button" >
        <img src={ChatsPng} alt="plus" />
      </div>
      

      <div className="chat-search-bar-input"> 
        <input type="text" placeholder="Mesaj Oluştur" ref={myRef} onChange={onChange} />
      </div>

    </div>
  )
}

export default ChatsSearchbar