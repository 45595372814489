import {combineReducers} from 'redux';

import authReducer from './AuthReducer.js';
import postReducer from './PostReducer.js';
import chatReducer from './ChatReducer.js';
import cafeteriaReducer from './CafeteriaReducer.js';
import forumReducer from './ForumReducer.js';
import discountReducer from './DiscountReducer.js';
import activityReducer from './ActivityReducer.js';
import marketplaceReducer from './MarketplaceReducer.js';

const appReducer = combineReducers({authReducer,postReducer,chatReducer,
    cafeteriaReducer,forumReducer,discountReducer,activityReducer,
    marketplaceReducer});

export const rootReducer = (state, action) => {
if (action.type === 'LOG_OUT') {
    state = undefined;
}
return appReducer(state, action);
};