import axios from 'axios';

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const deleteNotification = (id,data) => API.put('/notifications/'+id+"/delete",data);