import React, {useState} from 'react'
import "./ActivitiesPage.css";

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';

import Activities from '../../components/activities/Activities';

const ActivitiesPage = () => {
  const [white,setWhite] = useState(false);

  return (
    <div className="ActivitiesPage">
        <div className="Activities-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="Activities-lower">
            <ProfileSide/>
            <Activities />
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default ActivitiesPage