import axios from "axios";

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const getTimelineForums = () => API.get('/forums');

export const likeForumPost = (id,data) => API.put('/forums/'+id+'/like',data)

export const sendForumComment = (id,data) => API.put('/forums/'+id+'/comment',data);

export const getForum = (id) => API.get('/forums/'+id);

export const updateForum = (id,data) => API.put('/forums/'+id,data);

export const deleteForum = (id,data) => API.put('/forums/delete/'+id,data);