import React from 'react'
import './MenuContent.css';

import { Cafeteria } from '../standardImages/StandardImages';

const MenuContent = ({menu}) => {
  return (
    <div className="MenuContent">
    
        
        <div className="menu-date-title">
          <b>{menu.date}</b>
        </div>

        <div className="menu-container">
          <h6>Yemekler</h6>
          <p>
          {menu.soup || "sadasd"}
          <br/>
          {menu.main || "sadasd"}
          <br/>
          {menu.appetizer || "asdsad"}
          <br/>
          {menu.desert || "asdsad"}
          <br/>
          <b>* </b>{menu.vegeterian || "sadsad"}
          <br/>
          <strong>Kalori: </strong>{menu.calorie}
          </p>

          <img src={Cafeteria} alt="" />
        </div>
        
    
    </div>
  )
}

export default MenuContent