import React, {useEffect,useRef} from 'react';
import "./MarketplacePosts.css"
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import MarketplacePost from '../marketPlacePost/MarketplacePost'
import { getAllAds } from '../../actions/MarketplaceAction';

import ViewportList from "react-viewport-list";

const MarketplacePosts = () => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);

    useEffect(() => {
        dispatch(getAllAds());
    },[user]);


    let { ads,loading } = useSelector((state) => state.marketplaceReducer);

    if (params.id) ads = ads.filter( (post) => post.userId === params.id);

    if (!ads || ads.length === 0) return 'No Posts';

    /*
    ads.map((post,id) => {
            return <MarketplacePost data={post} key={id} />
        }
        )
        */

  return (
    <div className="MarketplacePosts" ref={ref}>
    {loading 
        ? "Fetching posts...."
        : <ViewportList viewportRef={ref} items={ads} itemMinSize={10} margin={8}>
        {(post,id) => {
          return <MarketplacePost data={post} key={id} />
        }}
        </ViewportList>
      }
    </div>
  )
}

export default MarketplacePosts