import React, {useState} from 'react'
import "./Cafeteria.css";

import Menu from '../../components/menu/Menu';
import RightSide from '../../components/rightSide/RightSide'
import ProfileSide from '../../components/profileSide/ProfileSide'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';

const Cafeteria = () => {
  const [white,setWhite] = useState(false);
  return (
    <div className="Cafeteria">
      <div className="Cafeteria-upper">
        <UpperPanel white={white}/>
      </div>
      <div className="Cafeteria-lower">
          <ProfileSide/>
          <Menu />
          <RightSide white={white} setWhite={setWhite}/>
      </div>

      <Chats/>
    </div>
  )
}

export default Cafeteria