import axios from "axios";

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const uploadImage = (data) => API.post('/upload/', data);

export const uploadPost = (data) => API.post('/posts/',data);

export const uploadForumPost = (data) => API.post('/forums/', data);

export const uploadDiscount = (data) => API.post('/discounts/', data);

export const addActivity = (data) => API.post('/activities/', data);

export const uploadAd = (data) => API.post('/ads/',data);

export const uploadIKData = (data,id) => API.post('/user/'+id+'/ik', data)