import React, { useState,useEffect,useRef} from 'react'
import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,authPicture,PurplePlusButton} from "../standardImages/StandardImages"
import {useParams,Link} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import {uploadImage} from "../../actions/UploadAction";
import { getIKData, updateUserIK, getUser,updateUser } from '../../actions/UserAction';
import { getBusiness,updateBusiness } from '../../actions/BusinessAction';

import { v4 } from "uuid";

import "./BusinessProfileBio.css";

const BusinessProfileBio = () => {
    const params =useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);

    const [isOpen,setIsOpen] = useState(false);
    const {password, ...other } = user;
    const [formData,setFormData] = useState(other);

    const [person1,setPerson] = useState(user);
    const [profilePicture,setProfilePicture] = useState(null);
    const profileUserId = params.id;
    const imageRef = useRef();
    const isUser = profileUserId === user._id;
    const disabled = "disabled";
    const enabled = !disabled;

    const fetchProfileUser = async () =>{
        if (!profileUserId || profileUserId === user._id){
            setPerson(user);
        }else{
            //console.log("fetching profile user...");
            //console.log(profileUserId)
            var ref
            ref = await getBusiness(profileUserId);
            ref().then((userData) => setPerson(userData))
            //console.log(person1)
        }
    }

    useEffect(() => {
        try{
        fetchProfileUser();
        }catch(err){
            console.error(err)
        }
        },[user]);

        const handleChange = (e) => {
            setFormData({...formData,[e.target.name]: e.target.value});
        };

        const onImageChange = (event) => {
            if (event.target.files && event.target.files[0]){
                let img = event.target.files[0];
                setProfilePicture(img)
    
            }
        };
    
        const handleSubmit = async (event) => {
            event.preventDefault();
    
            let UserData = formData;
            if (profilePicture){
                
                const fileName = "profilePic/images/"+ v4() + profilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
    
                try {
                    const imageUrl = await uploadImage(pictureData);
                    imageUrl().then((url) =>{
                        UserData.profilePicture = url;
                    dispatch(updateBusiness(params.id, UserData)); 
                })
                }catch (err) {
                    console.error(err);
                }
            }else{
                dispatch(updateBusiness(params.id, UserData));
        };
    }

    return (
        <form className="infoForm" style={{"padding-bottom":"1rem"}} onSubmit={handleSubmit}>
            <div className="ProfileBio">
                <div className="settings-profile-info"> 
                    <h6>Profil Detayları</h6>
                    <div style={{"position":"relative"}}> 
                        <div className="settings-profile-image-container">
                            <div className="settings-profile-image">
                                <img src={ 
                                    person1.profilePicture
                                    ? profilePicture ? URL.createObjectURL(profilePicture) : person1.profilePicture
                                    : profilePicture ? URL.createObjectURL(profilePicture) : defaultProfileImage 
                                } alt="ProfileImage" /> 
                            </div>
        
                            <div style={{display: "none"}}>
                                <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} disabled={isUser ? enabled : disabled } />
                            </div>
                        </div>
                        {isUser && ( 
                            <div className="settings-change-profile-image">
                                <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                            </div>
                        )}
                    </div>
                    <div className="settings-profile-info-container">
                        <div className="settings-profile-biography">
                            <h5>Biyografi</h5>
                            <textarea className="settings-profile-textarea" type="text" name="bio" value={formData.bio} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Konum</h5>
                            <input className="settings-profile-birthday" type="text" name="location" value={formData.location} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>
                    </div>
                </div>
            </div>
            { isUser
                ? (<button className="button infoButton" type="submit">
                    Update
                </button>)
                : ""}
        </form>
    );
};

export default BusinessProfileBio;