import React, {useState,useEffect,useRef} from 'react';
import "./Discount.css";
import * as StandardImages from '../standardImages/StandardImages';

import { likeDiscount } from '../../api/DiscountRequests';
import { sendDiscountComment } from '../../actions/DiscountAction';
import { useSelector, useDispatch } from 'react-redux';
import Comment from '../comment/Comment'
import moment from 'moment';

import ShareModal from '../shareModal/ShareModal';
import EditDiscountModal from '../editDiscountModal/EditDiscountModal'

import { Link } from "react-router-dom"

const Discount = ({data}) => {
    /*
                    <img className="shareBtn" src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} onClick={() => {navigator.clipboard.writeText("https://www.geyikapp.com:3000/discount/"+ data.discountId)}} />

                    */


    const commentRef = useRef('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const [liked,setLiked] = useState(data.likes.includes(user._id));
    const [likes,setLikes] = useState(data.likes.length)
    const [commentButton,setButtonPressed] = useState(false);
    const comments = data.comments

    const [clicked,setClicked] = useState(false);

    const [modalOpened1,setModalOpened1] = useState(false);
    const [modalOpened2,setModalOpened2] = useState(false);
    

    const handleLike = () => {
        likeDiscount(data.discountId,{
            ownerId : user._id,
            mediaUrl : user.profilePicture || "",
            username : user.isBusiness ? user.businessName : user.username,
        });
        setLiked((prev) => !prev);
        liked ? setLikes((prev) => prev-1) : setLikes((prev) => prev + 1)
    };

    const handleCommentButton = () => {
        setButtonPressed((prev) => !prev);
    }

    const handleComment = () => {
        setButtonPressed(false);

        const comment = {
            ownerId : user._id,
            username : user.isBusiness ? user.businessName : user.username,
            description : commentRef.current.value,
            mediaUrl : user.ProfilePicture || ""
        }
        try {
            dispatch(sendDiscountComment(data.discountId,comment))
            comments.push(comment);
        }catch (err){
                console.error(err);
        }
        
    }

    return(
        <div className="Discount">
        {data.mediaUrl ? (<span className={clicked ? "modal-discount-photo" : "discount-photo"} >
                <span className="dated">
                    {moment(data.timestamp).fromNow()}
                </span>
                <img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                    onClick={() => setClicked((prev) => !prev)}
                    /> 
            </span> ) : ""}
            
            <div className="discount-info">
                <div className="detail">
                    <span>
                        <Link className="link" to={"../profile/"+user._id} >
                            <b className="username">{data.businessName} </b>
                        </Link>
                    </span>
                    <span className="description">{data.description}</span>
                </div>
            </div>

            <div className="postReact">
                <img 
                    src={liked ? StandardImages.Heart : StandardImages.Dislike}
                    alt=""
                    style={{ cursor:"pointer"}}
                    onClick={handleLike}
                />
                <img 
                    src={StandardImages.Comment} 
                    alt= "" 
                    style={{ cursor:"pointer"}}
                    onClick={handleCommentButton} 
                />
                <div className="post-edit-delete">
                    {user._id === data.ownerId ? (<>
                        <img className="shareBtn" src={StandardImages.Pencil} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened2((prev) => !prev)} />
                    </>
                    ) : ""} 
                    <img className="shareBtn" src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened1((prev) => !prev)} />
                </div>   
            </div>
            
            {commentButton 
                ? <div className="writeComment">
                    <span className="comment-user-title" onClick={handleCommentButton} style={{cursor:"pointer"}}><b>{user.username} </b></span>
                    <input ref={commentRef} className="TextArea"/>
                    <button 
                        onClick={handleComment} 
                        className="button comment-submit"
                        type="submit"
                    >send</button>
                </div> 
                : ""}

                { comments.length > 0 && (
                    <div className="comments">
                        <div className="comments-inside">
                            { comments.map((comment,id) => {
                                return <Comment key={id} data={comment} />
                                })
                            }
                        </div>
                    </div>
                    )}

            <ShareModal modalOpened={modalOpened1} setModalOpened={setModalOpened1} data={ "https://www.geyikapp.com:3000/discount/"+ data.discountId } />
            <EditDiscountModal modalOpened={modalOpened2} setModalOpened={setModalOpened2} data={data} />

        </div>
    )


    /*
    return (
        <div className="Discount">
            <img src={data.image ? image : ""} alt="" />

            <div className="DiscountReact">
                <img 
                    src={liked ? StandardImages.Heart : StandardImages.Dislike}
                    alt=""
                    style ={{ cursor:"pointer"}}
                    onClick={handleLike}
                />
                <img 
                    src={StandardImages.Comment}
                    alt=""
                    style ={{ cursor:"pointer"}}
                    onClick={handleComment}
                />
                <img src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} />
            </div>

            <span style ={{ color: "var(--gray)", fontSize: "12px"}}>
                {likes} likes
            </span>

            <div className="detail">
                <span>
                    <b>{data.name} </b>
                </span>
                <span>{data.desc}</span> 
            </div>

            {commentButton 
                ? <div className="writeComment">
                    <b>{user.firstName}</b>
                    <input ref={commentRef} className="infoInput"/>
                    <button 
                        onClick={handleComment}
                        className="button infoButton"
                        type="submit"
                    >send</button>
                </div>
                : "" }

            {
                <div className="comments">
                { comments.map((comment,id) => {
                    return <Comment key={id} data={comment} />
                    })
                }
                </div>
            }
            <div className="dated" style={{ color: "var(--gray)",fontSize:"12px"}}>
            {moment(data.timestamp).fromNow()}
            </div>
            
        </div>
    );*/
};

export default Discount;
