import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import "./ChatUserWide.css";

import {ChatIcon} from '../standardImages/StandardImages';

import {storage} from "../../firebase"
import { ref, getDownloadURL} from "firebase/storage";

import { createChat,findChat,getUserChats } from '../../actions/ChatActions';
import {defaultProfileImage,defaultProfileBusiness} from "../standardImages/StandardImages"


const ChatUserWide = ({person,id}) => {
    const [profilePicture,setProfilePicture] = useState(defaultProfileImage);
    const user = useSelector((state) => state.authReducer.authData);
    const chat = useSelector((state) => state.chatReducer.chat)
    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;
    const dispatch = useDispatch();
    const [lastMessage,setLastMessage] = useState("");

    const handleChat = async () => {
        const data = { 
            senderId : user._id,
            receiverId : person._id 
        }

        try{
            const a = await findChat(user._id, person._id)
            if (a === "no chat found"){
                
                dispatch(createChat(data));
            }else{
                //
            }
            dispatch(getUserChats(user._id));
            
        }catch(e){console.error(e);}
    }

    /* resim işini persona çek*/


    return (
        <div className="ChatUserWide" onClick={handleChat}>
            <div className="chat-user-wide-info">
                <img src={
                    person.profilePicture
                ? person.profilePicture
                : defaultPicture
            } alt="profile" className="chat-user-wide-pp" />
                
                <div className="chat-user-wide-name">
                    <span>{person.firstname} {person.lastname}</span>
                    <span>{lastMessage}</span>
                </div>
            </div>

            <img src={ChatIcon} alt="" className="chat-user-wide-chat-logo"/>
            
            

        </div>
    );
}

export default ChatUserWide