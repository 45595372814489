import * as ForumApi from "../api/ForumRequests.js";

export const getTimelineForums = () => async (dispatch) => {
    dispatch({ type: "FRETREIVING_START"});
    try {
        const {data} = await ForumApi.getTimelineForums();
        dispatch({ type: "FRETREIVING_SUCCESS", data: data });
    }catch (err) {
        
        dispatch({ type: "FRETREIVING_FAIL"});
        console.error(err)
    }
};

export const sendForumComment = (id,data1) => async (dispatch) => {
    dispatch({ type: "FCOMMENT_START"});
        try {
            const {data} = await ForumApi.sendForumComment(id,data1);
            dispatch({ type: "FCOMMENT_SUCCESS"});
        }catch (err) {
            
            dispatch({ type: "FCOMMENT_FAIL"});
            console.error(err)

        }
}

export const getForum = (id) => async (dispatch) => {
    try {
        const {data} = await ForumApi.getForum(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const updateForum = (id,FormData) => async (dispatch) => {
    dispatch({type: "FUPDATING_START"})
    try {
        const {data} = await ForumApi.updateForum(id, FormData);
        dispatch({type: "FUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "FUPDATING_FAIL"})
    }
}

export const deleteForum = (id,FormData) => async (dispatch) => {
    dispatch({type: "FDELETE_START"})
    try {
        const {data} = await ForumApi.deleteForum(id,FormData);
        dispatch({type: "FDELETE_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "FDELETE_FAIL"})
    }
}