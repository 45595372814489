import React from 'react'
import './PostSide.css'
import PostShare from '../PostShare/PostShare'
import Discounts from '../discounts/Discounts'
import Posts from '../Posts/Posts'

import {useParams} from "react-router-dom";
import { useSelector } from 'react-redux';

const PostSide = ({location}) => {
  const user = useSelector((state) => state.authReducer.authData);
  const params =useParams();

  /*
  { (!params.id || params.id === user._id) && (
        <PostShare/>
      )}
  */

  return (
    <div className="PostSide">
        {
          user.isBusiness 
          ? ( <Discounts /> )
          :(<Posts location={location === "homePage"
            ? "homePage"
            : "profilePage"
            } /> )
        }
        
    </div>
  );
};

export default PostSide