import "./App.css"
import React, {useEffect} from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Home from "./pages/Home"
import Auth from "./pages/Auth/Auth"
import Profile from "./pages/Profile/Profile"
import Chat from "./pages/Chat/Chat"
import Forum from "./pages/Forum/Forum";
import Cafeteria from "./pages/Cafeteria/Cafeteria";
import BusinessAuth from "./pages/Business/Auth/BusinessAuth";
import BusinessHome from "./pages/Business/Home/BusinessHome";
import DiscountsPage from "./pages/DiscountsPage/DiscountsPage";
import ActivitiesPage from "./pages/ActivitiesPage/ActivitiesPage";
import MarketplacePage from "./pages/MarketplacePage/MarketplacePage";
import SettingsPage from "./pages/SettingsPage/SettingsPage";
import SinglePost from "./pages/SinglePost/SinglePost";
import SingleForum from "./pages/SingleForum/SingleForum";
import SingleActivity from "./pages/SingleActivity/SingleActivity";
import SingleDiscount from "./pages/SingleDiscount/SingleDiscount";
import SingleAdvertisement from "./pages/SingleAdvertisement/SingleAdvertisement";

import { useSelector,useDispatch } from "react-redux";

import {BrowserView, MobileView} from 'react-device-detect';

import {decrypt} from "./actions/EncryptAction";
import RegisterPage from "./pages/RegisterPage/RegisterPage";
import RegisterIKPage from "./pages/RegisterIKPage/RegisterIKPage";
import VerifyEmail from "./pages/VerifyEmail/VerifyEmail";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import BusinessRegister from "./pages/Business/Register/BusinessRegister";
import BusinessProfile from "./pages/Business/Profile/BusinessProfile";
import BusinessSettings from "./pages/Business/Settings/BusinessSettings";
import BusinessDiscounts from "./pages/Business/Discounts/BusinessDiscounts";
import BusinessSingleDiscount from "./pages/Business/BusinessSingleDiscount/BusinessSingleDiscount";


/*
#f5c32c
#fca61f;


#f9a225
#f95f35

orange

<Navigate to={"../business/discount/" + params.id } />

<Navigate to={"../discount/" + params.id } />
*/

function App() {
  const user = useSelector((state) => state.authReducer.authData);
  const ikData = useSelector((state) => state.authReducer.ikData);
  const params = useParams();

  /*<div className="blur" style={{top: '-18%', right: '0'}}></div>
            <div className="blur" style={{ top: '36%', left: '-8rem' }}></div>*/

  //console.log(decrypt("123"));

  
  if (user && !user.emailVerified) {
    return (
      <div className="App" style={{ height: "100vh"}}>

      <BrowserView>
        <Routes>
          <Route path="/" element={user ? ( user.isBusiness ? <Navigate to="business/home"/> : <Navigate to="../home"/> ) : <Navigate to="../auth" />} />
          <Route path="/registerhr" element={user ? <RegisterIKPage /> : <Navigate to="../auth" />} />
          <Route path="/verify" element={user ? <VerifyEmail/> : <Navigate to="../auth" /> } />
          <Route path="*" element={<Navigate to="verify" />} />
        </Routes>
      </BrowserView>

    <MobileView>
      <div>
        No mobile app
      </div>
    </MobileView>
          </div>
      );
  }
  /*
  else if(user && ikData === null && !user.isBusiness){
      return (
        <div className="App" style={{ height: "100vh"}}>
  
        <BrowserView>
          <Routes>
            <Route path="/" element={user ? ( user.isBusiness ? <Navigate to="business/home"/> : <Navigate to="../home"/> ) : <Navigate to="../auth" />} />
            <Route path="/registerhr" element={user ? <RegisterIKPage /> : <Navigate to="../auth" />} />
            <Route path="*" element={<Navigate to="registerhr" />} />
          </Routes>
        </BrowserView>
  
      <MobileView>
        <div>
          No mobile app
        </div>
      </MobileView>
            </div>
        );
  }*/
  
  return (
    <div className="App" style={{
      height: window.location.href === "http://www.geyikapp.com:3000/chat/"
    ? "calc(100vh - 2rem)"
    : "auto",
    }}>
        

          <BrowserView>
            <Routes>
              <Route path="/registerhr" element={user ? ( !user.isBusiness ? <RegisterIKPage /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/" element={user ? ( user.isBusiness ? <Navigate to="business/home"/> : <Navigate to="../home"/> ) : <Navigate to="../auth" />} />
              <Route path="/verify" element={user ? <VerifyEmail/> : <Navigate to="../auth" /> } />
              <Route path="/register" element={user ? ( !user.isBusiness ? <RegisterIKPage /> : <Navigate to="../business/home"/> ) : <RegisterPage/>} />

              <Route path="/business/register" element={user ? ( user.isBusiness ? <BusinessHome /> : <Navigate to="../home"/> ) : <BusinessRegister/>} />
              <Route path="/home" element={user ? ( !user.isBusiness ? <Home /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/auth" element={user ? ( user.isBusiness ? <Navigate to="../business/home" /> : <Navigate to="../home"/> ) : <Auth/>} />
              <Route path="/reset-password" element={user ? ( user.isBusiness ? <Navigate to="../business/home" /> : <Navigate to="../home"/> ) : <ResetPassword/>} />
              <Route path="/profile/:id" element={user ? ( user.isBusiness ? <BusinessProfile /> : <Profile /> ) : <Navigate to="../auth" />} />
              <Route path="/business/profile/:id" element={user ? ( user.isBusiness ? <BusinessProfile /> : <Profile /> ) : <Navigate to="../auth" />} />
              <Route path="/forums" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <Forum />) : <Navigate to="../auth" />} />
              <Route path="/business/auth" element={user ? ( user.isBusiness ? <BusinessHome /> : <Navigate to="../home"/> ) : <BusinessAuth/>} />
              <Route path="/business/home" element={user ? ( user.isBusiness ? <BusinessHome/> : <Navigate to="../home"/> ) : <Navigate to="../auth"/>} />
              <Route path="/activities" element={user ? ( !user.isBusiness ? <ActivitiesPage /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/marketplace" element={user ? ( !user.isBusiness ? <MarketplacePage /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/profile/:id/settings" element={user ? ( !user.isBusiness ? <SettingsPage /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/discounts" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <DiscountsPage /> ) : <Navigate to="../auth" />} />
              <Route path="/cafeteria" element={user ? ( !user.isBusiness ? <Cafeteria /> : <Navigate to="../business/home"/> ) : <Navigate to="../auth" />} />
              <Route path="/business/profile/:id/settings" element={user ? ( user.isBusiness ? <BusinessSettings /> : <Navigate to="../home"/> ) : <Navigate to="../auth" />} />
              <Route path="/business/discounts" element={user ? ( user.isBusiness ? <BusinessDiscounts/> : <Navigate to="../home"/> ) : <Navigate to="../auth" />} />


              <Route path="/post/:id" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <SinglePost />) : <Navigate to="../auth" />} />
              <Route path="/forum/:id" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <SingleForum />) : <Navigate to="../auth" />} />
              <Route path="/activity/:id" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <SingleActivity />) : <Navigate to="../auth" />} />
              <Route path="/discount/:id" element={user ? ( user.isBusiness ? <BusinessSingleDiscount/> : <SingleDiscount />) : <Navigate to="../auth" />} />
              <Route path="/advertisement/:id" element={user ? ( user.isBusiness ? <Navigate to="../business/home"/> : <SingleAdvertisement />) : <Navigate to="../auth" />} />

              <Route path="/business/discount/:id" element={user ? ( user.isBusiness ? <BusinessSingleDiscount/> : <SingleDiscount /> ) : <Navigate to="../auth" />} />


              <Route path="*" element={
                  <main style={{ padding: "1rem"}}>
                    <p>There's nothing here!</p>
                  </main>
                } 
              />
              {/*<Route path="/chat" element={user ? <Chat/> : <Navigate to="../auth" />} />*/}
              
            </Routes>
          </BrowserView>

          <MobileView>
            <div>
              No mobile app
            </div>
          </MobileView>
    </div>
  );
}

export default App;
