import React, { useState } from 'react';
import "./BusinessCreateNavbar.css";

import MenuItem from "../menuItem/MenuItem";
import {Discounts} from '../standardImages/StandardImages';

const BusinessCreateNavbar = ({choosePopUp}) => {
    return (
      <div className="BusinessCreateNavbar">
          <ul className="s-navbar-menu">
              <li className="s-nb-menu-item" onClick={() => choosePopUp(1)}>
                  <MenuItem navLogo={Discounts} navName="Kampanya Paylaş" />
              </li>
              
          </ul>
      </div>
    )
  }
  
  export default BusinessCreateNavbar