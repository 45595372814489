import React from 'react'
import './MenuItem.css'

const MenuItem = ({navName,navLogo}) => {
  return (
    <div className="MenuItem">
        <div className="navText">{navName}</div>
        <img className="navLogo" src={navLogo} alt={navLogo} />
    </div>
  )
}

export default MenuItem