import React from 'react';
import {Modal, useMantineTheme} from '@mantine/core';
import { BusinessLogo, PurplePlusButton } from '../standardImages/StandardImages';
import moment from 'moment';
import EditActivity from '../editActivity/EditActivity';
import { useSelector } from 'react-redux';
import "./CheckModal.css";


const CheckModal = ({modalOpened,setModalOpened,data}) => {
    const theme = useMantineTheme();
    const loading = useSelector((state) => state.activityReducer.updateLoading)

    if( loading ){
        setModalOpened(false)
    }

    /*overlayColor={
            theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity = {0.55}
        overlayBlur = {3}*/

    return (
        <Modal 
        size = '55%'
        opened={modalOpened}
        onClose={() => setModalOpened(false)} 
        >
            <div className="CheckModal">
                <b>Gönderiyi Silmek istediğinizden emin misiniz ?</b>

                <div className="checkmodal-buttons">
                    <button
                        className="button infoButton"
                        type="submit"
                        onClick={data}
                        >
                            Sil
                    </button>

                    <button
                        className="button infoButton"
                        type="submit"
                        onClick={() => setModalOpened(false)} 
                        >
                            Vazgeç
                    </button>
                </div>
            </div>
            

            
        </Modal>
    );
};

export default CheckModal