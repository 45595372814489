import React, {useState} from 'react'
import "./DiscountsPage.css";

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import Discounts from '../../components/discounts/Discounts';

const DiscountsPage = () => {
    const [white,setWhite] = useState(false);


    return (
        <div className="DiscountPage">
            <div className="Discount-upper">
                <UpperPanel white={white}/>
            </div>
            <div className="Discount-lower">
                <ProfileSide/>
                <Discounts />
                <RightSide white={white} setWhite={setWhite}/> 
            </div>

            <Chats/>
        </div>
    );
};

export default DiscountsPage;
