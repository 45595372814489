import React, { useState } from 'react'
import "./BusinessNavbar.css";
import MenuItem from "../menuItem/MenuItem";
import { Link } from "react-router-dom";

import { Discounts,SettingsIcon } from '../standardImages/StandardImages';

const BusinessNavbar = () => {
    return (
      <div className="BusinessNavbar">
          <ul className="s-navbar-menu">
            <li className="s-nb-menu-item">
              <Link to="../business/discounts" >
                <MenuItem navLogo={Discounts} navName="Kampanyalar" />
              </Link>
            </li>
            
          </ul>
      </div>
    )
  }
  
  export default BusinessNavbar