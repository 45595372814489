import React, {useState,useRef,useEffect} from 'react';
import "./CreateActivity.css"

import ActivityPreview from "../activityPreview/ActivityPreview";
import { v4 } from "uuid";
import {useDispatch,useSelector} from "react-redux";
import { UilTimes } from "@iconscout/react-unicons";
import {defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo, CinemaT
    ,ChatT, DanceT, HolidayT, MusicT, MeetT, PartyT, SporT, StudyingT, TravelT} from '../standardImages/StandardImages'
import {uploadImage,addActivity} from "../../actions/UploadAction";

import ActivityType from '../activityType/ActivityType';

const CreateActivity = ({choosePopUp}) => {
    const [type,setType] = useState(1);
    const title = useRef();
    const desc = useRef();
    const startDate = useRef();
    const media = useRef();
    const location = useRef();
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.activityReducer.loading);
    const dispatch = useDispatch();
    const [image,setImage] = useState(null);
    const [preview,setPreview] = useState({
        ownerId: user._id,
        username: '',
        description: '',
        title: "",
        image: "",
        location: "",
        type: "",
        date: "",
    });

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setImage(img);
        }
        setPreview1()
    };

      useEffect( () => {
        (function(){
            function scrollH(e){
                e.preventDefault();
                e = window.event || e;
                let delta = Math.max(-1,Math.min(1,
                    (e.wheelDelta || -e.detail)));
                document.querySelector('.activity-types').scrollLeft -= (delta *10);
          }if (document.querySelector('.activity-types').addEventListener){
                document.querySelector('.activity-types').addEventListener('mousewheel',scrollH,false);
                document.querySelector('.activity-types').addEventListener('DOMMouseScroll',scrollH,false);
          }
        })()
      })

      const setPreview1 = () => {
        setPreview({
            ownerId: user._id,
            username: user.username,
            description: desc.current.value,
            type: type,
            title: title.current.value,
            location: location.current.value,
            date: startDate.current.value,
            image: image,
        });
      }

      const handleUpload = async (event) => {
        event.preventDefault();

        if (image === null || image === undefined) {
            return;
        }
        //post data
        const newPost = {
            ownerId: user._id,
            username: user.username,
            description: desc.current.value,
            type: type,
            title: title.current.value,
            location: location.current.value,
            date: startDate.current.value,
        };

        if(newPost.description === "" || newPost.title === "" ){
            return
        }

        if (image){
            const fileName = "activities/images/" + v4() + image.name;
            const imageDat = {
                fileName : fileName,
                file : image,
            }
            //newPost.image = fileName;
            try{
                //dispatch(uploadImage(imageDat))
                const imageUrl = await uploadImage(imageDat);
                imageUrl().then((url) =>{
                    newPost.image = url;
                    dispatch(addActivity(newPost))
                    choosePopUp(3)
                })
            }catch (err) {
                console.error(err)
            }
        }else{
            newPost.image = "";
            dispatch(addActivity(newPost))
            choosePopUp(3)
        }
        resetShare();
      };

      const resetShare = () => {
        setImage(null);
        //desc.current.value = "";
        setType(0);
        title.current.value = "";
        media.current.value = "";
      };

  return (
    <div className="CreateActivity">
        <div className="forum-close-button">
            <div className="header-activity-create">
                <h6>Etkinilk Önizleme</h6>
            </div>
            
            <img src={CloseButton} alt="" onClick={() => choosePopUp(3)}/>
        </div>

        <div className="activity-preview-container">
            
            { <ActivityPreview data={preview} /> }
        </div>

        <div className="create-activity-container">

            <div className="header-activity-create">
                <h6>Etkinilk Oluşturun</h6>
            </div>

            <div className="activity-types">
                <ul className="activity-types-list">
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Sohbet")} data={{"image" : ChatT, "type" : "Sohbet" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Sinema")} data={{"image" : CinemaT, "type" : "Sinema" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Dans")} data={{"image" : DanceT, "type" : "Dans" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Tatil")} data={{"image" : HolidayT, "type" : "Tatil" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Buluşma")} data={{"image" : MeetT, "type" : "Buluşma" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Müzik")} data={{"image" : MusicT, "type" : "Müzik" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Parti")} data={{"image" : PartyT, "type" : "Parti" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Spor")} data={{"image" : SporT, "type" : "Spor" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Ders Çalışma")} data={{"image" : StudyingT, "type" : "Ders Çalışma" }} onChange={setPreview1}/>
                    </li>
                    <li className="activity-type">
                        <ActivityType onClick={() => setType("Gezi")} data={{"image" : TravelT, "type" : "Gezi" }} onChange={setPreview1}/>
                    </li>
                </ul>
            </div>

            <div className="activity-add-media">

                {!image 
                    ? (<> <img className="form-photo-image" src={PlusLogo} alt="" onClick={() => media.current.click()}/>
                        <p>Medya, Fotoğraf, Video ekleyin</p> </>) 

                : (
                    <div className="previewImage">
                        <UilTimes className="close-btn-preview" onClick={() => {
                        setImage(null)
                        media.current.value = ""}} />
                        <img src={URL.createObjectURL(image)} alt="preview" />
                    </div>
                )}

                <div style={{display: "none"}}>
                <input type="file" ref={media} onChange={onImageChange} required />
                </div>

            </div>

            <div className="activity-add-title">
                <h6>Etkinliğiniz için başlık girin</h6>
                <input className="settings-profile-birthday" type="text" ref={title} placeholder="Başlık" required onChange={setPreview1}/>
            </div>

            <div className="activity-add-title">
                <h6>Etkinliğiniz nerede gerçekleşecek</h6>
                <input className="settings-profile-birthday" type="text" ref={location} placeholder="Lokasyon" required onChange={setPreview1}/>
            </div>

            <div className="activity-add-title">
                <h6>Etkinliğiniz için kısa bir açıklama giriniz</h6>
                <input className="settings-profile-birthday" type="text" ref={desc} placeholder="Açıklama ..." required onChange={setPreview1}/>
            </div>

            <div className="activity-add-title">
                <h6>Etkinliğinizin başlama zamanını giriniz</h6>
                <input className="settings-profile-birthday" type="text" ref={startDate} placeholder="Tarih" required onChange={setPreview1}/>
            </div>

            <div className="activity-add-privacy">
                <h6>Etkinliğinizi gizleyin</h6>
                <input className="settings-profile-birthday" type="checkbox" required onChange={setPreview1}/>
            </div>

            <button className="button post-share-btn" onClick={handleUpload} disabled={loading}>Paylaş</button>

        </div>


    </div>
  )
}

export default CreateActivity