import React, {useRef,useState,useEffect} from 'react';
import "./ModalActivity.css";

import moment from 'moment';
import * as StandardImages from '../standardImages/StandardImages'
import { useSelector, useDispatch } from 'react-redux';


import { addLikeActivity,sendJoinRequest } from '../../api/ActivityRequests';
import { addCommentActivity } from '../../actions/ActivityAction.js';
import Comment from '../comment/Comment'

import ShareModal from '../shareModal/ShareModal';
import EditActivityModal from '../editActivityModal/EditActivityModal';
import CheckModal from '../checkModal/CheckModal';

import { Link } from "react-router-dom"

const ModalActivity = ({data}) => {
  const user = useSelector((state) => state.authReducer.authData);
    const [liked,setLiked] = useState( data.likes.includes(user._id) )//data.likes.includes(user._id));
    const [likes,setLikes] = useState( data.likes.length )//data.likes.length)


    const commentRef = useRef('');
    const dispatch = useDispatch();
    const [commentButton,setButtonPressed] = useState(false);
    const comments = data.comments

    const [modalOpened1,setModalOpened1] = useState(false);
    const [modalOpened2,setModalOpened2] = useState(false);


    const handleLike = () => {
        addLikeActivity(data.activityId,{
            mediaUrl: user.ProfilePicture || "",
            username: user.username,
            ownerId: user._id
            });
      setLiked((prev) => !prev);
      liked ? setLikes((prev) => prev-1) : setLikes((prev) => prev + 1)
  };

  const handleCommentButton = () => {
      setButtonPressed((prev)=> !prev);
  }

  const handleComment = () => {
      setButtonPressed(false);

      const comment = {
        ownerId : user._id,
        username : user.username,
        description : commentRef.current.value,
        mediaUrl : user.ProfilePicture || ""
    }
      try {
          dispatch(addCommentActivity(data.activityId,comment))
          comments.push(comment);
      }catch (err){
              console.error(err);
      } 
  }

    const handleJoin = () => {
        
        sendJoinRequest(data.activityId,{
            senderId : user._id,
            username : user.username,
            mediaUrl : user.ProfilePicture || ""
        });

        data.requested.push(user._id)
    }

  return (
    <div className="ModalActivity">
        <div className="modal-activity-center">
            <div className="modal-activity-image-area">
                {data.mediaUrl ? (<img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                /> ) : ""
                }
                

                <div className="modal-activity-info">
                    <div className="modal-activity-info-container">
                    <h4>{data.title}</h4>
                    <div className="activity-info-description">
                        <h6>{moment(data.timestamp).fromNow()}</h6>
                        <h4>{data.date}</h4>
                        <h4>{data.location}</h4>
                        <p>{data.description}</p>
                    </div>
                    </div>
                    <div className="modal-activity-image-plus">
                    <div className="modal-activity-plus">
                        <h6>{""}</h6>
                        <h1 className="modal-activity-join-button">Etkinliğe Katıl</h1>
                        { (data.requested.includes(user._id) || data.members.includes(user._id)) ? "" : <img src={StandardImages.PurplePlusButton} onClick={handleJoin} alt=""/>}
                    </div>
                    </div>
                </div>
            </div>
        </div>
        

        <div className="modal-info-area">
        <div className="modal-detail">
            <span>
            <Link className="link" to={"../profile/"+data.ownerId} >
            <b className="username">{data.username} </b>
        </Link>
            </span>
        </div>
        <span style={{ color: "var(--gray)",fontSize:"12px"}}>
            {likes === 0 ? "Bunu ilk beğenen sen ol" : (likes)+" likes" }
        </span>

    </div>

    

    

    <div className="modal-postReact">
        <img 
            src={liked ? StandardImages.Heart : StandardImages.Dislike}
            alt=""
            style={{ cursor:"pointer"}}
            onClick={handleLike}
        />
        <img 
            src={StandardImages.Comment} 
            alt= "" 
            style={{ cursor:"pointer"}}
            onClick={handleCommentButton} 
        />
        <div className="post-edit-delete">
            {user._id === data.ownerId ? (<>
                <img className="shareBtn" src={StandardImages.Pencil} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened2((prev) => !prev)} />            </>
            ) : ""} 
            <img className="shareBtn" src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened1((prev) => !prev)} />
        </div>  
    </div>
    
    {commentButton 
        ? <div className="writeComment">
            <span className="comment-user-title" onClick={handleCommentButton} style={{cursor:"pointer"}}><b>{user.username} </b></span>
            <input ref={commentRef} className="TextArea"/>
            <button 
                onClick={handleComment} 
                className="button comment-submit"
                type="submit"
            >send</button>
        </div> 
        : ""}

        { comments.length > 0 && (
            <div className="comments">
                <div className="comments-inside">
                    { comments.map((comment,id) => {
                        return <Comment key={id} data={comment} />
                        })
                    }
                </div>
            </div>
            )}

          <ShareModal modalOpened={modalOpened1} setModalOpened={setModalOpened1} data={ "https://www.geyikapp.com:3000/activity/"+data.activityId } />

    <EditActivityModal modalOpened={modalOpened2} setModalOpened={setModalOpened2} data={data} />

    </div>
  )
}

export default ModalActivity