import React, {useRef,useState,useEffect} from 'react';
import "./EditAd.css"
import {storage} from "../../firebase"
import { updateAdvertisement, deleteAdvertisement } from '../../actions/MarketplaceAction';
import moment from 'moment';
import * as StandardImages from '../standardImages/StandardImages'
import { useSelector, useDispatch } from 'react-redux';
import CheckModal from '../checkModal/CheckModal';

const EditAd = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.marketplaceReducer.loading);
    const dispatch = useDispatch();

    const [forum, setForum] = useState(data);
    const [modalOpened3,setModalOpened3] = useState(false);

    const handleChange = (e) => {
        setForum({...forum , [e.target.name]:e.target.value});
    };

    const handleDelete = () => {
        try{
            const imgRef = storage.refFromURL(data.mediaUrl);
            imgRef.delete();
        }catch(error){
        }
        const deleteData = {userId: user._id};
        dispatch(deleteAdvertisement(data.advertisementId,deleteData))
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        forum.userId = user._id;
        dispatch(updateAdvertisement(data.advertisementId, forum))
        window.location.reload();
    }
    
    
    
    return (
        <div className="EditAd">
        <div className="modal-discount-photo">
        <span className="dated">
          {moment(data.timestamp).fromNow()}
        </span>
        {data.mediaUrl ? (<img 
            src={data.mediaUrl ? data.mediaUrl : ""}
            alt= "" 
        /> ) : ""}
      </div>

      <div className="marketplace-post-desc-area">
          <div className="marketplace-post-desc-area-inner">
          <h2>{data.username}</h2>
          <textarea className="edit-textarea" type="text" value={forum.description} name="description" onChange={handleChange}/>
          </div>

          <div className="">
            <input className="edit-input" type="text" value={forum.price} name="price" onChange={handleChange}/> TL
          </div>
      </div>

      <div className="edit-buttons">
      <button
          className="button infoButton"
          type="submit"
          disabled={loading}
          onClick={handleSubmit}
          >
              {loading ? "Updating..." : "Update"}
      </button>

      <button className="button infoButton" onClick={() => setModalOpened3((prev) => !prev)}> 
              Sil
      </button>
  </div>

            <CheckModal modalOpened={modalOpened3} setModalOpened={setModalOpened3} data={ handleDelete } />


        </div>
    )
}

export default EditAd