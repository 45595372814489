import React from 'react';
import "./Setting.css";

import { OpenedButton,ClosedButton } from '../standardImages/StandardImages';

const Setting = ({data,isOpen,setIsOpen}) => {
  return (
    <div className="Setting">
        <div className="setting-container">

            <div className="setting-title-desc">
                <h5>{data.title}</h5>
                <h3>{data.desc}</h3>
                <h6>2022</h6>
            </div>

        {/* <img src={isOpen ? OpenedButton : ClosedButton} onClick={() => setIsOpen((prev) => !prev)} className="setting-icon" /> */}
        </div>
    </div>
  )
}

export default Setting