import React, {useEffect,useRef} from 'react';
import "./MyMarketplace.css";

import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { getAllAds } from '../../actions/MarketplaceAction';

import MarketplaceAds from '../marketplaceAds/MarketplaceAds';

import ViewportList from "react-viewport-list";

const MyMarketplace = () => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);

    useEffect(() => {
        dispatch(getAllAds());
    },[]);

    let { ads,loading } = useSelector((state) => state.marketplaceReducer);

    if (params.id){ ads = ads.filter( (post) => post.ownerId === params.id);}
    else{
        ads = ads.filter( (post) => post.ownerId === user._id);
    }

    if (!ads || ads.length === 0) return 'No Advertisement';


    /*
    ads.map((post,id) => {
                        return <MarketplaceAds data={post} key={id} />
                    }
                    ) */

    return (
        <div className="MyMarketplace">
            <div className="marketplace-header">
                <h6>Marketplace</h6>
            </div>

            <div className="marketplace-ads-container" ref={ref }>
                {loading 
                    ? "Fetching posts...."
                    : <ViewportList viewportRef={ref} items={ads} itemMinSize={10} margin={8}>
                    {(post,id) => {
                        return <MarketplaceAds data={post} key={id} />
                    }}
                    </ViewportList>
                }
            </div>


        </div>
    )
}

export default MyMarketplace