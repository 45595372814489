import * as AuthApi from "../api/AuthRequests";
import {auth,db,emailVerify,createUser} from "../firebase.js"
import { doc, getDoc,setDoc } from "firebase/firestore";
import { encrypt,decrypt } from "./EncryptAction";
import { setKey,setKeyUserId } from "../api/PrivateKeyContainer";
import { v4 } from "uuid";
import  secureLocalStorage  from  "react-secure-storage";
import { getIKData } from './UserAction';

import { uploadImage } from "./UploadAction";


export const verify = (formData, navigate) => async (dispatch) => {
    //user alamadan emaili gönderemiyor
    dispatch({ type: "VERIFY_START" });
    try {
        //console.log(formData.email);
        const currentUser = auth.currentUser
        emailVerify(currentUser).then(()=>{
            console.log("email sent");
        });
        //emailVerify(userRecord.user)
        dispatch({ type: "VERIFY_SUCCESS" });
        navigate("../auth", { replace: true });
    } catch (err) {
        dispatch({ type: "VERIFY_FAIL" });
        console.error(err);
        
    }
}

/*export const signUp = (formData, navigate) => async (dispatch) => {
    dispatch({ type: "REGISTER_START"});

    try{
        const { data } = await AuthApi.signUp(encrypt(formData))
        console.log(data)
        dispatch({ type: "REGISTER_SUCCESS", data: data });
        navigate("../register/ik", { replace: true });
    }catch(err){
        
        dispatch({ type: "REGISTER_FAIL"});
        console.error(err);
    }
}*/

export const signUp = (formData,imageData, navigate) => async (dispatch) => {
    dispatch({ type: "REGISTER_START"});

    try{
        await createUser(auth,formData.email,formData.password).then((userCredential) => {
            // Signed in 
            const user = userCredential.user;
            formData.uid = user.uid;
            const {password,confirmPass, ...otherData} = formData;
            if(imageData !== null){
                
                const imageUrl = uploadImage(imageData);
                imageUrl().then((url) =>{
                    otherData.image = url;
                    const data = AuthApi.signUp(otherData).then((user) =>  {
                        dispatch({ type: "REGISTER_SUCCESS", data: user.data });
                        navigate("../registerhr", { replace: true });
                    }).catch((err) =>{
                        dispatch({ type: "REGISTER_FAIL"});
                        console.error(err);
                    })
                })
            }else{
                otherData.image = "";
                const data = AuthApi.signUp(otherData).then((user) =>  {
                    dispatch({ type: "REGISTER_SUCCESS", data: user.data });
                    navigate("../registerhr", { replace: true });
                }).catch((err) =>{
                    dispatch({ type: "REGISTER_FAIL"});
                    console.error(err);
                })
            }
            
            
        })

    }catch(err){
        dispatch({ type: "REGISTER_FAIL"});
        console.error(err);
    }
}

export const logIn = (formData, navigate) => async (dispatch) =>{
    dispatch({ type: "AUTH_START" });
    const { email, password } = formData;
    var data = null

    try {
        const user = await auth.signInWithEmailAndPassword(email, password).then((userRecord) => {
            return userRecord.user
        });

        const docRef = doc(db, 'users', user.uid)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            data = docSnap.data();
        } else {
            console.log("undefined inside")  
        } 
        const { visitors, ...other} = data;
        

        if (user.emailVerified){
            other.emailVerified = true;

            // send firebase to key

            const key = v4();
            await setDoc(doc(db, "userKeys", user.uid), {
                userID: user.uid,
                privateKey: key,
                date: Date(),
              });
              
              setKey(key);
              setKeyUserId(user.uid);
              secureLocalStorage.setItem("key", JSON.stringify({key: key, userid: user.uid}));
            
            dispatch({ type: "AUTH_SUCCESS", data: other });
            dispatch(getIKData(user.uid,navigate))

        }else{
            other.emailVerified = false;
            dispatch({ type: "AUTH_SUCCESS", data: other });
            navigate("../verify", { replace: true });
        }

        
    } catch (err) {
        
        dispatch({ type: "AUTH_FAIL" });
        console.error(err);
    }
};


export const logInBusiness = (formData, navigate) => async (dispatch) =>{
    dispatch({ type: "AUTH_START" });
    const { email, password } = formData;
    var data = null

    try {
        const user = await auth.signInWithEmailAndPassword(email, password).then((userRecord) => {
            return userRecord.user
        });

        const docRef = doc(db, 'business', user.uid)
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
            data = docSnap.data();
        } else {
            console.log("undefined inside")  
        } 
        const { visitors, ...other} = data;
        

        if (user.emailVerified){
            other.emailVerified = true;

            // send firebase to key

            const key = v4();
            await setDoc(doc(db, "userKeys", user.uid), {
                userID: user.uid,
                privateKey: key,
                date: Date(),
              });
              
              setKey(key);
              setKeyUserId(user.uid);
              secureLocalStorage.setItem("key", JSON.stringify({key: key, userid: user.uid}));

            dispatch({ type: "AUTH_SUCCESS", data: other });
            navigate("../business/home", { replace: true });
        }else{
            other.emailVerified = false;
            dispatch({ type: "AUTH_SUCCESS", data: other });
            navigate("../verify", { replace: true });
        }

        
    } catch (err) {
        
        dispatch({ type: "AUTH_FAIL" });
        console.error(err);
    }
};


export const logout = (navigate) => async (dispatch) => {
    try {
        const user = await auth.signOut();
        dispatch({ type: "LOG_OUT" })
        navigate("../", { replace: true });
        
    } catch (err) {
        console.error(err);
    }
};


export const resetPassword = async (formData, navigate) => {
    try {
        await auth.sendPasswordResetEmail(formData.email).then(() => {
            navigate("../auth", { replace: true });
        })
    } catch (err) {
        console.error(err);
    }
};

/*
export const updateEmail = async (formData, navigate) => {
    dispatch({ type: "UPDATING_START" })
    try {
        await auth.updateEmail(req.body.email).then((userCredential) => {
            const user = userCredential.user;

            const { data } = AuthApi.updateUserDetails(user.uid, formData);
            dispatch({ type: "UPDATE_SUCCESS", data: data });
            navigate("../profile", { replace: true });
            
            res.status(200).json("saved successfully")
        })

    } catch (err) {
        dispatch({ type: "UPDATING_FAIL" })
        res.status(500).json({ message: err.message })
    }

};

export const updatePassword = async (formData, navigate) => {
    dispatch({ type: "UPDATING_START" })
    try {
        await auth.updatePassword(req.body.password).then((userCredential) => {
            const user = userCredential.user;

            const { data } = AuthApi.updateUserDetails(user.uid, formData);
            dispatch({ type: "UPDATE_SUCCESS", data: data });
            navigate("../profile", { replace: true });
        })

        res.status(200).json("saved successfully")
    } catch (err) {
        dispatch({ type: "UPDATING_FAIL" })
        res.status(500).json({ message: err.message })
    }
};*/