import * as DiscountApi from "../api/DiscountRequests.js";

export const getTimelineDiscounts = () => async (dispatch) => {
    dispatch({ type: "DRETREIVING_START"});
    try {
        const response = await DiscountApi.getTimelineDiscounts();
        dispatch({ type: "DRETREIVING_SUCCESS", data: response.data });
    }catch (err) {
        dispatch({ type: "DRETREIVING_FAIL" });
        console.error(err)
    }
}

export const sendDiscountComment = (id, Data) => async (dispatch) => {
    dispatch({ type: "DCOMMENT_START"});
    try {
        const response = await DiscountApi.commentDiscount(id, Data);
        dispatch({ type: "DCOMMENT_SUCCESS", data: response.data });
    }catch (err) {
        
        dispatch({ type: "DCOMMENT_FAIL" });
        console.error(err)
    }
}

export const getDiscount = (id) => async (dispatch) => {
    try {
        const {data} = await DiscountApi.getDiscount(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const updateDiscount = (id,FormData) => async (dispatch) => {
    dispatch({type: "DUPDATING_START"})
    try {
        const {data} = await DiscountApi.updateDiscount(id, FormData);
        dispatch({type: "DUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "DUPDATING_FAIL"})
    }
}

export const deleteDiscount = (id,FormData) => async (dispatch) => {
    dispatch({type: "DDELETE_START"})
    try {
        const {data} = await DiscountApi.deleteDiscount(id,FormData);
        dispatch({type: "DDELETE_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "DDELETE_FAIL"})
    }
}