import * as ActivityApi from "../api/ActivityRequests.js";

export const getAllActivities = () => async (dispatch) => {
    dispatch({ type: "ARETREIVING_START"});
    try {
        const {data} = await ActivityApi.getAllActivities();
        dispatch({ type: "ARETREIVING_SUCCESS", data: data });
    }catch (err) {
        dispatch({ type: "ARETREIVING_FAIL"});
        console.error(err)
    }
};

export const getPersonalActivities = (id) => async (dispatch) => {
    try {
        const {data} = await ActivityApi.getPersonalActivities(id);
        
        return data;
    }catch (err) {
        console.error(err)
    }
};




export const addCommentActivity = (id,data1) => async (dispatch) => {
    dispatch({ type: "ACOMMENT_START"});
        try {
            const {data} = await ActivityApi.addCommentActivity(id,data1);
            dispatch({ type: "ACOMMENT_SUCCESS"});
        }catch (err) {
            dispatch({ type: "ACOMMENT_FAIL"});
            console.error(err)
        }
}


export const getActivity = (id) => async (dispatch) => {
    try {
        const {data} = await ActivityApi.getActivity(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const updateActivity = (id,FormData) => async (dispatch) => {
    dispatch({type: "AUPDATING_START"})
    try {
        const {data} = await ActivityApi.updateActivity(id, FormData);
        dispatch({type: "AUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "AUPDATING_FAIL"})
    }
}

export const deleteActivity = (id,FormData) => async (dispatch) => {
    dispatch({type: "ADELETE_START"})
    try {
        const {data} = await ActivityApi.deleteActivity(id,FormData);

        dispatch({type: "ADELETE_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "ADELETE_FAIL"})
    }
}