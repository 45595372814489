import axios from 'axios';

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {
    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const createChat = (data) => API.post('/chat/', data);

export const getUserChats = (id) => API.get('/chat/'+id);

export const findChat = (firstId,secondId) => API.get('/chat/find/'+firstId+'/'+secondId);

export const sendMessage = (data) => API.post('/message/',data);

export const getMessage = (id) => API.get('/message/'+id);

export const deleteChat = (id,data) => API.put('/chat/'+id+"/delete",data);