const authReducer = (state = {authData : null, ikData: null, loading: false, error: false, updateLoading: false},action) => {

    switch (action.type) {

        case "AUTH_START":
            return {...state, loading: true, error: false};
        
        case "AUTH_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({...action?.data}));
            return {...state,authData: action.data, loading:false, error: false};

        case "AUTH_FAIL":
            return {...state,loading: false, error: true};


            

        case "REGISTER_START":
            return { ...state, loading: true, error: false };

        case "REGISTER_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
            return { ...state, authData: action.data, loading: false, error: false };
            //return { ...state, loading: false, error: false };

        case "REGISTER_FAIL":
            return { ...state, loading: false, error: true };




        case "ADDING_START":
            return { ...state, loading: true, error: false };

        case "ADDING_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
            //return { ...state, authData: action.data, loading: false, error: false };
            return { ...state, loading: false, error: false };

        case "ADDING_FAIL":
            return { ...state, loading: false, error: true };




        case "BADDING_START":
            return { ...state, loading: true, error: false };

        case "BADDING_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({ ...action?.data }));
            //return { ...state, authData: action.data, loading: false, error: false };
            return { ...state, loading: false, error: false };

        case "BADDING_FAIL":
            return { ...state, loading: false, error: true };



            
        case "VERIFY_START":
            return { ...state, loading: true, error: false };

        case "VERIFY_SUCCESS":
            return { ...state, authData: action.data, loading: false, error: false };

        case "VERIFY_FAIL":
            return { ...state, loading: false, error: true };


        
        
        case "UPDATING_START":
            return {...state,updateLoading: true, error: false};

        case "UPDATING_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({...action?.data}));
            return {...state,authData: action.data, updateLoading: false, error: false}

        case "UPDATING_FAIL":
            return {...state,updateLoading: true, error: true}



        case "IK_UPDATING_START":
            return {...state,updateLoading: true, error: false};

        case "IK_UPDATING_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({...action?.data}));
            return {...state,ikData: action.data, updateLoading: false, error: false}

        case "IK_UPDATING_FAIL":
            return {...state,updateLoading: true, error: true}

        
        

        case "BUPDATING_START":
            return {...state,updateLoading: true, error: false};

        case "BUPDATING_SUCCESS":
            //localStorage.setItem("profile", JSON.stringify({...action?.data}));
            return {...state,authData: action.data, updateLoading: false, error: false}

        case "BUPDATING_FAIL":
            return {...state,updateLoading: true, error: true}


        
        
        case "LOG_OUT":
            localStorage.clear();
            return {...state, authData: null, loading: false, error: false, updateLoading: false }

        
        
        case "FOLLOW_USER":
            return {...state,authData:  {...state.authData, following: [ action.data, ...state.authData.following ] }}

        case "UNFOLLOW_USER":
            return {...state, authData: {...state.authData, following: [...state.authData.following.filter((personId) => personId !== action.data)] }}


        case "BFOLLOW_USER":
            return {...state,authData:  {...state.authData, following: [ action.data, ...state.authData.following ] }}

        case "BUNFOLLOW_USER":
            return {...state, authData: {...state.authData, following: [...state.authData.following.filter((personId) => personId !== action.data)] }}
    



        default:
            return state;
        
    }
};

export default authReducer;