import React, { useState,useEffect,useRef} from 'react'
import "./RegisterIKPage.css";
import { Logo,authPicture,AmazoiLogo,defaultProfileImage,PurplePlusButton,Eye,Person,IKLarge } from "../../components/standardImages/StandardImages";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { v4 } from "uuid";
import {uploadIKData, uploadImage} from "../../actions/UploadAction";

const RegisterIKPage = () => {
    const initialState = {
        fullname: "",
        email: "",
        coverLetter:"",
        birthday: "",
        university: "Hacettepe Üniversitesi /",
        mobile: "",
        hobbies: "",
        location: "",
        image: "",
        driverLicense: "",
        languages: "",
        experiences: "",
    };

    const user = useSelector((state) => state.authReducer.authData )

    const loading = useSelector((state) => state.authReducer.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState(initialState);
    const [profilePicture,setProfilePicture] = useState(null);
    const imageRef = useRef();
    const [page,setPage] = useState(true);
    const defaultPicture = defaultProfileImage;


    const resetForm = () => {
        setData(initialState);
    };

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const register = async () => {

            if (profilePicture){
                
                const fileName = "profilePic/images/"+ v4() + profilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
                data.uid = user._id;
                try {
                    dispatch(uploadImage(pictureData));

                    const imageUrl = await uploadImage(fileName);
                    imageUrl().then((url) =>{
                    data.profilePicture = url;
                    dispatch( uploadIKData(data, navigate))
                })
                
                }catch (err) {
                    console.error(err);
                }
            }else{
                data.uid = user._id;
                dispatch( uploadIKData(data, navigate))
            }
            //dispatch(verify(data, navigate))
            resetForm();
        };

        await register();
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setProfilePicture(img)
        }
    };


    return (
        <div className="Auth">
            <div id="upper-picture">
            <img src={authPicture} alt="" />
            </div>
            <div id="ik-picture">
                <img src={IKLarge} alt="" />
            </div>

            <div className="">
            </div>


            <div className="middle">
                <div className="register-upper-desc">
                <h6 className="register-description register-upper-desc">{"İş Arama (İK)"}</h6>
                    
                    
                </div>
                
                <div className="register-main-panel">
                    {page 
                        ? (<div className="register-page-one">
                            <form className="register-form" onSubmit={handleSubmit}>
                            <div style={{ "position" : "relative"}}>
                                <div className="settings-profile-image-container">
                                    <div className="settings-profile-image">
                                        <img src={ 
                                            profilePicture
                                            ? URL.createObjectURL(profilePicture)
                                            : defaultPicture 
                                        } alt="ProfileImage" /> 
                                    </div>
                                    <div style={{display: "none"}}>
                                        <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} required />
                                    </div>
                                </div>
                                <div className="settings-change-profile-image">
                                    <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                                </div>
                            </div>

                                <h6 className="register-description">Arkadaşlarınızın sizi daha iyi <br/> tanıması için resminizi ekleyin</h6>

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>İsim Soyisim</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="İsminiz Soyisminiz"
                                        name="fullname"
                                        value={data.fullname}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  


                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Üniversite / Bölüm</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="Hacettepe Üniversitesi / Bölümünüz"
                                        name="university"
                                        value={data.university}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  
                                
                                
                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Doğum Tarihi</p>
                                        <input required type="date" 
                                        min="1985-01-01"
                                        className="emailInput" 
                                        placeholder="GG/AA/YYYY"
                                        name="birthday"
                                        value={data.birthday}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>E-mail</p>
                                        <input required type="email" 
                                        className="emailInput" 
                                        placeholder="example@hacettepe.edu.tr"
                                        name="email"
                                        value={data.email}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Cep No</p>
                                        <input required type="tel" 
                                        className="emailInput" 
                                        placeholder="05XXXXXXXXX"
                                        name="mobile"
                                        value={data.mobile}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Yaşadığınız Şehir</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="Yaşadığınız Şehir"
                                        name="location"
                                        value={data.location}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  

                                <div className="register-login-btn">
                                    <button
                                        className="button infoButton"
                                        type="submit"
                                        onClick={() => setPage(false)} >
                                        Devam
                                    </button>
                                </div>
                            </form>

                            
                        </div>)
                        : (<div className="personal-info">
                            <form className="register-form" onSubmit={handleSubmit}>

                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Ön Yazı</p>
                                        <textarea required type="text" 
                                        className="register-textarea" 
                                        placeholder="Ön Yazınız"
                                        name="coverLetter"
                                        value={data.coverLetter}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  


                                <div className="register-input-area">
                                    <div className="input-container">
                                        <p>Geçmiş Tecrübeler</p>
                                        <textarea required type="text" 
                                        className="register-textarea" 
                                        placeholder="Geçmiş Tecrübeler"
                                        name="experiences"
                                        value={data.experiences}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  
                                
                                
                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Ehliyet</p>
                                        <input required type="text"
                                            className="passwordInput" 
                                            placeholder="Ehliyetiniz Varsa Yazınız"
                                            name="driverLicense"
                                            value={data.driverLicense}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Yabancı Dil</p>
                                        <input required type="text"
                                            className="passwordInput" 
                                            placeholder="Bildiğiniz Yabancı Diller"
                                            name="languages"
                                            value={data.languages}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-input-area">

                                    <div className="input-container">
                                        <p>Hobiler</p>
                                        <input required type="text"
                                            className="passwordInput" 
                                            placeholder="Hobileriniz"
                                            name="hobbies"
                                            value={data.hobbies}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-login-btn">
                                    <button
                                        className="button infoButton"
                                        type="submit"
                                        onClick={() => setPage(true)} >
                                        Geri
                                    </button>
                                        
                                    <button
                                        className="button infoButton"
                                        type="submit"
                                        disabled={loading}>
                                        {loading ? "Loading..." : "Giriş"}
                                    </button>
                                </div>
                            </form>
                        </div>)
                    }

                        
                        
                </div>
                <a href="https://www.amazoisoftware.com/">
                    <div className="logo">
                        <img src={AmazoiLogo} alt=""/>
                    </div>
                </a>
                    
                </div>


            <div className="">
            <span></span>
            </div>
        </div>
    )

}

export default RegisterIKPage