import React, {useEffect,useState} from 'react';
import moment from 'moment';

import {storage} from "../../firebase"
import { ref, getDownloadURL} from "firebase/storage"

import { PurplePlusButton } from '../standardImages/StandardImages';

import { useSelector, useDispatch } from 'react-redux';

const ActivityTinyCard = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
  return (
    <div className="ActivityTinyCard">
        <div className="activity-post-image-tn">
            {data.image ? (<img 
                src={data.image ? URL.createObjectURL(data.image) : ""}
                alt= "" 
            /> ) : ""}
            
            {data.image && (
                <div className="activity-tn-info">
                    <div className="activity-tn-inside">
                        <h4>{data.title}</h4>
                        <h6>{data.date}</h6>
                        <h6>{data.location}</h6>
                        <p>{data.description}</p>
                    </div>
                    <div className="activity-tn-plus">
                        <img src={PurplePlusButton} alt=""/>
                    </div>
                </div>
            )}
            
        </div>
    </div>
  )
}

export default ActivityTinyCard