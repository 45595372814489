import React, { useState,useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { followUser, unfollowUser } from '../../actions/UserAction';
import { followBusiness,unfollowBusiness } from '../../actions/BusinessAction';
import { createChat,findChat,getUserChats } from '../../actions/ChatActions';

import {Person} from '../standardImages/StandardImages';

import {defaultProfileImage,defaultProfileBusiness} from "../standardImages/StandardImages"

const User = ({person,id}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const chat = useSelector((state) => state.chatReducer.chat)

    const dispatch = useDispatch();

    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;

    

    const [following, setFollowing] = useState(
        user.following.includes(user._id)
    );

    const userFollowing = () => {
        following 
        ? dispatch(unfollowUser(person._id, user))
        : dispatch(followUser(person._id, user));
        setFollowing((prev) => !prev);
    };

    const businessFollowing = () => {
        following 
        ? dispatch(unfollowBusiness(person._id, user))
        : dispatch(followBusiness(person._id, user));
        setFollowing((prev) => !prev);
    };

    const handleFollow = () => {
        user.isBusiness 
        ? businessFollowing()
        : userFollowing()
    }

    const handleChat = async () => {

        const data = { 
            senderId : user._id,
            receiverId : person._id 
        }

        try{
            dispatch(findChat(user._id, person._id));
            
            if (chat === "no chat found"){
                
                dispatch(createChat(data));
            }
            
            dispatch(getUserChats(user._id));
        }catch(e){console.error(e);}
    }

    /* resim işini persona çek*/

    return (
        <div className="follower">
            <div className="user-info">
                <img src={
                    person.profilePicture
                ? person.profilePicture
                : defaultPicture
            } alt="profile" className="followerImage" />
                
                <div className="name">
                    <span>{person.firstname} {person.lastname}</span>
                    {/*<span>{person.worksAt}</span>*/}
                </div>
            </div>

            <img src={Person} alt="" className="user-logo"/>
            
            

        </div>
    );

    /*<button className={
                following ? "fc-button UnfollowButton" : "button fc-button"
            } onClick = {handleFollow}>
            {following ? "Unfollow" : "Follow"}
            </button>*/
};


export default User;