import axios from "axios";

import {getKey,getKeyUserId} from './PrivateKeyContainer';

axios.defaults.baseURL = 'https://api.campus101app.com/';

const API = axios.create({baseUrl:"https://api.campus101app.com/"});

API.interceptors.request.use((req) => {

    req.headers.key = getKey();
    req.headers.userId = getKeyUserId();
    return req;
});

export const getBusiness = (userId) => API.get('/business/'+userId);

export const updateBusiness = (id,FormData) => API.put('/business/'+id, FormData);

export const getAllBusiness = () => API.get('/business');

export const followBusiness = (id,Data) => API.put('/business/'+id+'/follow',Data);

export const unfollowBusiness = (id, Data) => API.put('/business/' + id + '/unfollow',Data);

export const addBusinessVisitor = (id,Data) => API.put('/business/'+id+'/visit',Data);

export const getBusinessDict = () => API.get('/business/dict');