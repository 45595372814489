import React, {useState,useEffect,useRef} from 'react';
import { db } from '../../firebase.js';
import { collection, query, where, onSnapshot,orderBy } from "firebase/firestore";
import "./Messages.css";
import Message from "../message/Message.jsx";
import { useSelector } from 'react-redux';
import ReactScrollableFeed from 'react-scrollable-feed';

const Messages = ({chatId}) => {
    const [messages,setMessages] = useState([]);
    const user = useSelector((state) => state.authReducer.authData);

    const messagesEndRef = useRef(null)

    useEffect(() => {
        const q = query(collection(db, "chats",chatId,"messages"),orderBy("timestamp","asc"));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        
        const temp = [];

        querySnapshot.forEach((doc) => {
            temp.push(doc.data());
        });
            setMessages(temp)
            messagesEndRef.current?.scrollIntoView({behavior: 'smooth'});
        });
        
    },[])

    return (
        <div className="Messages">

            {messages.map((message,id) => {
                return (
                        <div key={id} className={user._id === message.senderId 
                            ? "right" 
                            : "left" 
                        }>
                            <Message key={id} data={message}/> 
                    </div>
                )
            })}
            <div ref={messagesEndRef} />
            
        </div>
    )

}

    

export default Messages;