import React, {useRef,useState,useEffect} from 'react';
import "./EditActivity.css"
import {storage} from "../../firebase"
import { deleteActivity,updateActivity } from '../../actions/ActivityAction.js';
import moment from 'moment';
import * as StandardImages from '../standardImages/StandardImages'
import { useSelector, useDispatch } from 'react-redux';

import CheckModal from '../checkModal/CheckModal';


const EditActivity = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.activityReducer.loading);
    const dispatch = useDispatch();

    const [forum, setForum] = useState(data);
    const [modalOpened3,setModalOpened3] = useState(false);

    const handleChange = (e) => {
        setForum({...forum , [e.target.name]:e.target.value});
    };

    const handleDelete = () => {
        try{
            const imgRef = storage.refFromURL(data.mediaUrl);
            imgRef.delete();
        }catch(error){
        }
        const deleteData = {userId: user._id};
        dispatch(deleteActivity(data.activityId,deleteData))
      }

    const handleSubmit = (e) => {
        e.preventDefault();
        forum.userId = user._id;
        dispatch(updateActivity(data.activityId, forum))
        window.location.reload();
    }
    
    
    
    return (
        <div className="EditActivity">

            <div className="modal-activity-center">
                <div className="modal-activity-image-area">
                    {data.mediaUrl ? (<img 
                        src={data.mediaUrl ? data.mediaUrl : ""}
                        alt= "" 
                    /> ) : ""
                    }
                    

                    <div className="modal-activity-info">
                        <div className="modal-activity-info-container">
                            <input type="text" className="edit-input" value={forum.title} name="title" onChange={handleChange}/>
                            <div className="activity-info-description">
                                <h6>{moment(data.timestamp).fromNow()}</h6>
                                <input type="text" className="edit-input" value={forum.date} name="date" onChange={handleChange}/>
                                <input type="text" className="edit-input" value={forum.location} name="location" onChange={handleChange}/>
                                <textarea type="text" className="edit-textarea" value={forum.description} name="description" onChange={handleChange}/>
                            </div>
                        </div>
                        <div className="modal-activity-image-plus">
                            <div className="modal-activity-plus">
                                <h6>{""}</h6>
                                <h1 className="modal-activity-join-button">Etkinliğe Katıl</h1>
                                <img src={StandardImages.PurplePlusButton} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div className="modal-info-area">
                <div className="modal-detail">
                    <span>
                        <b className="modal-activity-username">{data.username} </b>
                    </span>
                </div>

            </div>

            <div className="edit-buttons">
                <button
                    className="button infoButton"
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                    >
                        {loading ? "Updating..." : "Update"}
                </button>

                <button className="button infoButton" onClick={() => setModalOpened3((prev) => !prev)}> 
                        Sil
                </button>
            </div>

            

            <CheckModal modalOpened={modalOpened3} setModalOpened={setModalOpened3} data={ handleDelete } />

        </div>
    )
}

export default EditActivity