import React, {useEffect,useRef} from 'react';
import "./ForumPosts.css";
import ForumPost from '../forumPost/ForumPost';
import {useSelector, useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import { getTimelineForums } from '../../actions/ForumAction';

import ViewportList from "react-viewport-list";

const ForumPosts = () => {
  const params =useParams();
  const dispatch =useDispatch();
  const user = useSelector((state) => state.authReducer.authData);
  const ref = useRef(null);
  
  let { forums,loading } = useSelector((state) => state.forumReducer);

  useEffect(() => {
    dispatch(getTimelineForums());
  },[])

  /*forums.map((forum,id) => {
        return <ForumPost key={id} data={forum} />
        }
      ) */

  

  if (!forums || forums.length === 0) return 'No Posts'


  return (
    <div className="ForumPosts" ref={ref}>
      {loading
      ? "Fetching Posts"
      : <ViewportList viewportRef={ref} items={forums} itemMinSize={10} margin={8}>
      {(forum,id) => {
        return <ForumPost key={id} data={forum} />
        }}
      </ViewportList>
    }

    </div>
  )
}

export default ForumPosts