import React from 'react'
import "./SponsoredAds.css"
import { Link} from "react-router-dom"
import url from "../../img/cover.jpg";

const SponsoredAds = () => {
  return (
    <div className="SponsoredAds">
        <a href="https://www.amazoisoftware.com/metaexpo">
            <img className="sponsored-ads" src={url} alt=""/>
            <h6> Text </h6>
        </a>
    </div>
  )
}

export default SponsoredAds