const activityReducer = (
    state = { activities: [], myActivities: [], loading: false, error: false, uploading: false, updateLoading: false },
    action) => {
        switch (action.type) {
            case 'AUPLOAD_START':
                return { ...state, error: false, uploading: true };
            case 'AUPLOAD_SUCCESS':
                return { ...state, activities: [action.data, ...state.activities], uploading: false, error: false };
            case 'AUPLOAD_FAIL':
                return { ...state, uploading: false, error: true }

            //belongs to activities.jsx
            case 'ARETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'ARETREIVING_SUCCESS':
                return { ...state, activities: action.data, loading: false, error: false };
            case 'ARETREIVING_FAIL':
                return { ...state, loading: false, error: true };


            case 'APRETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'APRETREIVING_SUCCESS':
                return { ...state, myActivities: action.data, loading: false, error: false };
            case 'APRETREIVING_FAIL':
                return { ...state, loading: false, error: true };




            case 'ADOWNLOAD_START':
                return { ...state, loading: true, error: false};
            case 'ADOWNLOAD_SUCCESS':
                return { ...state, activities: action.data, loading: false, error: false };
            case 'ADOWNLOAD_FAIL':
                return { ...state, loading: false, error: true }; 


            //belongs to activities.jsx
            case 'ACOMMENT_START':
                return { ...state, loading: true, error: false};
            case 'ACOMMENT_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'ACOMMENT_FAIL':
                return { ...state, loading: false, error: true };


            case "AUPDATING_START":
                return {...state,updateLoading: true, error: false};

            case "AUPDATING_SUCCESS":
                //localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return {...state, updateLoading: false, error: false}

            case "AUPDATING_FAIL":
                return {...state,updateLoading: true, error: true}

            case 'ADELETE_START':
                return { ...state, loading: true, error: false};
            case 'ADELETE_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'ADELETE_FAIL':
                return { ...state, loading: false, error: true };
                
            default:
                return state;
        }
    };

    
export default activityReducer;