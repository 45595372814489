import React, { useState,useEffect,useRef} from 'react'
import "./Settings.css";

import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,authPicture,PurplePlusButton} from "../standardImages/StandardImages"
import {useParams,Link} from "react-router-dom";
import {useSelector} from 'react-redux'

import {storage} from "../../firebase"
import { ref, getDownloadURL} from "firebase/storage"

import { getUser } from '../../actions/UserAction';
import { getBusiness } from '../../actions/BusinessAction';

import InfoCard from '../InfoCard/InfoCard';

import Setting from '../setting/Setting';
import BusinessInfoCard from '../businessInfoCard/BusinessInfoCard';


const Settings = () => {

    
    const [isOpen,setIsOpen] = useState(false);

    const params =useParams();
    const user = useSelector((state) => state.authReducer.authData);
    

        /*
        <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "ayar1",desc: "ayar atar arar"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                */

  return (
    <div className="Settings">
        { user.isBusiness ? <BusinessInfoCard /> : <InfoCard /> }

        <div className="settings-settings-area">
            <h5>Genel Ayarlar</h5>
            <ul className="settings-list">
                <li className="settings-list-item">
                    <Setting data={{title: "Uygulama Hakkında",desc: "Uygulama hakkında metin"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                <li className="settings-list-item">
                    <Setting data={{title: "Gizlilik Sözleşmesi",desc: "Gizlilik Sözleşmesi"}} isOpen={isOpen} setIsOpen={setIsOpen} />
                </li>
                
            </ul>
            <h5>Hesap Ayarları</h5>
        </div>
    </div>
  )
}

export default Settings