import React, { useState } from 'react';
import "./Auth.css";
import { Logo,authPicture,AmazoiLogo,Eye,Person,ClosedEye } from "../../components/standardImages/StandardImages";

import { logIn,signUp } from "../../actions/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

const Auth = () => {
    const initialState = {
        email: "",
        password: "",
    };

    const loading = useSelector((state) => state.authReducer.loading);
    const error = useSelector((state) => state.authReducer.error);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState(initialState);
    const [passwordVisibility,setPasswordVisibility] = useState(false);

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(logIn(data,navigate));
    }

    const handleVisibility = (e) => {
        e.preventDefault();
        setPasswordVisibility((prevState) => !prevState)
    }
    /*left: 0px;
		top: 0px;

        infoForm authForm

        padding: 20%; */

    return (
        <div className="Auth">
            <div id="upper-picture">
            <img src={authPicture} alt="" />
            </div>

            <div className="">
            </div>

                
            <div className="middle">
                <div className="">
                </div>
                
                <div className="main-panel">
                    <div className="logo-and-quote">
                        
                        <img className="Logo" src={Logo} alt="" />
                        
                        <span className="quote">
                        Öğrencilerin ortak adresi...
                        </span>
                    </div>
                    
                    
                        <div className="to-hr-page ">
                            <Link className="link" to="../business/auth">
                                <span className="hr-circle">
                                    <p className="hr-circle-p">IK</p>
                                </span>
                            </Link>
                        </div>
                    
                    

                    <div className="inputArea">
                        <form className="form" onSubmit={handleSubmit}>
                            <div className="input-area">
                                <div className="input-container">
                                    <p>E- Posta Adresi</p>
                                    <input required type="text" 
                                    className="emailInput" 
                                    placeholder="example@hacettepe.edu.tr"
                                    name="email"
                                    value={data.email}
                                    onChange={handleChange}
                                    />
                                </div>
                                <img className="svg-img" src={Person}/>
                            </div>    
                            <div className="input-area">
    
                                <div className="input-container">
                                    <p>Şifre</p>
                                    <input required type={passwordVisibility ? "text" : "password"}
                                        className="passwordInput" 
                                        placeholder="......"
                                        name="password"
                                        value={data.password}
                                        onChange={handleChange}
                                    />
                                </div>
                                <img className="svg-img" src={passwordVisibility ? ClosedEye : Eye} onClick={handleVisibility}/>
                            </div> 

                            <span 
                                style={{
                                    color : "red",
                                    fontSize: "12px",
                                    alignSelf: "flex-end",
                                    marginRight: "5px",
                                    display: error ? "block" : "none",
                                }}>
                                    *Eposta veya şifre yanlış
                                </span>

                            <div className="login-btn">
                                <button
                                    className="button infoButton"
                                    type="submit"
                                    disabled={loading}>
                                        {loading ? "Loading..." : "Giriş"}
                                </button>
                            </div>
                        </form>
                        <br/>
                        <div className="auth-links">
                        <Link className="link-font" to="../reset-password">Şifremi unuttum</Link>
                        <Link className="link-font" to="../register">Yeni kullanıcı</Link>
                        </div>
                    </div>

                        
                        
                    </div>
                    <a href="https://www.amazoisoftware.com/">
                        <div className="logo">
                            <img src={AmazoiLogo} alt=""/>
                        </div>
                    </a>
                    
                </div>


            <div className="">
            <span></span>
            </div>
        </div>
    )


    /*
    return (
        <div className="Auth">
            {/* left side *//*}
            <div className="a-left">
                <img src={Logo} alt="" />
                <div className="Webname">
                    <h1>geYik</h1>
                    <h6>Hacettepe'nin Sosyal Medyası</h6>
                </div>
            </div>


            {/* RightSide *//*}

            <div className="a-right">

                <form className="infoForm authForm" onSubmit={handleSubmit}>
                    <h3>{isSignUp ? "Register" : "Login"}</h3>
                    {isSignUp && (
                        <div>
                            <input 
                            required 
                            type="text"
                            placeholder="First Name"
                            className="infoInput"
                            name="firstname"
                            value={data.firstname}
                            onChange={handleChange}
                            />

                            <input 
                            required 
                            type="text"
                            placeholder="Last Name"
                            className="infoInput"
                            name="lastname"
                            value={data.lastname}
                            onChange={handleChange}
                            />
                        </div>
                    )}

                    <div>
                        <input
                            required
                            type="text"
                            placeholder="Email"
                            className="infoInput input123 emailInput"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <input
                            required
                            type="password"
                            placeholder="Password"
                            className="infoInput input123 passwordInput"
                            name="password"
                            value={data.password}
                            onChange={handleChange}
                        />
                        {isSignUp && (
                            <input
                                required
                                type="password"
                                placeholder="Confirm Password"
                                className="infoInput"
                                name="confirmPass"
                                value={data.confirmPass}
                                onChange={handleChange}
                            />
                        )}
                    </div>

                    <span 
                    style={{
                        color : "red",
                        fontSize: "12px",
                        alignSelf: "flex-end",
                        marginRight: "5px",
                        display: confirmPass ? "none" : "block",
                    }}>
                        *Confirm password is not same
                    </span>
                    <span 
                    style={{
                        color : "red",
                        fontSize: "12px",
                        alignSelf: "flex-end",
                        marginRight: "5px",
                        display: confirmEmail ? "none" : "block",
                    }}>
                        *Email must be a hacettepe email address
                    </span>
                    <div>
                        <span
                            style={{
                                fontSize: "12px",
                                cursor: "pointer",
                                textDecoration: "underline",
                            }}
                            onClick={() => {
                                resetForm();
                                setIsSignUp((prev) => !prev);
                            }}
                        >
                            {isSignUp 
                            ? "Already have an account Login" 
                            : "Don't have an account Sign Up"}
                        </span>
                        <button
                            className="button infoButton"
                            type="submit"
                            disabled={loading}>
                                {loading ? "Loading..." : isSignUp ? "SignUp" : "Login"}
                            </button>
                    </div>

                    <span>
                            <Link to="../business/auth">Is a business account?</Link>
                        </span>


                </form>
            </div>

        </div>
    );
    */
};

export default Auth;