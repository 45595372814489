import React, {useEffect,useState} from 'react';
import "./MarketplaceAds.css";

import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom"

const MarketplaceAds = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    

  return (
    <Link className="link link-center" style={{"width" : "100%", "height" : "100%"}} to={"../advertisement/"+ data.advertisementId}>
        <div className="MarketplaceAds">
            <div className="marketplace-ad-image">
                {data.mediaUrl ? (<img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                /> ) : ""}
                
            </div>

            <div className="marketplace-ad-info"> 
                <div className="marketplace-ad-title">
                    <h6 className="ad-desc">{data.description}</h6>
                    <h5 className="ad-name">{data.username}</h5>
                </div>
                <h6 className="marketplace-ad-price">{data.price}</h6>
            </div>
        </div>
    </Link>
  )
}

export default MarketplaceAds