import React, {useState, useEffect} from 'react';
import "./SinglePost.css"

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import { useParams } from 'react-router-dom'

import Post from '../../components/Post/Post';
import { getPost } from '../../actions/PostsAction';

const SinglePost = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const postId = params.id;
    const [post,setPost] = useState(null);

    const aFunc = async () => {
        const postData = await getPost(postId);
        postData().then((data) => {
            setPost(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[post === null])

  return (
    <div className="SinglePost">
        <div className="SinglePost-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="SinglePost-lower">
            <ProfileSide/>
            <div className="">
                {post === null ? "Post getiriliyor..." : (<Post data={post} />) }
            </div>
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default SinglePost