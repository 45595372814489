const cafeteriaReducer = (
    state = { menu: [], loading: false, error: false, uploading: false },
    action) => {
        switch (action.type) {
            case 'MENU_START':
                return { ...state, loading: true, error: false};

            case 'MENU_SUCCESS':
                return { ...state, menu: action.data, loading: false, error: false };
            
            case 'MENU_FAIL':
                return { ...state, loading: false, error: true };

            default:
                return state;
        };
    };

export default cafeteriaReducer;

                