import React, {useEffect, useState, useRef } from 'react';
import './FollowersCard.css';
import User from "../User/User";
import { useSelector } from "react-redux";

import  {SearchGlass} from '../standardImages/StandardImages'


import { getSecretUsers } from '../../api/UserRequests';
import { getAllBusiness } from '../../api/BusinessRequests';

import { Link } from 'react-router-dom';
/*
max-height: 100rem;

persons.map( ))
*/

/*
<ViewportList viewportRef={ref} items={persons} itemMinSize={25} margin={8}>

</ViewportList>

*/

const FollowersCard = ({location}) => {
  const user = useSelector((state) => state.authReducer.authData);
  const ref = useRef(null);
  const [input,setInput] = useState("");
  const [persons, setPersons] = useState([]);

  const onChange = (event) => {
    let temp = event.target.value
    setInput(temp.toLowerCase(),);
  }

  const fetchPersons = async () => {
    var data
    if(user.isBusiness){
      data = await getAllBusiness();
    }else{
      data = await getSecretUsers();
    }
    setPersons(data.data);
  };

  useEffect(() => {
    fetchPersons();
  }, [input === ""]);



  return (
    <div className="FollowersCard" ref={ref}>
      <h6>{ location !== "following" ? "Takipçilerim" : "Arkadaşlarım" }</h6>

      <div className="SearchBar">
            <div className="search-area">
                <input type="text" placeholder={ location !== "following" ? "Takipçilerini ara" : "Arkadaşlarını ara"} value={input} onChange={onChange} />
                <img className="search-logo" src={SearchGlass} alt="" />
            </div>
            
        </div>
        
      {
        location === "followers" && (
          <div className="FollowersCard-list" >
          {persons.filter((item) => {
            const searchItem = input //.toLowerCase();
            const firstname = item.firstname || " ";
            const lastname = item.lastname || " ";
            const username= item.username || " ";
            const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
    
            if(searchItem === ""){
              return true;
            }
    
            return (
              searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
            );
            }).map((person, id) => {
            if (user.followers.includes(person._id)) {
              return (
                <Link className="link" style={{"width" : "100%", "height" : "100%"}} to={"../profile/"+person._id}>
                  <User person={person} key={id} />
                </Link>
                )
            };
            })
          }</div>)
          
          }

      {
        location === "following" && (

          <div className="FollowersCard-list" >
          {
            persons.filter((item) => {
              const searchItem = input //.toLowerCase();
              const firstname = item.firstname || " ";
              const lastname = item.lastname || " ";
              const username= item.username || " ";
              const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
      
              if(searchItem === ""){
                return true;
              }
      
              return (
                searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
              );
              }).map((person, id) => {
            if (user.following.includes(person._id)) {
              return (
                <Link className="link" style={{"width" : "100%", "height" : "100%"}} to={"../profile/"+person._id}>
                  <User person={person} key={id} />
                </Link>
                )
            };
            })
          }
          </div>)
        }

    </div>
  )

  /*
  return (
    <div className="FollowersCard">
      <h3>People you may know</h3>
      
      {!location ? persons.slice(0,4).map((person, id) => {
        if (person._id !== user._id){
          return <User person={person} key={id} />
        };
      }) : persons.map((person, id) => {
        if (person._id !== user._id){
          return <User person={person} key={id} />
        };
      })
    }

      {!location ? (
        <span onClick={() => setModalOpened(true)}>Show More</span>
      ) : (
        ""
      )}
        
      <FollowersModal modalOpened={modalOpened} setModalOpened={setModalOpened} />
      
    </div>
  );*/


};

export default FollowersCard