import React, {useState} from 'react'
import "./BusinessDiscounts.css";
import BusinessRightSide from '../../../components/businessRightSide/BusinessRightSide';
import BusinessProfileSide from '../../../components/businessProfileSide/BusinessProfileSide';
import UpperPanel from '../../../components/upperPanel/UpperPanel'
import Discounts from '../../../components/discounts/Discounts';

const DiscountsPage = () => {
    const [white,setWhite] = useState(false);


    return (
        <div className="DiscountPage">
            <div className="Discount-upper">
                <UpperPanel white={white}/>
            </div>
            <div className="Discount-lower business-discounts">
                <BusinessProfileSide/>
                <Discounts />
                <BusinessRightSide white={white} setWhite={setWhite}/> 
            </div>

        </div>
    );
};

export default DiscountsPage;