import React, {useState} from 'react'
import "./UpperPanel.css";
import LogoSearch from '../logoSearch/LogoSearch'
import { Banner } from '../standardImages/StandardImages';
import { useSelector } from 'react-redux';
import {Link, useParams} from 'react-router-dom';

const UpperPanel = ({white}) => {
  const params = useParams();
  const user = useSelector((state) => state.authReducer.authData)
  const [persons,setPersons1] = useState([]);

  const refreshFunc = () => { 
    window.location.reload();
   }
   
  return (
    <div className="UpperPanel">
        <Link to={user.isBusiness ? "../business/home" : "../home"}>
          <img className="geyik-logo-banner" src={Banner} alt="" />
        </Link>
        
        <div className="UpperPanel-container">
            <div className="UpperPanel-welcome">
               <span className="welcome-box">Hoşgeldin {user.isBusiness 
                ? <b>{user.businessName.charAt(0).toUpperCase() + user.businessName.slice(1)}</b>
                : <b>{user.firstname.charAt(0).toUpperCase() + user.firstname.slice(1)}</b> }</span> 
            </div>
            <LogoSearch className="uppper-panel-searchbar" setPersons1={setPersons1}/>
        </div>

        <div className="white-background"> 
            <div className="normal-side" ></div>
            <div className={white ? "white-side" : "normal-side"} >
              <br/>
            </div>
        </div>
    </div>
  )
}

export default UpperPanel