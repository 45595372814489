import React, { useState,useEffect,useRef} from 'react'

import "./BusinessRegister.css";

import { AuthPictureBs,AmazoiLogoBs,defaultProfileImage,PurplePlusButton,EyeBs,ClosedEyeBs } from "../../../components/standardImages/StandardImages";

import { signUp } from "../../../actions/AuthAction.js";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import { v4 } from "uuid";

const BusinessRegister = () => {

    const initialState = {
        businessName: "",
        email: "",
        password: "",
        confirmPass: "",
        bio:"",
        location: "",
        image: "",
    };

    const loading = useSelector((state) => state.authReducer.loading);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [data,setData] = useState(initialState);
    const [confirmPass,setConfirmPass] = useState(true);
    const [confirmEmail,setConfirmEmail] = useState(true);
    const [passwordVisibility,setPasswordVisibility] = useState(false);
    const [profilePicture,setProfilePicture] = useState(null);
    const imageRef = useRef();
    const [page,setPage] = useState(true);
    const defaultPicture = defaultProfileImage;

    const resetForm = () => {
        setData(initialState);
        setConfirmPass(true);
        setConfirmEmail(true);
    };

    const handleChange = (e) => {
        setData({...data , [e.target.name]:e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const register = async () => {
            data.isBusiness = true;

            if (profilePicture){
                
                const fileName = "profilePic/images/"+ v4() + profilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
                try {
                    dispatch(signUp( data, pictureData, navigate ))
                    
                }catch (err) {
                    console.error(err);
                }
            }else{
                data.image = "";
                dispatch(signUp( data, null, navigate))
            }
            
            //dispatch(verify(data, navigate))
            resetForm();
        };

        await register();
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setProfilePicture(img)
        }
    };

    const handleVisibility = (e) => {
        e.preventDefault();
        setPasswordVisibility((prevState) => !prevState)
    }

    const handleNextPage = () => {
        setConfirmPass(true);
        setConfirmEmail(true);

        if(data.email.endsWith(".com") || data.email.endsWith(".com.tr")){
            data.password === data.confirmPass 
            ? setPage(false)
            : setConfirmPass(false);
        }else{
            setConfirmEmail(false);
        }

        
    }

    return (
        <div className="Auth">
            <div id="upper-picture">
            <img src={AuthPictureBs} alt="" />
            </div>

            <div className="">
            </div>


            <div className="middle">
                <div className="register-upper-desc">
                {page 
                    ? (<h6 className="business-register-description register-upper-desc">Profilinizi oluşturalım</h6>) 
                    : (<h6 className="business-register-description register-upper-desc">Müşterilerin hakkınızda daha çok bilgi <br/> edinebilmesi için biyografi ekleyin </h6>)
                }
                    
                </div>
                
                <div className="register-main-panel">
                    {page 
                        ? (<div className="register-page-one">
                            <form className="register-form" onSubmit={handleSubmit}>
                            <div style={{ "position" : "relative"}}>
                                <div className="settings-profile-image-container">
                                    <div className="settings-profile-image">
                                        <img src={ 
                                            profilePicture
                                            ? URL.createObjectURL(profilePicture)
                                            : defaultPicture 
                                        } alt="ProfileImage" /> 
                                    </div>
                                    <div style={{display: "none"}}>
                                        <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} required />
                                    </div>
                                </div>
                                <div className="settings-change-profile-image">
                                    <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                                </div>
                            </div>
                                

                                <h6 className="business-register-description register-profile-desc">Müşterilerinizin sizi daha iyi <br/> tanıması için resminizi ekleyin</h6>

                                <div className="business-register-input-area">
                                    <div className="business-input-container">
                                        <p>İşletme adınız</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="İşletme İsminiz"
                                        name="businessName"
                                        value={data.businessName}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>


                                <div className="business-register-input-area">
                                    <div className="business-input-container">
                                        <p>E- Posta Adresi</p>
                                        <input required type="text" 
                                        className="emailInput" 
                                        placeholder="example@emailprovider.com"
                                        name="email"
                                        value={data.email}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  
                                
                                
                                <div className="business-register-input-area">

                                    <div className="business-input-container">
                                        <p>Şifre</p>
                                        <input required type={passwordVisibility ? "text" : "password"}
                                            className="passwordInput" 
                                            placeholder="......"
                                            name="password"
                                            value={data.password}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <img className="svg-img" src={passwordVisibility ? ClosedEyeBs : EyeBs} onClick={handleVisibility}/>
                                </div> 


                                <div className="business-register-input-area">

                                    <div className="business-input-container">
                                        <p>Şifre (Tekrar)</p>
                                        <input required type={passwordVisibility ? "text" : "password"}
                                            className="passwordInput" 
                                            placeholder="......"
                                            name="confirmPass"
                                            value={data.confirmPass}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <img className="svg-img" src={passwordVisibility ? ClosedEyeBs : EyeBs} onClick={handleVisibility}/>
                                </div> 

                                <span 
                                style={{
                                    color : "red",
                                    fontSize: "12px",
                                    alignSelf: "flex-end",
                                    marginRight: "5px",
                                    display: confirmPass ? "none" : "block",
                                }}>
                                    *Confirm password is not same
                                </span>
                                <span 
                                style={{
                                    color : "red",
                                    fontSize: "12px",
                                    alignSelf: "flex-end",
                                    marginRight: "5px",
                                    display: confirmEmail ? "none" : "block",
                                }}>
                                    *Email must be a hacettepe email address
                                </span>


                                <div className="register-login-btn">
                                    <div
                                        className="businessButton infoButton"
                                        onClick={() => handleNextPage()} >
                                        Devam
                                    </div>
                                </div>

                                <div className="auth-links">
                                    <Link className="business-link-font" to="../business/auth">Giriş Yap</Link>
                                </div>
                            </form>

                            
                        </div>)
                        : (<div className="personal-info">
                            <form className="register-form" onSubmit={handleSubmit}>

                                <div className="business-register-input-area">
                                    <div className="business-input-container">
                                        <p>Biyografi</p>
                                        <textarea required type="text" 
                                        className="register-textarea" 
                                        placeholder="enter your biography"
                                        name="bio"
                                        value={data.bio}
                                        onChange={handleChange}
                                        />
                                    </div>
                                </div>  

                                <div className="business-register-input-area">

                                    <div className="business-input-container">
                                        <p>Konum</p>
                                        <input required type="text"
                                            className="passwordInput" 
                                            placeholder="Konumunuz"
                                            name="location"
                                            value={data.location}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div> 


                                <div className="register-login-btn">
                                    <div
                                        className="businessButton infoButton"
                                        onClick={() => setPage(true)} >
                                        Geri
                                    </div>
                                        
                                    {
                                    <button
                                        className="businessButton infoButton"
                                        type="submit"
                                        disabled={loading}>
                                        {loading ? "Loading..." : "Devam"}
                                    </button>
                                    }
                                </div>
                            </form>
                        </div>)
                    }

                        
                        
                </div>
                <a href="https://www.amazoisoftware.com/">
                    <div className="logo">
                        <img src={AmazoiLogoBs} alt=""/>
                    </div>
                </a>
                    
                </div>


            <div className="">
            <span></span>
            </div>
        </div>
  )
}

export default BusinessRegister