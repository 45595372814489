import * as UploadApi from "../api/UploadRequest.js";
import {storage} from "../firebase"
import { ref, uploadBytes, getDownloadURL,listAll,list} from "firebase/storage"
import { logout } from "./AuthAction.js";
//import { Experimental_CssVarsProvider } from "@mui/material";

export const uploadImage = (data,ex) => async (dispatch) => {
    try{
        try{
            const imgRef = storage.refFromURL(ex);
            imgRef.delete();
        }catch (error) {
            //
        }
        
        //await UploadApi.uploadImage(data);
        const imageRef = ref(storage, data.fileName);
        const url = await uploadBytes(imageRef, data.file).then((snapshot) => {
            const url = getDownloadURL(snapshot.ref).then((url) => {
                return url;
            })
            return url;
        })
        
        return url;
        
    }catch(err){
        console.error(err)
    }
};

export const uploadPost = (data) => async (dispatch) => {
    dispatch({ type:"UPLOAD_START"});
    try{
        const newPost = await UploadApi.uploadPost(data);
        
        dispatch({ type: "UPLOAD_SUCCESS",data: newPost.data});
    }catch(err){
        
        dispatch({ type: "UPLOAD_FAIL"});
        console.error(err)
    }
};


export const uploadForumPost = (data) => async (dispatch) => {
    dispatch({ type:"FUPLOAD_START"});
    try{
        const newPost = await UploadApi.uploadForumPost(data);
        
        dispatch({ type: "FUPLOAD_SUCCESS",data: newPost.data});
    }catch(err){
        
        dispatch({ type: "FUPLOAD_FAIL"});
        console.error(err)
    }
};

export const uploadDiscount = (data) => async (dispatch) => {
    dispatch({ type:"DUPLOAD_START"});
    try{
        const newPost = await UploadApi.uploadDiscount(data);
        
        dispatch({ type: "DUPLOAD_SUCCESS",data: newPost.data});
    }catch(err){
        
        dispatch({ type: "DUPLOAD_FAIL"});
        console.error(err)
    }
};

export const addActivity = (data) => async (dispatch) => {
    dispatch({ type:"AUPLOAD_START"});
    try{
        const newActivity = await UploadApi.addActivity(data);
        
        dispatch({ type: "AUPLOAD_SUCCESS",data: newActivity.data});
    }catch(err){
        
        dispatch({ type: "AUPLOAD_FAIL"});
        console.error(err)
    }
};

export const addAd = (data) => async (dispatch) => {
    dispatch({ type:"ADUPLOAD_START"});
    try{
        const newAd = await UploadApi.uploadAd(data);
        
        dispatch({ type: "ADUPLOAD_SUCCESS",data: newAd.data});
    }catch(err){
        
        dispatch({ type: "ADUPLOAD_FAIL"});
        console.error(err)
    }
};

export const uploadIKData = (formData, navigate) => async (dispatch) =>{
    dispatch({ type:"IKUPLOAD_START"});
    try{
        const {data} = await UploadApi.uploadIKData(formData,formData.uid);
        
        dispatch({ type: "IKUPLOAD_SUCCESS", data: data});

        navigate("../home", { replace: true });
    }catch(err){
        
        dispatch({ type: "IKUPLOAD_FAIL"});
        console.error(err)
    }
}

/*
export const DownloadPhoto = (data) => async (dispatch) => {
    //const url = ""
    dispatch({ type:"DOWNLOAD_START"});
    try{
        const url = getDownloadURL(ref(storage,data)).then((downloadUrl) => { return downloadUrl })
        dispatch({ type: "DOWNLOAD_SUCCESS", data: url});
    }catch(err){
        console.error(err)
        dispatch({ type: "DOWNLOAD_FAIL"});
    }
}*/