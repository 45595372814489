import React,{useState,useEffect} from 'react'
import './LogoSearch.css'
import  {SearchGlass,SearchMenu} from '../standardImages/StandardImages'
// @ts-ignore
import { getUsersDict,getSecretUsers } from '../../api/UserRequests';
import { getBusinessDict,getAllBusiness } from '../../api/BusinessRequests';
import { Link } from "react-router-dom";
import User from '../User/User'
import { useSelector } from 'react-redux';



const LogoSearch = ({setPersons1}) => {
  const [persons, setPersons] = useState([]);
  const [inputText, setInputText] = useState("");
  const [chosenUser, setChosenUser] = useState("");
  const user = useSelector((state) => state.authReducer.authData);

  const fetchPersons = async () => {
    var data
    if(user.isBusiness){
      data = await getAllBusiness();
    }else{
      data = await getSecretUsers();
    }
    setPersons(data.data);
  };

  useEffect(() => {
    fetchPersons();
  }, []);

  const onChange = (event) => {
    setInputText(event.target.value);
    setPersons1(persons.filter((item) => {
      const searchItem = inputText.toLowerCase();
      const firstname = item.firstname || " ";
      const lastname = item.lastname || " ";
      const username= item.username || " ";
      const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();

      return (
        searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
      );
    }))
  }

  const onSearch = (value) => {
    setInputText(value.firstname)
    setChosenUser(value._id);
  }

  /*<div>
          <Link to={user.isBusiness ? "../business/home" : "../home"}>
            <img className="geyik-logo" src={Logo} alt="" />
          </Link>
        </div>*/

  return (
    <div className="LogoSearch">
        
        <div className="search-up" >
          <div className="Search">
            <div className="search-and-logo">
              <div className="s-icon">
                <Link to={'../profile/' + chosenUser}>
                  <img className="search-logo" src={SearchGlass} alt="" />
                </Link>
              </div>
              <input type="text" placeholder="#Search" value={inputText} onChange={onChange}/>
            </div>
            <img className="search-logo" src={SearchMenu} alt="" />
          </div>

          <div className="dropdown">
                {persons.filter((item) => {
                  const searchItem = inputText.toLowerCase();
                  const firstname = item.firstname || " ";
                  const lastname = item.lastname || " ";
                  const username= item.username || " ";
                  const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
    
                  return (
                    searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
                  );
                }).slice(0,5).map((item,id) => {
                  return ( 
    
                      <div 
                      onClick={ () => onSearch(item)}
                      className="dropdown-row"
                      key={id}
                      > 
                        <Link to={"../profile/"+item._id} className="link">
                          <User person={item} key={id}/> 
                        </Link>
                      </div> 
                    )})  
              }
            </div> 
        
        </div>
    </div>
  )
}

export default LogoSearch