import React, {useState, useEffect} from 'react';
import "./SingleAdvertisement.css"

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import { useParams } from 'react-router-dom'

import MarketplacePost from '../../components/marketPlacePost/MarketplacePost';
import { getAdvertisement } from '../../actions/MarketplaceAction';

const SingleAdvertisement = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const advertisementId = params.id;
    const [advertisement,setAdvertisement] = useState(null);

    const aFunc = async () => {
        const advertisementData = await getAdvertisement(advertisementId);
        advertisementData().then((data) => {
            setAdvertisement(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[advertisement === null])

  return (
    <div className="SingleAdvertisement">
        <div className="SingleAdvertisement-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="SingleAdvertisement-lower">
            <ProfileSide/>
            <div className="">
                {advertisement === null ? "advertisement getiriliyor..." : (<MarketplacePost data={advertisement} />) }
            </div>
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default SingleAdvertisement