import React from 'react'
import "./VerifyEmail.css"
import { useSelector,useDispatch } from "react-redux";
import { logout, verify } from "../../actions/AuthAction";
import {  useNavigate } from "react-router-dom";
import { Logo,authPicture,AmazoiLogo,Eye,Person } from "../../components/standardImages/StandardImages";


const VerifyEmail = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleSubmit = () => {
        const data = {
          email: user.email
        }
        dispatch(verify(data,navigate));
        //dispatch(logout(navigate));
    }

    const handleLogout = () => {
      dispatch(logout(navigate));
    };


    return (
          <div className="VerifyEmail">
        <div id="upper-picture">
            <img src={authPicture} alt="" />
        </div>

        <div className=""> </div>

        <div className="reset-password-main-panel">
            <div className="">
                <h6 className="register-description register-upper-desc">Email Doğrulama</h6>
            </div>

            <div className="reset-password-info-area">
            <div className="verify-main-panel" >
              <h1>Email Hesabınız henüz Doğrulanmamış</h1>
              <button 
                  className="button infoButton"
                  type="submit" onClick={handleSubmit}> Doğrulama Mailini Gönder </button>

              <button className="button infoButton"
              type="submit" onClick={handleLogout} > Çıkış Yap </button>
            </div>
                
            </div>

            <a href="https://www.amazoisoftware.com/">
                <div className="logo">
                    <img src={AmazoiLogo} alt=""/>
                </div>
            </a>   
            
        </div>

        <div className=""> </div>

    </div>
      )
}

export default VerifyEmail