import React, {useState} from 'react'
import "./SettingsPage.css";

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import Settings from '../../components/settings/Settings';

const SettingsPage = () => {
  const [white,setWhite] = useState(false);

  return (
    <div className="SettingsPage">
        <div className="Settings-upper">
          <UpperPanel white={white}/>
        </div>
        <div className="Settings-lower">
            <ProfileSide/>
            <Settings />
            <RightSide white={white} setWhite={setWhite}/> 
        </div>

        <Chats/>
    </div>
  )
}

export default SettingsPage