import React, {useRef,useState,useEffect} from 'react';
import "./MarketplacePost.css";

import * as StandardImages from '../standardImages/StandardImages'

import moment from 'moment';

import { likeAd } from '../../api/MarketplaceRequests';
import { sendAdComment } from '../../actions/MarketplaceAction';
import { useSelector, useDispatch } from 'react-redux';
import Comment from '../comment/Comment'

import EditAdModal from '../editAdModal/EditAdModal';

import { Link } from "react-router-dom"

const MarketplacePost = ({data}) => {
    const commentRef = useRef('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const [liked,setLiked] = useState( data.likes.includes(user._id) ) //data.likes.includes(user._id));
    const [likes,setLikes] = useState( data.likes.length ) //data.likes.length)
    const [commentButton,setButtonPressed] = useState(false);
    const comments = data.comments;

    const [clicked,setClicked] = useState(false);

    const [modalOpened,setModalOpened] = useState(false);

    const handleLike = () => {
      likeAd(data.advertisementId,{ 
        ownerId:user._id,
        username: user.username,
        mediaUrl: user.profilePicture || ""
    });
      setLiked((prev) => !prev);
      liked ? setLikes((prev) => prev-1) : setLikes((prev) => prev + 1)
    };

    const handleCommentButton = () => {
        setButtonPressed((prev)=> !prev);
    }

    

    const handleComment = () => {
        setButtonPressed(false);

        const comment = {
            ownerId : user._id,
            username : user.username,
            description : commentRef.current.value,
            mediaUrl : user.profilePicture || ""
        }
        try {
            dispatch(sendAdComment(data.advertisementId,comment))
            comments.push(comment);
        }catch (err){
                console.error(err);
        } 
    }


    return (
      <div className="MarketplacePost">
      {data.mediaUrl ? (<div className={clicked ? "modal-discount-photo" : "marketplace-post-image-area"}>
          <span className="dated">
            {moment(data.timestamp).fromNow()}
          </span>
          <img 
              src={data.mediaUrl ? data.mediaUrl : ""}
              alt= "" 
              onClick={() => setClicked((prev) => !prev)}
          /> 
        </div>) : ""}

        <div className="marketplace-post-desc-area">
            <div className="marketplace-post-desc-area-inner">
                <Link className="link" to={"../profile/"+data.ownerId} >
                    <h2 className="marketplace-inner-h2" >{data.username}</h2>
                </Link>
            <h5>{data.description}</h5>
            </div>
          <span style={{ color: "var(--gray)",fontSize:"12px"}}>
                {likes === 0 ? "Bunu ilk beğenen sen ol" : (likes)+" likes" }
            </span>
        </div>

        <br/>

        <div className="postReact">
            <img 
                src={liked ? StandardImages.Heart : StandardImages.Dislike}
                alt=""
                style={{ cursor:"pointer"}}
                onClick={handleLike}
            />
            <img 
                src={StandardImages.Comment} 
                alt= "" 
                style={{ cursor:"pointer"}}
                onClick={handleCommentButton} 
            />
            <div className="post-edit-delete">
                    {user._id === data.ownerId ? (<>
                        <img className="shareBtn" src={StandardImages.Pencil} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened((prev) => !prev)} />
                    </>
                    ) : ""} 
                    <b> {data.price+" TL"} </b>
                </div>   
            
        </div>
        
        {commentButton 
            ? <div className="writeComment">
                <span className="comment-user-title" onClick={handleCommentButton} style={{cursor:"pointer"}}><b>{user.username} </b></span>
                <input ref={commentRef} className="TextArea"/>
                <button 
                    onClick={handleComment} 
                    className="button comment-submit"
                    type="submit"
                >send</button>
            </div> 
            : ""}

            { comments.length > 0 && (
                <div className="comments">
                    <div className="comments-inside">
                        { comments.map((comment,id) => {
                            return <Comment key={id} data={comment} />
                            })
                        }
                    </div>
                </div>
                )}

        <EditAdModal modalOpened={modalOpened} setModalOpened={setModalOpened} data={data} />

      </div>
    )
}

export default MarketplacePost