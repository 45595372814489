const postReducer = (
    state = { posts: [], loading: false, error: false, uploading: false, updateLoading: false },
    action) => {
        switch (action.type) {
            case 'UPLOAD_START':
                return { ...state, error: false, uploading: true };
            case 'UPLOAD_SUCCESS':
                return { ...state, posts: [action.data, ...state.posts], uploading: false, error: false };
            case 'UPLOAD_FAIL':
                return { ...state, uploading: false, error: true }

            //belongs to Posts.jsx
            case 'RETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'RETREIVING_SUCCESS':
                return { ...state, posts: action.data, loading: false, error: false };
            case 'RETREIVING_FAIL':
                return { ...state, loading: false, error: true };

            case 'DOWNLOAD_START':
                return { ...state, loading: true, error: false};
            case 'DOWNLOAD_SUCCESS':
                return { ...state, posts: action.data, loading: false, error: false };
            case 'DOWNLOAD_FAIL':
                return { ...state, loading: false, error: true }; 


            //belongs to Posts.jsx
            case 'COMMENT_START':
                return { ...state, loading: true, error: false};
            case 'COMMENT_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'COMMENT_FAIL':
                return { ...state, loading: false, error: true };

            case "PUPDATING_START":
                return {...state,updateLoading: true, error: false};

            case "PUPDATING_SUCCESS":
                //localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return {...state, updateLoading: false, error: false}

            case "PUPDATING_FAIL":
                return {...state,updateLoading: true, error: true}

            case 'PDELETE_START':
                return { ...state, loading: true, error: false};
            case 'PDELETE_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'PDELETE_FAIL':
                return { ...state, loading: false, error: true };
                
            default:
                return state;
        }
    };

    
export default postReducer;
