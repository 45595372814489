import React, {useState,useEffect} from 'react';
import { db } from '../../firebase.js';
import { collection, query, where, onSnapshot,orderBy,deleteDoc,doc } from "firebase/firestore";
import './Notifications.css';
import Notification from '../notification/Notification';
import { useSelector,useDispatch } from 'react-redux';
import { deleteNotification } from '../../actions/NotificationAction.js';

const Notifications = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const [notifications,setNotifications] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        
        const q = query( collection(db, "notifications",user._id,"notifications"), orderBy("timestamp","desc") ); //,orderBy("timestamp","asc")
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const temp = [];
        querySnapshot.forEach((doc) => {
            temp.push(doc.data());
        });
        setNotifications(temp)
        });
    },[])

    const handleCleanup = () => {
        
        try{
            const deleteNoti = async () => {
                dispatch(deleteNotification(user._id,{userId: user._id}))
            }
            deleteNoti(); 
        }catch (err){
            console.error(err);
        }
        
    }
    

  return (
    <div className="Notifications">
        <div className="Notifications-Info">
            <h6>Bildirimlerim</h6>
            <h6 onClick={handleCleanup}>Tümünü Temizle</h6>
        </div>
        <div className="NotificationsArea">
            {
                notifications && (notifications.slice(0,100).map((notification,id) => {
                    return <Notification className="noti" notification={notification} key={id} />
                }))
            }
        </div>
    </div>
  )
}

export default Notifications