import React, {useRef,useState,useEffect} from 'react';
import "./EditForum.css"
import {storage} from "../../firebase"
import { updateForum,deleteForum } from '../../actions/ForumAction'
import moment from 'moment';
import * as StandardImages from '../standardImages/StandardImages'
import { useSelector, useDispatch } from 'react-redux';
import CheckModal from '../checkModal/CheckModal';

const EditForum = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.forumReducer.loading);
    const dispatch = useDispatch();

    const [forum, setForum] = useState(data);
    const [modalOpened3,setModalOpened3] = useState(false);

    const handleChange = (e) => {
        setForum({...forum , [e.target.name]:e.target.value});
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        forum.userId = user._id;
        dispatch(updateForum(data.forumId, forum))
        window.location.reload();
    }
    
    const handleDelete = () => {
        try{
            const imgRef = storage.refFromURL(data.mediaUrl);
            imgRef.delete();
        }catch(error){
        }
        const deleteData = {userId: user._id};
        dispatch(deleteForum(data.forumId,deleteData))
      }
    
    
    return (
        <div className="EditForum">
            <div className="title-desc-photo">
                <div className="forum-post-text-area">
                    <input className="edit-input" type="text" value={forum.title} name="title" onChange={handleChange}/>
                    <input className="edit-input" type="text" value={forum.subTitle} name="subTitle" onChange={handleChange}/>
                    <textarea className="edit-textarea" type="text" value={forum.description} name="description" onChange={handleChange}/>
                </div>
                <div className="forum-post-photo-area">
                <span className="dated">
                    {moment(data.timestamp).fromNow()}
                </span>
                {data.mediaUrl ? (<img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                    /> ) : ""}
                </div>
            </div>

            <div className="info-area">
                <div className="detail">
                    <span>
                        <b className="forum-username">{data.username} </b>
                    </span>
                </div>

            </div>

            <div className="edit-buttons">
                <button
                    className="button infoButton"
                    type="submit"
                    disabled={loading}
                    onClick={handleSubmit}
                    >
                        {loading ? "Updating..." : "Update"}
                </button>

                <button className="button infoButton" onClick={() => setModalOpened3((prev) => !prev)}> 
                        Sil
                </button>
            </div>

            <CheckModal modalOpened={modalOpened3} setModalOpened={setModalOpened3} data={ handleDelete } />

        </div>
    )
}

export default EditForum;