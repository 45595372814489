const MarketplaceReducer = (
    state = { ads: [], loading: false, error: false, uploading: false, updateLoading: false },
    action) => {
        switch (action.type) {
            case 'ADUPLOAD_START':
                return { ...state, error: false, uploading: true };
            case 'ADUPLOAD_SUCCESS':
                return { ...state, ads: [action.data, ...state.ads], uploading: false, error: false };
            case 'ADUPLOAD_FAIL':
                return { ...state, uploading: false, error: true }

            //belongs to Posts.jsx
            case 'ADRETREIVING_START':
                return { ...state, loading: true, error: false};
            case 'ADRETREIVING_SUCCESS':
                return { ...state, ads: action.data, loading: false, error: false };
            case 'ADRETREIVING_FAIL':
                return { ...state, loading: false, error: true };

            case 'ADDOWNLOAD_START':
                return { ...state, loading: true, error: false};
            case 'ADDOWNLOAD_SUCCESS':
                return { ...state, ads: action.data, loading: false, error: false };
            case 'ADDOWNLOAD_FAIL':
                return { ...state, loading: false, error: true }; 


            //belongs to Posts.jsx
            case 'ADCOMMENT_START':
                return { ...state, loading: true, error: false};
            case 'ADCOMMENT_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'ADCOMMENT_FAIL':
                return { ...state, loading: false, error: true };


            case "ADUPDATING_START":
                return {...state,updateLoading: true, error: false};

            case "ADUPDATING_SUCCESS":
                //localStorage.setItem("profile", JSON.stringify({...action?.data}));
                return {...state, updateLoading: false, error: false}

            case "ADUPDATING_FAIL":
                return {...state,updateLoading: true, error: true}

            case 'ADDELETE_START':
                return { ...state, loading: true, error: false};
            case 'ADDELETE_SUCCESS':
                return { ...state, loading: false, error: false };
            case 'ADDELETE_FAIL':
                return { ...state, loading: false, error: true };

                
            default:
                return state;
        }
    };

    
export default MarketplaceReducer;