import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import { deleteUser, sendEmailVerification,createUserWithEmailAndPassword } from "firebase/auth";
import "firebase/compat/firestore"
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

//console.log(process.env.REACT_APP_FIREBASE_API_KEY)

const app = firebase.initializeApp({
    apiKey: "AIzaSyDJUCuDCe7kW3PO99guqblDzzRFF-nUd7I",
  authDomain: "geyik-dev.firebaseapp.com",
  projectId: "geyik-dev",
  storageBucket: "geyik-dev.appspot.com",
  messagingSenderId: "454570098205",
  appId: "1:454570098205:web:6910cc9f0b7f9e9cc9d0f4",
  measurementId: "G-LNEQHM5DL8"
});


// Initialize Firebase
//const analytics = getAnalytics(app);
export const auth = app.auth()
export const db = getFirestore(app);
export const deleteUserFirebase = deleteUser
export const emailVerify = sendEmailVerification 
export const createUser = createUserWithEmailAndPassword;
export const storage = getStorage(app);

export default app
