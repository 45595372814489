import React, { useState,useEffect,useRef} from 'react'
import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,authPicture,PurplePlusButton} from "../standardImages/StandardImages"
import {useParams,Link} from "react-router-dom";
import {useDispatch,useSelector} from "react-redux";
import {uploadImage} from "../../actions/UploadAction";
import { getIKData, updateUserIK, getUser,updateUser } from '../../actions/UserAction';
import { getBusiness,updateBusiness } from '../../actions/BusinessAction';

import { v4 } from "uuid";

import "./ProfileBio.css";

const ProfileBio = () => {
    const params =useParams();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ik = useSelector((state) => state.authReducer.ikData);
    
    const [isOpen,setIsOpen] = useState(false);
    const {password, ...other } = user;

    //const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
    const [person1,setPerson] = useState(user);
    const [profilePicture,setProfilePicture] = useState(null);
    const [ikProfilePicture,setIKProfilePicture] = useState(null);
    const profileUserId = params.id;
    const imageRef = useRef();
    const imageIKRef = useRef();
    const isUser = profileUserId === user._id;
    const disabled = "disabled";
    const enabled = !disabled;

    const [formData,setFormData] = useState(isUser ? other : person1);
    const [hrFormData,setHrFormData] = useState(ik || {
        image:null,
        fullname: "",
        university: "",
        birthday: "",
        coverLetter: "",
        driverLicense: "",
        email: "",
        experiences: "",
        hobbies: "",
        languages: "",
        location: "",
        mobile: "",
        _id: user._id
    });

    const fetchProfileUser = async () =>{
        if (!profileUserId || profileUserId === user._id){
            setPerson(user);
        }else{
            //console.log("fetching profile user...");
            //console.log(profileUserId)
            var ref

            if (user.isBusiness){
            ref = await getBusiness(profileUserId);
            }else{
            ref = await getUser(profileUserId);
            }
            
            ref().then((userData) => {
                setPerson(userData)
                setFormData(userData)
            })
            //console.log(person1)
        }
    }

    const getIK = async () => {
        var profileIK;
        profileIK = await getIKData(profileUserId)
        profileIK().then((userData) => setHrFormData(userData ? userData : {
            image:null,
            fullname: "",
            university: "",
            birthday: "",
            coverLetter: "",
            driverLicense: "",
            email: "",
            experiences: "",
            hobbies: "",
            languages: "",
            location: "",
            mobile: "",
            _id: user._id
        }))
        //profileIK.then((userData) => setHrFormData(userData))
    }

    useEffect(() => {
        try{
        fetchProfileUser();
        if (isUser) {getIK();}
        }catch(err){
            console.error(err)
        }
        },[]);

        const handleChange = (e) => {
            setFormData({...formData,[e.target.name]: e.target.value});
        };

        const handleChangeHr = (e) => {
            setHrFormData({...hrFormData,[e.target.name]: e.target.value});
        };

        const onImageChange = (event) => {
            if (event.target.files && event.target.files[0]){
                let img = event.target.files[0];
                setProfilePicture(img)
    
            }
        };

        const onIKImageChange = (event) => {
            if (event.target.files && event.target.files[0]){
                let img = event.target.files[0];
                setIKProfilePicture(img)
    
            }
        };
    
        const handleSubmit = async (event) => {
            event.preventDefault();
    
            let UserData = formData;
            if (profilePicture){
                
                const fileName = "profilePic/images/"+ v4() + profilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
    
                try {

                    const imageUrl = await uploadImage(pictureData,user.profilePicture);
                    imageUrl().then((url) =>{
                        UserData.profilePicture = url;

                    if(user.isBusiness){
                        dispatch(updateBusiness(params.id, UserData));
                    }else{
                        dispatch(updateUser(params.id, UserData));
                        dispatch(updateUserIK(params.id, hrFormData))
                    }
                })
                }catch (err) {
                    console.error(err);
                }
            } if (ikProfilePicture){
                const fileName = "profilePic/images/"+ v4() + ikProfilePicture.name;
                const pictureData = {
                    fileName : fileName,
                    file : profilePicture
                }
    
                try {
                    const imageUrl = await uploadImage(pictureData, ik?.image);
                    imageUrl().then((url) =>{
                        hrFormData.image = url;

                    if(user.isBusiness){
                        dispatch(updateBusiness(params.id, UserData));
                    }else{
                        dispatch(updateUser(params.id, UserData));
                        dispatch(updateUserIK(params.id, hrFormData))
                    }
                })
                }catch (err) {
                    console.error(err);
                }
            }
            
            else{
                if(user.isBusiness){
                    dispatch(updateBusiness(params.id, UserData));
                }else{
                    dispatch(updateUser(params.id, UserData));
                    dispatch(updateUserIK(params.id, hrFormData))
                }
            }
    
            
        };

    return (
        <form className="infoForm" style={{"padding-bottom":"1rem"}} onSubmit={handleSubmit}>
            <div className="ProfileBio">
                <div className="settings-profile-info"> 
                    <h6>Profil Detayları</h6>
                    <div style={{"position":"relative"}}> 
                        <div className="settings-profile-image-container">
                            <div className="settings-profile-image">
                                <img src={ 
                                    person1.profilePicture
                                    ? profilePicture ? URL.createObjectURL(profilePicture) : person1.profilePicture
                                    : profilePicture ? URL.createObjectURL(profilePicture) : defaultProfileImage 
                                } alt="ProfileImage" /> 
                            </div>
        
                            <div style={{display: "none"}}>
                                <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} disabled={isUser ? enabled : disabled } />
                            </div>
                        </div>
                        {isUser && ( 
                            <div className="settings-change-profile-image">
                                <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                            </div>
                        )}
                    </div>



                    <div className="settings-profile-info-container">
                        <div className="settings-profile-biography">
                            <h5>Biyografi</h5>
                            <textarea className="settings-profile-textarea" type="text" name="bio" value={formData.bio} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Doğum Günü</h5>
                            <input className="settings-profile-birthday" type="date" name="birthday" value={formData.birthday} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Üniversite</h5>
                            <input className="settings-profile-birthday" type="text" name="university" value={formData.university} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Bölüm</h5>
                            <input className="settings-profile-birthday" type="text" name="department" value={formData.department} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Hobiler</h5>
                            <textarea className="settings-profile-textarea" type="text" name="hobbies" value={formData.hobbies} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>

                        <div className="settings-profile-biography">
                            <h5>Konum</h5>
                            <input className="settings-profile-birthday" type="text" name="location" value={formData.location} onChange={handleChange} disabled={isUser ? enabled : disabled } />
                        </div>
                    </div>
                </div>

                {(isUser && hrFormData)&& (
                    <div className="settings-profile-hr">
                        <h6>İK Profil Detayları</h6>
                        <div style={{"position":"relative"}}> 
                            <div className="settings-profile-image-container">
                                <div className="settings-profile-image">
                                    <img src={ 
                                        hrFormData.image
                                        ? ikProfilePicture ? URL.createObjectURL(ikProfilePicture) : hrFormData.image
                                        : ikProfilePicture ? URL.createObjectURL(ikProfilePicture) : defaultProfileImage 
                                    } alt="ProfileImage" />
                                    
                                </div>
                                <div style={{display: "none"}}>
                                    <input type="file" name="profilePicture" ref={imageIKRef} onChange={onIKImageChange} disabled={isUser ? enabled : disabled } />
                                </div>
                            </div>
                            <div className="settings-change-profile-image">
                                <img src={PurplePlusButton} alt="" onClick={() => imageIKRef.current.click()} style={{"cursor":"pointer"}}/>
                            </div>
                        </div>
                            
                        <div className="settings-profile-info-container">
                            <div className="settings-profile-biography">
                                <h5>İsim Soyisim</h5>
                                <input className="settings-profile-birthday" type="text" name="fullname" value={hrFormData.fullname} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Üni / Bölüm</h5>
                                <input className="settings-profile-birthday" type="text" name="university" value={hrFormData.university} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Doğum Tarihi</h5>
                                <input className="settings-profile-birthday" type="date" name="birthday" value={hrFormData.birthday} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>E-Mail</h5>
                                <input className="settings-profile-birthday" type="text" name="email" value={hrFormData.email} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Cep No:</h5>
                                <input className="settings-profile-birthday" type="text" name="mobile" value={hrFormData.mobile} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Yaşadığınız Şehir</h5>
                                <input className="settings-profile-birthday" type="text" name="location" value={hrFormData.location} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Ön Yazı</h5>
                                <textarea className="settings-profile-textarea" type="text" name="coverLetter" value={hrFormData.coverLetter} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Geçmiş Tecrübe</h5>
                                <textarea className="settings-profile-textarea" type="text" name="experiences" value={hrFormData.experiences} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Ehliyet</h5>
                                <input className="settings-profile-birthday" type="text" name="driverLicense" value={hrFormData.driverLicense} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Yabancı Dil</h5>
                                <textarea className="settings-profile-textarea" type="text" name="languages" value={hrFormData.languages} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>

                            <div className="settings-profile-biography">
                                <h5>Hobiler</h5>
                                <textarea className="settings-profile-textarea" type="text" name="hobbies" value={hrFormData.hobbies} onChange={handleChangeHr} disabled={isUser ? enabled : disabled } />
                            </div>
                        </div>
                    </div>
                )}

                
            </div>

            { isUser 
                ? (<button className="button infoButton" type="submit">
                Update
            </button>)
            : ""}
            
        </form>
        
    )
}

export default ProfileBio