import React, {useEffect,useRef} from 'react';
import "./Activities.css";

import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";

import Activity from '../activity/Activity';
import { getAllActivities } from '../../actions/ActivityAction';

import ViewportList from "react-viewport-list";

const Activities = () => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);


    //let { posts,loading } = useSelector((state) => state.postReducer);
    let { activities,loading } = useSelector((state) => state.activityReducer);

    /*
    const loading = false;
    const activities = [{
        userId: 'asdsadsadsadsdasdad',
        username: 'John Doe',
        desc: 'Mangala gidiyoeuz',
        title: "Mangal",
        createdAt: Date(),
        image: "https://cdn.ototeknikveri.com/Files/gallery/Big/2048-150202_Fiat_Nuovo-Doblo_11.jpg"
    },{
        userId: 'asdsadsadsadsdasdad',
        username: 'John Doe',
        desc: 'Mangala gidiyoeuz',
        title: "Mangal",
        createdAt: Date(),
        image: "https://cdn.ototeknikveri.com/Files/gallery/Big/2048-150202_Fiat_Nuovo-Doblo_11.jpg"
    },{
        userId: 'asdsadsadsadsdasdad',
        username: 'John Doe',
        desc: 'Mangala gidiyoeuz',
        title: "Mangal",
        createdAt: Date(),
        image: "https://cdn.ototeknikveri.com/Files/gallery/Big/2048-150202_Fiat_Nuovo-Doblo_11.jpg"
    },{
        userId: 'asdsadsadsadsdasdad',
        username: 'John Doe',
        desc: 'Mangala gidiyoeuz',
        title: "Mangal",
        createdAt: Date(),
        image: "https://cdn.ototeknikveri.com/Files/gallery/Big/2048-150202_Fiat_Nuovo-Doblo_11.jpg"
    }];
    
    activities.map((post,id) => {
                    return <Activity data={post} key={id} />
                }
                )
    */

    useEffect(() => {
        dispatch(getAllActivities());
    },[])

    if (!activities || activities.length === 0) return 'No Activities';

    return (
        <div className="Activities" ref={ref}>
            {loading 
                ? "Fetching posts...."
                : <ViewportList viewportRef={ref} items={activities} itemMinSize={10} margin={8}>
                    {(post,id) => {
                        return <Activity data={post} key={id} />
                    }}
                </ViewportList>
                
                
                
            
            }
        </div>
    )
}

export default Activities