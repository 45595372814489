import React, {useRef,useState,useEffect} from 'react'
import "./ForumPost.css"
import * as StandardImages from '../standardImages/StandardImages'
/*import CommentLogo from '../standardImages/StandardImages';
import Share from '../standardImages/StandardImages';
import Heart from '../standardImages/StandardImages';
import Dislike from '../standardImages/StandardImages'*/
import { useSelector, useDispatch } from 'react-redux';
import Comment from '../comment/Comment'

import { sendForumComment } from '../../actions/ForumAction'
import { likeForumPost } from '../../api/ForumRequests';
import moment from 'moment';

import ShareModal from '../shareModal/ShareModal';
import EditForumModal from '../editForumModal/EditForumModal';


import { Link } from "react-router-dom"

const ForumPost = ({data}) => {
    const commentRef = useRef('');
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);

    const [liked,setLiked] = useState(data.likes.includes(user._id));
    const [likes,setLikes] = useState(data.likes.length)
    const [commentButton,setButtonPressed] = useState(false);
    const comments = data.comments

    const [modalOpened1,setModalOpened1] = useState(false);
    const [modalOpened2,setModalOpened2] = useState(false);
    

    const handleLike = () => {
        likeForumPost(data.forumId,{
            ownerId : user._id,
            mediaUrl : user.profilePicture || "",
            username : user.username,
        });
        setLiked((prev) => !prev);
        liked ? setLikes((prev) => prev-1) : setLikes((prev) => prev + 1)
    };

    const handleCommentButton = () => {
        setButtonPressed(true);
    }

    

    const handleComment = () => {
        setButtonPressed(false);

        const comment = {
            ownerId : user._id,
            username : user.username,
            description : commentRef.current.value,
            mediaUrl : user.profilePicture || ""
        }
        try {
            dispatch(sendForumComment(data.forumId,comment))
            comments.push(comment);
        }catch (err){
                console.error(err);
        }
        
    }

    return (
        <div className="ForumPost">

            <div className="title-desc-photo">
                <div className="forum-post-text-area">
                    <h1>{data.title}</h1>
                    <h4>{data.subTitle}</h4>
                    <p>{data.description}</p>
                </div>
                {data.mediaUrl ? ( <div className="forum-post-photo-area">
                <span className="dated">
                    {moment(data.timestamp).fromNow()}
                </span>
                <img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                    /> 
                </div> ) : ""}
            </div>

            <div className="info-area">
                <div className="detail">
                    <span>
                    <Link className="link" to={"../profile/"+data.ownerId} >
                    <b className="username">{data.username} </b>
                </Link>
                    </span>
                </div>
                <span style={{ color: "var(--gray)",fontSize:"12px"}}>
                    {likes === 0 ? "Bunu ilk beğenen sen ol" : (likes)+" likes" }
                </span>

            </div>

            <div className="postReact">
            <img 
                src={liked ? StandardImages.Heart : StandardImages.Dislike}
                alt=""
                style={{ cursor:"pointer"}}
                onClick={handleLike}
            />
            <img 
                src={StandardImages.Comment} 
                alt= "" 
                style={{ cursor:"pointer"}}
                onClick={handleCommentButton} 
            />
            <div className="post-edit-delete">
                {user._id === data.ownerId ? (<>
                    <img className="shareBtn" src={StandardImages.Pencil} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened2((prev) => !prev)} />
                </>
                ) : ""} 
                <img className="shareBtn" src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} onClick={() => setModalOpened1((prev) => !prev)} />
            </div>   
        </div>
        
        {commentButton 
            ? <div className="writeComment">
                <span className="comment-user-title" onClick={handleCommentButton} style={{cursor:"pointer"}}><b>{user.username} </b></span>
                <input ref={commentRef} className="TextArea"/>
                <button 
                    onClick={handleComment} 
                    className="button comment-submit"
                    type="submit"
                >send</button>
            </div> 
            : ""}

            { comments.length > 0 && (
                <div className="comments">
                    <div className="comments-inside">
                        { comments.map((comment,id) => {
                            return <Comment key={id} data={comment} />
                            })
                        }
                    </div>
                </div>
                )}


            <ShareModal modalOpened={modalOpened1} setModalOpened={setModalOpened1} data={ "https://www.geyikapp.com:3000/forum/"+ data.forumId } />
        <EditForumModal modalOpened={modalOpened2} setModalOpened={setModalOpened2} data={data} />

        </div>
    );

  /*return (
    <div className='ForumPost'>
            <img 
            src={data.image ? image : ""}
            alt= "" 
        />

        <div className="postReact">
            <img 
                src={liked ? StandardImages.Heart : StandardImages.Dislike}
                alt=""
                style={{ cursor:"pointer"}}
                onClick={handleLike}
            />
            <img 
                src={StandardImages.Comment} 
                alt= "" 
                style={{ cursor:"pointer"}}
                onClick={handleCommentButton} 
            />
            <img src={StandardImages.Share} alt="" style={{ cursor:"pointer"}} />
        </div>

        <span style={{ color: "var(--gray)",fontSize:"12px"}}>
            {likes} likes
        </span>
        <div className="detail">
            <span>
                <b>{data.name} </b>
            </span>
            <span>{data.desc}</span>
        </div>

        {commentButton 
            ? <div className="writeComment">
                <b>{user.firstname} </b>
                <input ref={commentRef} className="infoInput"/>
                <button 
                    onClick={handleComment} 
                    className="button infoButton"
                    type="submit"
                >send</button>
            </div> 
            : ""}

        {
            <div className="comments">
            { comments.map((comment,id) => {
                return <Comment key={id} data={comment} />
                })
            }
            </div>
        }
        <div className="dated" style={{ color: "var(--gray)",fontSize:"12px"}}>
        {moment(data.timestamp).fromNow()}
        </div>
    </div>
  )*/
  
};

export default ForumPost