import React, {useState, useEffect} from 'react';
import "./SingleForum.css"

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import { useParams } from 'react-router-dom'

import ForumPost from '../../components/forumPost/ForumPost';
import { getForum } from '../../actions/ForumAction';

const SingleForum = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const forumId = params.id;
    const [forum,setForum] = useState(null);

    const aFunc = async () => {
        const forumData = await getForum(forumId);
        forumData().then((data) => {
            setForum(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[forum === null])

  return (
    <div className="SingleForum">
        <div className="SingleForum-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="SingleForum-lower">
            <ProfileSide/>
            <div className="">
                {forum === null ? "forum getiriliyor..." : (<ForumPost data={forum} />) }
            </div>
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default SingleForum