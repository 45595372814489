import React, { useState,useEffect,useRef} from 'react'
import "./InfoCard.css";
import { UilPen } from '@iconscout/react-unicons';
import ProfileModal from '../ProfileModal/ProfileModal';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { logout } from '../../actions/AuthAction';
import { getUser,updateUser } from '../../actions/UserAction';
import { getBusiness,updateBusiness }  from '../../actions/BusinessAction';
import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,authPicture,PurplePlusButton} from "../standardImages/StandardImages"

import {uploadImage} from "../../actions/UploadAction";
import { v4 } from "uuid";

const InfoCard = ({location}) => {

    const params =useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.authReducer.authData);
    const posts = useSelector((state) => state.postReducer.posts);
    const discounts = useSelector((state) => state.discountReducer.discounts);
    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;

    
    const [profilePicture,setProfilePicture] = useState(null);
    const imageRef = useRef();
    const disabled = "disabled";
    const enabled = !disabled;


    //const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;
    const [person1,setPerson] = useState(user);
    const profileUserId = params.id;
    const isUser = profileUserId === user._id;

    const fetchProfileUser = async () =>{
        if (!profileUserId || profileUserId === user._id){
            setPerson(user);
        }else{
            //console.log("fetching profile user...");
            //console.log(profileUserId)
            var ref
            if (user.isBusiness){
            ref = await getBusiness(profileUserId);
            }else{
            ref = await getUser(profileUserId);
            }
            
            ref().then((userData) => setPerson(userData))
            //console.log(person1)
        }
    }

    useEffect(() => {
        try{
        fetchProfileUser();
        }catch(err){
            console.error(err)
        }
        },[user]);
    //console.log(profileUser)


    const handleLogOut = () => {
        dispatch(logout(navigate))
    }

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setProfilePicture(img)

        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        let UserData = {};
        if (profilePicture){
            
            const fileName = "profilePic/images/"+ v4() + profilePicture.name;
            const pictureData = {
                fileName : fileName,
                file : profilePicture
            }

            try {
                const imageUrl = await uploadImage(pictureData, user.profilePicture);
                imageUrl().then((url) =>{
                    UserData.profilePicture = url;

                if(user.isBusiness){
                    dispatch(updateBusiness(params.id, UserData));
                }else{
                    dispatch(updateUser(params.id, UserData));
                }
            })
            }catch (err) {
                console.error(err);
            }
        }
        else{
            if(user.isBusiness){
                dispatch(updateBusiness(params.id, UserData));
            }else{
                dispatch(updateUser(params.id, UserData));
            }
        }

        
    };


  return (
    <div className="InfoCard" >
        <div className="settings-profile-info"> 
            <h6>Profil Resmi</h6>

            <div style={{"position":"relative"}}> 
                        <div className="settings-profile-image-container">
                            <div className="settings-profile-image">
                                <img src={ 
                                    person1.profilePicture
                                    ? profilePicture ? URL.createObjectURL(profilePicture) : person1.profilePicture
                                    : profilePicture ? URL.createObjectURL(profilePicture) : defaultPicture 
                                } alt="ProfileImage" /> 
                            </div>
        
                            <div style={{display: "none"}}>
                                <input type="file" name="profilePicture" ref={imageRef} onChange={onImageChange} disabled={isUser ? enabled : disabled } />
                            </div>
                        </div>
                        {isUser && ( 
                            <div className="settings-change-profile-image">
                                <img src={PurplePlusButton} alt="" onClick={() => imageRef.current.click()} style={{"cursor":"pointer"}}/>
                            </div>
                        )}
                    </div>

                    { isUser 
                        ? (<button className="button logout-button" onClick={handleSubmit} type="submit">
                        Update
                    </button>)
                    : ""}

            <h6>Hakkında</h6>
            <div className="settings-profile-info-container">
                <div className="settings-profile-biography">
                    <h5>Biyografi</h5>
                    <p className="settings-profile-textarea">{user.bio}</p>
                </div>

                <div className="settings-profile-biography">
                    <h5>Doğum Günü</h5>
                    <p className="settings-profile-birthday">{user.birthday}</p>
                </div>

                <div className="settings-profile-biography">
                    <h5>Üniversite</h5>
                    <p className="settings-profile-birthday">{user.university}</p>
                </div>

                <div className="settings-profile-biography">
                    <h5>Bölüm</h5>
                    <p className="settings-profile-birthday">{user.department}</p>
                </div>

                <div className="settings-profile-biography">
                    <h5>Hobiler</h5>
                    <p className="settings-profile-textarea">{user.hobbies}</p>
                </div>

                <div className="settings-profile-biography">
                    <h5>Konum</h5>
                    <p className="settings-profile-birthday">{user.location}</p>
                </div>
            </div>
        </div>

          <button className="button logout-button" onClick={handleLogOut}>Log Out</button>
    </div>
  )
}

export default InfoCard