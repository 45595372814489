import React, {useEffect,useState} from 'react';
import "./ProfileActivityCard.css";

import moment from 'moment';

import {storage} from "../../firebase"
import { ref, getDownloadURL} from "firebase/storage"

import { PurplePlusButton } from '../standardImages/StandardImages';

import { useSelector, useDispatch } from 'react-redux';
import ActivityModal from '../activityModal/ActivityModal';

const ProfileActivityCard = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const [modalOpened,setModalOpened] = useState(false);

    
    return (
        <div className="ProfileActivityCard"> 
            <div className="activity-profile-closed" onClick={() => setModalOpened((prev) => !prev)}>
                {data.mediaUrl ? (<img 
                    src={data.mediaUrl ? data.mediaUrl : ""}
                    alt= "" 
                /> ) : ""}
            </div>
            <ActivityModal modalOpened={modalOpened} setModalOpened={setModalOpened } data={data} />
        </div>
    )

    /*
  return (
    <div className="ProfileActivityCard"> 
        { modalOpened 
            ? <div className="ProfileActivityCard-detail">
                <div className="activity-profile-tn-post">
                    {data.image ? (<img 
                        src={data.image ? data.image : ""}
                        alt= "" 
                    /> ) : ""}
                
                    <div className="activity-profile-tn-info">
                        <div className="activity-profile-info-inside">
                            <h4>{data.title}</h4>
                            <h6>{moment(data.createdAt).fromNow()}</h6>
                            <p>{data.desc}</p>
                        </div>
                        <div className="activity-profile-tn-plus">
                            <img src={PurplePlusButton} alt=""/>
                        </div>
                    </div>
                </div> 
            </div>

            : <div className="activity-profile-closed" onClick={() => setModalOpened((prev) => !prev)}>
                {data.image ? (<img 
                    src={data.image ? data.image : ""}
                    alt= "" 
                /> ) : ""}
            </div>
        }
        <ActivityModal modalOpened={modalOpened} setModalOpened={setModalOpened } data={data} />
    </div>
  )*/

};

export default ProfileActivityCard