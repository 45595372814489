import React,{useEffect, useState} from 'react';
import "./Menu.css";
import { geTheMenu } from '../../actions/CafeteriaAction'
import {useDispatch, useSelector} from 'react-redux';
import MenuContent from '../menuContent/MenuContent';

const Menu = () => {
    const dispatch = useDispatch();

    const {menu,loading} = useSelector((state) => state.cafeteriaReducer);
    const [menuAvailable, setMenuAvailable] = useState(true);

    useEffect(() => {
      dispatch(geTheMenu());
    },[menuAvailable])

  return (
    <div className="Menu">
    {
        loading 
        ? "Menu Yükleniyor...."
        : menu.map((menu,id) => {
            return <MenuContent menu={menu} key={id} />
        })
  }
    </div>
  )
}

export default Menu