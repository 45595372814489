import React, { useState } from 'react'
import "./CreateNavbar.css"

import MenuItem from "../menuItem/MenuItem";
import { CreatePost, ChatsPng, CreateActivity, CreateForum, CreateAdvertisement } from '../standardImages/StandardImages';

const CreateNavbar = ({choosePopUp}) => {
  return (
    <div className="CreateNavbar">
        <ul className="s-navbar-menu">
            <li className="s-nb-menu-item" onClick={() => choosePopUp(1)}>
                <MenuItem navLogo={CreatePost} navName="Gönderi Paylaş" />
            </li>
            <li className="s-nb-menu-item" onClick={() =>  choosePopUp(2)}>
              <MenuItem navLogo={ChatsPng} navName="Mesajlar" />
            </li>
            <li className="s-nb-menu-item" onClick={() => choosePopUp(3)}>
              <MenuItem navLogo={CreateActivity} navName="Etkinlik Oluştur"  />
            </li>
            <li className="s-nb-menu-item" onClick={() => choosePopUp(4)}>
              <MenuItem navLogo={CreateForum} navName="Forum Başlat" />
            </li>
            <li className="s-nb-menu-item" onClick={() => choosePopUp(5)}>
              <MenuItem navLogo={CreateAdvertisement} navName="İlan Oluştur" />
            </li> 
        </ul>
    </div>
  )
}

export default CreateNavbar