import React, {useEffect, useState, useRef } from 'react';
import "./SearchBar.css";
import { useSelector } from 'react-redux';
import User from "../User/User";
import { getSecretUsers } from '../../api/UserRequests';
import { getAllBusiness } from '../../api/BusinessRequests';
import { useParams } from 'react-router-dom';
import { getUser } from '../../actions/UserAction';
import  {SearchGlass} from '../standardImages/StandardImages'

import { Link } from 'react-router-dom';

const SearchBar = ({location}) => {
  const user = useSelector((state) => state.authReducer.authData);
  const ref = useRef(null);
  const params = useParams();
  const [input,setInput] = useState(" ");
  const [persons, setPersons] = useState([]);
  const [person,setPerson] = useState(null);
  const guest = params.id;

  const onChange = (event) => {
    let temp = event.target.value
    setInput(temp.toLowerCase(),);
  }

  const fetchPersons = async () => {
    var data
    if(user.isBusiness){
      data = await getAllBusiness();
    }else{
      data = await getSecretUsers();
    }
    setPersons(data.data);
  };

  const getGuest = async () => {
    var ref
    ref = await getUser(guest);
    ref().then((userData) => setPerson(userData))
  }

  useEffect(() => {
    getGuest();
    fetchPersons();
  }, [input === "",person === null]);

  return (
    <div className="FollowersCard" ref={ref}>
      <h6>{ location !== "following" ? "Takipçilerim" : "Arkadaşlarım" }</h6>

      <div className="SearchBar">
            <div className="search-area">
                <input type="text" placeholder={ location !== "following" ? "Takipçilerini ara" : "Arkadaşlarını ara"} value={input} onChange={onChange} />
                <img className="search-logo" src={SearchGlass} alt="" />
            </div>
        </div>
        
      {
        location === "followers" && (
          <div className="FollowersCard-list" >
          {persons.filter((item) => {
            const searchItem = input //.toLowerCase();
            const firstname = item.firstname || " ";
            const lastname = item.lastname || " ";
            const username= item.username || " ";
            const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
    
            if(searchItem === ""){
              return true;
            }
    
            return (
              searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
            );
            }).map((person12, id) => {
            if (person.followers.includes(person12._id)) {
              return (
                <Link className="link" style={{"width" : "100%", "height" : "100%"}} to={"../profile/"+person12._id}>
                  <User person={person12} key={id} />
                </Link>
              )
            };
            })
          }</div>)
          
          }

      {
        location === "following" && (

          <div className="FollowersCard-list" >
          {
            persons.filter((item) => {
              const searchItem = input //.toLowerCase();
              const firstname = item.firstname || " ";
              const lastname = item.lastname || " ";
              const username= item.username || " ";
              const fullName = firstname.toLowerCase() + " " + lastname.toLowerCase() + " " + username.toLowerCase();
      
              if(searchItem === ""){
                return true;
              }
      
              return (
                searchItem && fullName.search(searchItem) !== -1 && fullName !== searchItem && item._id !== user._id
              );
              }).map((person12, id) => {
            if (person.following.includes(person12._id)) {
              return (
                <Link className="link" style={{"width" : "100%", "height" : "100%"}} to={"../profile/"+person12._id}>
                  <User person={person12} key={id} />
                </Link>
              )
            };
            })
          }
          </div>)
        }

    </div>
  )
}

export default SearchBar