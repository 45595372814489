import React, {useState} from 'react'
import "./Home.css"
import RightSide from '../components/rightSide/RightSide.jsx'
import PostSide from '../components/postSide/PostSide.jsx'
import ProfileSide from '../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../components/upperPanel/UpperPanel'


import Chats from '../components/chats/Chats';


const Home = () => {
  const [white,setWhite] = useState(false);

  return (
    <div className="Home">
      <div className="Home-upper">
        <UpperPanel white={white}/>
      </div>
      <div className="Home-lower">
        <ProfileSide/>
        <PostSide location="homePage" />
        <RightSide white={white} setWhite={setWhite}/> 
      </div>

      <Chats/>
    </div>
  )
}

export default Home