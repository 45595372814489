import React, { useState,useEffect} from 'react';
import "./ProfileDetails.css";
import TinyPost from '../tinyPost/TinyPost';
import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,Person,NewChat,AddUser} from "../standardImages/StandardImages"

import { followUser, unfollowUser } from '../../actions/UserAction';
import { followBusiness,unfollowBusiness } from '../../actions/BusinessAction';

import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import { getUser } from '../../actions/UserAction';
import { getBusiness } from '../../actions/BusinessAction';

import FollowersModal from '../followersModal/FollowersModal';

import ProfileBio from '../profileBio/ProfileBio';
import ProfileActivities from '../profileActivities/ProfileActivities';
import { createChat,findChat,getUserChats } from '../../actions/ChatActions';

const ProfileDetails = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const params =useParams();
    const isUser = user._id === params.id;
    const [person,setPerson] = useState(user);
    const profileUserId = params.id;
    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;
    const [infoPageNmr, setInfoPageNmr] = useState(0);
    const dispatch = useDispatch();
    const [modalOpened1,setModalOpened1] = useState(false);
    const [modalOpened2,setModalOpened2] = useState(false);

    const [following, setFollowing] = useState(
        person.followers.includes(user._id)
    );

    const fetchProfileUser = async () =>{
        if (!profileUserId || profileUserId === user._id){
            setPerson(user);
            setFollowing(person.followers.includes(user._id))
        }else{
            //console.log("fetching profile user...");
            //console.log(profileUserId)
            var ref
            ref = await getUser(profileUserId);
            ref().then((userData) => setPerson(userData))
            setFollowing(person.followers.includes(user._id))
            //console.log(person1)
        }
      }

    useEffect(() => {
        try{
            fetchProfileUser();
            setFollowing(person.followers.includes(user._id));
        }catch(err){
            console.error(err)
        }
        },[isUser]);


        let { posts,loading } = useSelector((state) => state.postReducer);

        //if (!posts || posts.length === 0) return 'No Posts';
    
        if (params.id) posts = posts.filter( (post) => post.ownerId === params.id);

    if(following !== person.followers.includes(user._id)){
        setFollowing(person.followers.includes(user._id))
    }

    
    const userFollowing = () => {
        following 
        ? dispatch(unfollowUser(person._id, user))
        : dispatch(followUser(person._id, user));
        setFollowing((prev) => !prev);
    };

    const businessFollowing = () => {
        following 
        ? dispatch(unfollowBusiness(person._id, user))
        : dispatch(followBusiness(person._id, user));
        setFollowing((prev) => !prev);
    };

    const handleFollow = () => {
        user.isBusiness 
        ? businessFollowing()
        : userFollowing()

        window.location.reload();
    }

    const handleChat = async () => {
        const data = { 
            senderId : user._id,
            receiverId : person._id 
        }

        try{
            const a = await findChat(user._id, person._id)
            if (a === "no chat found"){
                
                dispatch(createChat(data));
            }else{
                //
            }
            dispatch(getUserChats(user._id));
            
        }catch(e){console.error(e);}
    }
    
    /*
    const tempactivities = [
        {
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },{
            image: "https://picsum.photos/200",
            title: "some title",
            createdAt: Date.now(),
            desc: "132123131",
        },
    ]

    {
        tempactivities.map((post,id) => {
            return <TinyPost data={post} key={id} className="profile-post-photo"/>
        })
    } */

    return (
        <div className="ProfileDetails">

            <div className="profile-profile-container">

                <div className="profile-user-statistics">
                    <span className="profile-user-statistic">Takip Edilenler <b>{person.following.length}</b></span>
                    <span className="profile-user-statistic">Takipçi Sayısı <b>{person.followers.length}</b></span>
                </div>
                {isUser ? ""
                        : following
                            ? (<div className="UnfollowButton profile-user-following-true" onClick={handleFollow}>Takiptesin</div> )
                            : (<div className="button profile-user-following-false" onClick={handleFollow}>Takip Et</div> )
                        }

                <div className="profile-profile-image">
                    <img src={ 
                        person.profilePicture
                        ? person.profilePicture
                        : defaultPicture 
                    } alt="ProfileImage" />
                </div>

                {isUser ? "" : (
                    <div className="profile-connect-buttons">
                        <button className="profile-interaction-button" onClick={() => {setModalOpened1((prev) => !prev)}}>
                            <img src={ Person } alt="" className="photo-32x32" />
                        </button>
                        <button className="profile-interaction-button-image" onClick={handleChat}>
                            <img id="profile-other-new-chat" src={ NewChat } alt="" />
                        </button>
                        <button className="profile-interaction-button-image" onClick={() => {setModalOpened2((prev) => !prev)}}>
                            <img src={ AddUser } alt="" />
                        </button>
                    </div>
                ) }

                <FollowersModal modalOpened={modalOpened1} setModalOpened={setModalOpened1} location="following" />
                <FollowersModal modalOpened={modalOpened2} setModalOpened={setModalOpened2} location="followers" />


                <div className="profile-profile-desc">
                    <span className="profile-user-statistic">{ person.firstname.charAt().toUpperCase()+person.firstname.slice(1)  } { person.lastname.charAt().toUpperCase()+person.lastname.slice(1) }</span>
                    <span className="profile-user-bio">{person.username}</span>

                    <div className="profile-profile-description">
                    <p>{person.bio}</p>
                    </div>
                </div> 

                <div className="profile-buttons">
                
                    <button className={infoPageNmr === 0 ? "button profile-btn" : "button-closed profile-btn"} onClick={() => setInfoPageNmr(0)}> Fotoğraflar </button>

                    <button className={infoPageNmr === 1 ? "button profile-btn" : "button-closed profile-btn"} onClick={() => setInfoPageNmr(1)}> Hakkında </button>

                    <button className={infoPageNmr === 2 ? "button profile-btn" : "button-closed profile-btn"} onClick={() => setInfoPageNmr(2)}> Etkinlikler </button>
                </div>

            </div>

            <div className="profile-right-info-container">
                {infoPageNmr === 0 && (
                    <div className="profile-posts-container">
                        <h6>Fotoğraflar ve Videolar</h6>
                        <div className="profile-posts">
                            { loading 
                                ? "Fetching posts...."
                                : posts.map((post,id) => {
                                    return <TinyPost data={post} key={id} className="profile-post-photo"/>
                                }) }

                                
                        </div>
                    </div>
                )}
                {infoPageNmr === 1 && (
                    <div className="profile-bio"> 
                        <ProfileBio />
                    </div>
                )}
                {infoPageNmr === 2 && (
                    <div className="profile-activities-container">
                        <h6>Tüm Etkinlikler</h6>
                        <div className="profile-activities">
                            <ProfileActivities />
                        </div>
                    </div>
                )}
            </div>


        </div>
    )
}

export default ProfileDetails