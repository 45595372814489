import React, { useState,useEffect} from 'react';
import { addVisitor } from '../../../actions/UserAction';
import { addBusinessVisitor } from '../../../actions/BusinessAction';
import './BusinessProfile.css';
import { useParams } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';

import BusinessRightSide from '../../../components/businessRightSide/BusinessRightSide.jsx'
import BusinessProfileSide from '../../../components/businessProfileSide/BusinessProfileSide.jsx'
import UpperPanel from '../../../components/upperPanel/UpperPanel'
import Chats from '../../../components/chats/Chats';
import BusinessProfileDetails from '../../../components/businessProfileDetails/BusinessProfileDetails';

const BusinessProfile = () => {
    const user = useSelector((state) => state.authReducer.authData);
    const params =useParams();
    const [userId,setUserId] = useState(params.id)
    const dispatch = useDispatch();
    const [white,setWhite] = useState(false);

    useEffect(() => {
        if(params.id){
            setUserId(params.id)
            if (userId !== user._id){
                dispatch(addBusinessVisitor(params.id, {visitorId: user._id}));  
            }
        }
    })

    if(params.id && params.id !== userId){
        window.location.reload();
    }

    return (
        <div className="BusinessProfile">

            <div className="Profile-upper">
                <UpperPanel white={white}/>
            </div>
            <div className="Profile-lower business-profile">
                <BusinessProfileSide/>
                {/* <span> sad </span> */}
                <BusinessProfileDetails />  
                <BusinessRightSide white={white} setWhite={setWhite}/> 
            </div>

            <Chats/>
            
        </div>
    );
};

export default BusinessProfile;