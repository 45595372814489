import React from 'react';
import {Modal, useMantineTheme} from '@mantine/core';
import { PurplePlusButton } from '../standardImages/StandardImages';
import moment from 'moment';
import Share from '../share/Share';


const ShareModal = ({modalOpened,setModalOpened,data}) => {
    const theme = useMantineTheme();

    /*overlayColor={
            theme.colorScheme === "dark"
            ? theme.colors.dark[9]
            : theme.colors.gray[2]
        }
        overlayOpacity = {0.55}
        overlayBlur = {3}*/

    return (
        <Modal 
        size = '55%'
        opened={modalOpened}
        onClose={() => setModalOpened(false)} 
        >
            <Share data={data} setModalOpened={setModalOpened} />
        </Modal>
    );
};

export default ShareModal