import React, {useState,useRef,useEffect} from 'react';
import "./PostShare.css";
import { UilScenery, UilPlayCircle, UilLocationPoint, UilSchedule, UilTimes } from "@iconscout/react-unicons";
import {useDispatch,useSelector} from "react-redux";
import {uploadImage,uploadPost} from "../../actions/UploadAction";
import { v4 } from "uuid";

import {defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo} from '../standardImages/StandardImages'


const PostShare = ({location, choosePopUp}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.postReducer.loading);
    const [image,setImage] = useState(null);
    const [profilePicture,setProfilePicture] = useState(null);
    const desc = useRef();
    const location1 = useRef();
    const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;

    //handle image changes
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setImage(img);
        }
    };

    const imageRef = useRef();

    //handle post upload
    const handleUpload = async (event) => {
        event.preventDefault();

        if (image === null || image === undefined) {
            return;
        }

        //post data
        const newPost = {
            ownerId: user._id,
            username: user.username,
            description: desc.current.value,
            location: location1.current.value
        };

        if(newPost.description === "" ){
            return
        }

        if (image){
            const fileName = "posts/images/" + v4() + image.name;
            const imageDat = {
                fileName : fileName,
                file : image,
            }
            //newPost.image = fileName;
            try{
                //dispatch(uploadImage(imageDat))
                const imageUrl = await uploadImage(imageDat);
                imageUrl().then((url) =>{
                    newPost.image = url;
                    
                    dispatch(uploadPost(newPost))
                    choosePopUp(1)
                })
            }catch (err) {
                console.error(err)
            }
        }else{
            newPost.image = "";
            dispatch(uploadPost(newPost))
            choosePopUp(1)
        }
        resetShare();
    };

    const resetShare = () => {
        setImage(null);
        desc.current.value = "";
        location1.current.value = "";
        imageRef.current.value = "";
    };

    return (
        <div className="PostShare">
            <div className="forum-close-button">
                <div className="header-post-create">
                    <h6>Gönderi Oluştur</h6>
                </div>
                
                <img src={CloseButton} alt="" onClick={() => choosePopUp(1)}/>
            </div>

            <div className="post-create-menu">
                
                

                <div className="post-photo-area">
                    {!image 
                        ? (<> <img className="photo-image" src={PlusLogo} alt="" onClick={() => imageRef.current.click()}/>
                            <p>Medya, Fotoğraf, Video ekleyin</p> </>) 

                    : (
                        <div className="previewImage">
                            <UilTimes className="close-btn-preview" onClick={() => {
                                                                                setImage(null)
                                                                                imageRef.current.value = ""}} />
                            <img src={URL.createObjectURL(image)} alt="preview" />
                        </div>
                    )}
                    
                    <div style={{display: "none"}}>
                        <input type="file" ref={imageRef} onChange={onImageChange} required />
                    </div>
                    
                </div>

                <div className="post-desc-area">
                    <h6>Gönderiniz İçin</h6>
                    <textarea type="text" className="post-desc forum-desc settings-profile-textarea" placeholder="Buraya yazabilirsiniz" required
                    ref = {desc}/>
                </div>

                <div className="post-location-area">
                    <h6>Konum Girin</h6>
                    <input type="text" className="post-location settings-profile-birthday" placeholder="konum" required ref={location1}/>
                </div>

                <button className="button post-share-btn" onClick={handleUpload} disabled={loading}>Paylaş</button>
            </div>

        </div>
    )

    /*
    return (
        <div className="PostShare">
            <img src={
                user.profilePicture
                ? profilePicture
                : defaultPicture
            } alt="Profile" />
            <div>
               <input 
               type="text" 
               placeholder="What's happening?"
               required
               ref = {desc}
               />
               <div className="postOptions">
                    <div className="option" style={{color: "var(--orange)"}}
                    onClick={() => imageRef.current.click()}>
                        <UilScenery/>
                        Photo
                    </div>

                    <div className="option" style={{color: "var(--orange)"}}>
                        <UilPlayCircle/>
                        Video
                    </div>

                    <div className="option" style={{ color: "var(--orange)" }}>
                        <UilLocationPoint />
                        Location
                    </div>

                    <div className="option" style={{ color: "var(--orange)" }}>
                        <UilSchedule />
                        Shedule
                    </div>
                    <button className="button ps-button" onClick={handleUpload} disabled={loading}>
                        {loading ? "uploading" : "Share"}
                    </button>

                    <div style={{display: "none"}}>
                        <input type="file" ref={imageRef} onChange={onImageChange} />
                    </div>
                </div> 

                {image && (
                    <div className="previewImage">
                        <UilTimes onClick={() => setImage(null)} />
                        <img src={URL.createObjectURL(image)} alt="preview" />
                    </div>
                )}
            </div>
        </div>
    );*/


};

export default PostShare;