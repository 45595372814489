import React from 'react'
import "./ActivityType.css"


const ActivityType = ({data,onClick}) => {
    const data1 = data || {
        image: "https://cdn.ototeknikveri.com/Files/gallery/Big/2048-150202_Fiat_Nuovo-Doblo_11.jpg",
        type: "otomobil"
    }
  return (
    <div className="ActivityType" onClick={() => onClick()}>
        <div className="Activity-container">
            <img src={data1.image} alt="" />
            <p>{data1.type}</p>
        </div>
    </div>
  )
}

export default ActivityType