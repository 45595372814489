import React, {useState, useEffect} from 'react';
import "./SingleDiscount.css"

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import { useParams } from 'react-router-dom'

import Discount from '../../components/discount/Discount';
import { getDiscount } from '../../actions/DiscountAction';

const SingleDiscount = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const discountId = params.id;
    const [discount,setDiscount] = useState(null);

    const aFunc = async () => {
        const discountData = await getDiscount(discountId);
        discountData().then((data) => {
            setDiscount(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[discount === null])

  return (
    <div className="SingleDiscount">
        <div className="SingleDiscount-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="SingleDiscount-lower">
            <ProfileSide/>
            <div className="">
                {discount === null ? "discount getiriliyor..." : (<Discount data={discount} />) }
            </div>
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default SingleDiscount