import React, { useState,useEffect} from 'react'
import './ProfileCard.css'

import {defaultCoverPicture,defaultProfileImage,defaultProfileBusiness,authPicture} from "../standardImages/StandardImages"

import {useParams,Link} from "react-router-dom";
import {useSelector} from 'react-redux'

const ProfileCard = ({location}) => {
  const user = useSelector((state) => state.authReducer.authData);
  const defaultPicture = user.isBusiness ? defaultProfileBusiness : defaultProfileImage;

  //const serverPublic = process.env.REACT_APP_PUBLIC_FOLDER;

  

  return (
    <div className="profileCard">
      <div className="upper-image">
        <img src={authPicture} alt="" />
      </div>
      
    { user.isBusiness 
      ? (<span className="following-details"></span>) 
      : (<span className="following-details">
          <span className="user-statistics">Takip Edilenler <b>{user.following.length}</b></span>
          <span className="user-statistics">Takipçi Sayısı <b>{user.followers.length}</b></span>
        </span>) }
      

      <div className="ProfileImages">
        <img src={ 
          user.profilePicture
          ? user.profilePicture
          : defaultPicture 
        } alt="ProfileImage" />
      </div>

      <div className="ProfileName">
        { user.isBusiness 
          ? <span>{ user.businessName.charAt().toUpperCase()+user.businessName.slice(1) } </span>
          : <span>{ user.firstname.charAt().toUpperCase()+user.firstname.slice(1)  } { user.lastname.charAt().toUpperCase()+user.lastname.slice(1) } </span> }
        <span>{ user.bio ? user.bio : 'Write about yourself' }</span>
      </div>


      
    </div>
  );

  /*
  return (
    <div className="profileCard">
      <div className="ProfileImages">
        <img src={
          user.coverPicture 
        ? coverPicture
        : defaultCoverPicture
      } alt="CoverImage"/>
        <img src={ 
        user.profilePicture
        ? profilePicture
        : defaultPicture 
      } alt="ProfileImage" />
      </div>
      
      <div className="ProfileName">
        <span>{ user.firstname } { user.lastname } </span>
        <span>{ user.worksAt? user.worksAt : 'Write about yourself' }</span>
      </div>
      
      
      <div className="followStatus">
        <hr/>
        <div>
          <div className="follow">
            <span>{ user.followers.length }</span>
            <span> Followers </span>
          </div>
          <div className="vl"></div>
          <div className="follow">
            <span>{ user.following.length }</span>
            <span>Following</span>
          </div>
          {/* for profile Page *//* }
          {location === "profilePage" && (
            <>
              <div className="vl"></div>
              <div className="follow">
                <span>
                  {user.isBusiness 
                    ? (discounts ? discounts.filter((discount) => discount.userId === params.id).length : 0) 
                    : (posts ? posts.filter((post) => post.userId === params.id ).length : 0) }
                </span>
                <span>{user.isBusiness ? "Discounts" : "Posts"}</span>
              </div>{" "}
            </>
          )}

        </div>
        <hr/>
      </div>
      

      {location === "homepage" &&
         (
          <span id="MyProfile">
            <Link to={'/profile/'+user._id} style={{textDecoration:"none",color:"inherit"}}>
              My Profile
              </Link>
          </span> 
        )}
      
    </div>
  );*/


};

export default ProfileCard;