import * as PostApi from '../api/PostsRequests.js';

export const getTimelinePosts = () => async (dispatch) => {
    dispatch({ type: "RETREIVING_START"});
    try {
        const {data} = await PostApi.getTimelinePosts();
        dispatch({ type: "RETREIVING_SUCCESS", data: data });
    }catch (err) {
        
        dispatch({ type: "RETREIVING_FAIL"});
        console.error(err)
    }
};

export const getPersonalPosts = (id) => async (dispatch) => {
    dispatch({ type: "RETREIVING_START"});
    try {
        const {data} = await PostApi.getPersonalPosts(id);
        
        dispatch({ type: "RETREIVING_SUCCESS", data: data });
    }catch (err) {
        
        dispatch({ type: "RETREIVING_FAIL"});
        console.error(err)
    }
};

export const sendComment = (id,data1) => async (dispatch) => {
    dispatch({ type: "COMMENT_START"});
        try {
            const {data} = await PostApi.sendComment(id,data1);
            dispatch({ type: "COMMENT_SUCCESS"});
        }catch (err) {
            
            dispatch({ type: "COMMENT_FAIL"});
            console.error(err)

        }
}

export const getPost = (id) => async (dispatch) => {
    try {
        const {data} = await PostApi.getPost(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const updatePost = (id,FormData) => async (dispatch) => {
    dispatch({type: "PUPDATING_START"})
    try {
        const {data} = await PostApi.updatePost(id, FormData);
        dispatch({type: "PUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "PUPDATING_FAIL"})
    }
}

export const deletePost = (id,FormData) => async (dispatch) => {
    dispatch({type: "PDELETE_START"})
    try {
        
        const {data} = await PostApi.deletePost(id,FormData);
        dispatch({type: "PDELETE_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "PDELETE_FAIL"})
    }
}