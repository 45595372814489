import React, {useRef,useState,useEffect} from 'react';
import "./EditDiscount.css"
import {storage} from "../../firebase"
import { updateDiscount,deleteDiscount } from '../../actions/DiscountAction';
import moment from 'moment';
import * as StandardImages from '../standardImages/StandardImages'
import { useSelector, useDispatch } from 'react-redux';
import CheckModal from '../checkModal/CheckModal';

const EditDiscount = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.discountReducer.loading);
    const dispatch = useDispatch();

    const [forum, setForum] = useState(data);
    const [modalOpened3,setModalOpened3] = useState(false);

    const handleChange = (e) => {
        setForum({...forum , [e.target.name]:e.target.value});
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        forum.userId = user._id;
        dispatch(updateDiscount(data.discountId, forum))
        window.location.reload();
    }

    const handleDelete = () => {
        try{
            const imgRef = storage.refFromURL(data.mediaUrl);
            imgRef.delete();
        }catch(error){
        }
        const deleteData = {userId: user._id};
        dispatch(deleteDiscount(data.discountId,deleteData))
      }
    
    return (
        <div className="EditDiscount">
        <span className="modal-discount-photo">
            <span className="dated">
                {moment(data.timestamp).fromNow()}
            </span>
            {data.mediaUrl ? (<img 
                src={data.mediaUrl ? data.mediaUrl : ""}
                alt= "" 
                /> ) : ""}
        </span>
        
        

        <div className="modal-info-area">
            <div className="modal-detail">
                <span>
                    <b className="modal-username">{data.businessName} </b>
                </span>
                <span className="modal-description">
                <textarea className="edit-textarea" type="text" value={forum.description} name="description" onChange={handleChange}/>
                </span>
            </div>

        </div>

        <div className="edit-buttons">
        <button
            className="button infoButton"
            type="submit"
            disabled={loading}
            onClick={handleSubmit}
            >
                {loading ? "Updating..." : "Update"}
        </button>

        <button className="button infoButton" onClick={() => setModalOpened3((prev) => !prev)}> 
                Sil
        </button>
    </div>

            <CheckModal modalOpened={modalOpened3} setModalOpened={setModalOpened3} data={ handleDelete } />


        </div>
    )
}

export default EditDiscount