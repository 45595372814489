import React, {useState} from 'react'
import "./MessagePanelUsers.css"

import {defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo} from '../standardImages/StandardImages'

import ChatsSearchbar from '../chatsSearchbar/ChatsSearchbar'

import { useSelector } from 'react-redux';

import ChatUserWide from '../chatUserWide/ChatUserWide';

const MessagePanelUsers = ({location,choosePopUp}) => {
    const [persons, setPersons] = useState([]);
    const user = useSelector((state) => state.authReducer.authData);
  

  return (
    <div className="MessagePanelUsers">
      <div className="message-panel-users-upper">
        <h6>Mesajlar</h6>
        <div className="forum-close-button">
          <img src={CloseButton} alt="" onClick={() => choosePopUp(2)}/>
        </div>
      </div>
        
        
        <div className="user-searchbar">
            <ChatsSearchbar persons={persons} setPersons={setPersons} />
        </div>

        <div className="chat-user-list">
            {persons.length <= 0 ? "No Friends" : (persons.map((person, id) => {
                if (user._id !== person._id) {
                  return <ChatUserWide person={person} key={id} />
                };
              }))}
        </div>
    </div>
  )
}

export default MessagePanelUsers