import React, {useEffect, useState } from 'react'
import "./ProfileSide.css"
import ProfileCard from "../profileCard/ProfileCard.jsx"
import Toolbar from '../toolbar/Toolbar';
import SocialNavbar from '../socialNavbar/SocialNavbar';
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import FollowersCard from '../followersCard/FollowersCard';
import Notifications from '../notifications/Notifications';
import MyMarketplace from '../myMarketplace/MyMarketplace';
import MyActivities from '../myActivities/MyActivities';


const ProfileSide = ({location}) => {
  const user = useSelector((state) => state.authReducer.authData);

  const [isFriends,setIsFriends] = useState(false);
  const [isFollower,setIsFollower] = useState(false);
  const [isNotifications,setNotifications] = useState(false);
  const [isAdvertisement,setAdvertisement] = useState(false);
  const [isActivities,setActivities] = useState(false);

  const choosePopUp = (selection) => {
      switch (selection){
        case 1:
          setIsFriends((prev) => !prev);
          setIsFollower(false);
          setNotifications(false);
          setAdvertisement(false);
          setActivities(false);
          break;

        case 2:
          setIsFollower((prev) => !prev);
          setIsFriends(false);
          setNotifications(false);
          setAdvertisement(false);
          setActivities(false);
          break;

        case 3:
          setNotifications((prev) => !prev);
          setIsFriends(false);
          setIsFollower(false);
          setAdvertisement(false);
          setActivities(false);
          break;

        case 4:
          setAdvertisement((prev) => !prev);
          setIsFriends(false);
          setIsFollower(false);
          setNotifications(false);
          setActivities(false);
          break;

        case 5:
          setActivities((prev) => !prev);
          setIsFriends(false);
          setIsFollower(false);
          setNotifications(false);
          setAdvertisement(false);
          break;

        default:
          setAdvertisement(false);
          setIsFriends(false);
          setIsFollower(false);
          setNotifications(false);
          setActivities(false);
          break;
      }
  }

  return (
    <div className="ProfileSide">
      <div className="left-left-panel">
        {isFriends ? <FollowersCard location="following"/>  : ""}
        {isFollower ? <FollowersCard location="followers"/> : ""}
        {isNotifications ? <Notifications/> : ""}
        {isAdvertisement ? <MyMarketplace /> : ""}
        {isActivities ? <MyActivities /> : ""}

      </div>
      
      <div className="left-main-panel">
          <div className="info-header">
            <h6 className="info-text">Bildirimler</h6>
          </div>
          {location !== "profile" && (
            <Link to={"../profile/"+user._id} className="profile-side-profile">
              <ProfileCard location='homepage' person={user} /> 
            </Link>
          )}
          
        <Toolbar choosePopUp={choosePopUp}/>
        <div className="info-header">
          <h6 className="info-text">Sosyal</h6>
        </div>
        <SocialNavbar/>
      </div>
    </div>
  )
}

export default ProfileSide