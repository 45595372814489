import * as AdApi from "../api/MarketplaceRequests";

export const getAllAds = () => async (dispatch) => {
    dispatch({ type: "ADRETREIVING_START"});
    try {
        const {data} = await AdApi.getAllAds();
        dispatch({ type: "ADRETREIVING_SUCCESS", data: data });
    }catch (err) {
        
        dispatch({ type: "ADRETREIVING_FAIL"});
        console.error(err)
    }
};

export const sendAdComment = (id,data1) => async (dispatch) => {
    dispatch({ type: "ADCOMMENT_START"});
        try {
            const {data} = await AdApi.sendAdComment(id,data1);
            dispatch({ type: "ADCOMMENT_SUCCESS"});
        }catch (err) {
            
            dispatch({ type: "ADCOMMENT_FAIL"});
            console.error(err)

        }
}


export const getAdvertisement = (id) => async (dispatch) => {
    try {
        const {data} = await AdApi.getAdvertisement(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const updateAdvertisement = (id,FormData) => async (dispatch) => {
    dispatch({type: "ADUPDATING_START"})
    try {
        const {data} = await AdApi.updateAdvertisement(id, FormData);
        dispatch({type: "ADUPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "ADUPDATING_FAIL"})
    }
}

export const deleteAdvertisement = (id,FormData) => async (dispatch) => {
    dispatch({type: "ADDELETE_START"})
    try {
        const {data} = await AdApi.deleteAdvertisement(id,FormData);
        dispatch({type: "ADDELETE_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "ADDELETE_FAIL"})
    }
}