import React, {useState, useEffect} from 'react';
import "./SingleActivity.css"

import RightSide from '../../components/rightSide/RightSide.jsx'
import ProfileSide from '../../components/profileSide/ProfileSide.jsx'
import UpperPanel from '../../components/upperPanel/UpperPanel'
import Chats from '../../components/chats/Chats';
import { useParams } from 'react-router-dom'

import Activity from '../../components/activity/Activity';
import { getActivity } from '../../actions/ActivityAction';

const SingleActivity = () => {
    const [white,setWhite] = useState(false);
    const params = useParams();
    const activityId = params.id;
    const [activity,setActivity] = useState(null);

    const aFunc = async () => {
        const activityData = await getActivity(activityId);
        activityData().then((data) => {
            setActivity(data);
        })
      }   
  
      useEffect(() => {
        aFunc();
      },[activity === null])

  return (
    <div className="SingleActivity">
        <div className="SingleActivity-upper">
            <UpperPanel white={white}/>
        </div>
        <div className="SingleActivity-lower">
            <ProfileSide/>
            <div className="">
                {activity === null ? "activity getiriliyor..." : (<Activity data={activity} />) }
            </div>
            <RightSide white={white} setWhite={setWhite}/>
        </div>

        <Chats/>
    </div>
  )
}

export default SingleActivity