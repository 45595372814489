import React, { useState } from 'react'
import "./SocialNavbar.css";
import MenuItem from "../menuItem/MenuItem";
import { Link } from "react-router-dom";

import { Forum,Calendar,Discounts,Cart, Cafeteria } from '../standardImages/StandardImages';


const SocialNavbar = () => {

  return (
    <div className="socialNavbar">
        <ul className="s-navbar-menu">
          <li className="s-nb-menu-item">
            <Link to="../forums" >
              <MenuItem navLogo={Forum} navName="Forum" />
            </Link>
          </li>
          <li className="s-nb-menu-item">
            <Link to="../activities" >
              <MenuItem navLogo={Calendar} navName="Etkinlik" />
            </Link>
          </li>
          <li className="s-nb-menu-item">
            <Link to="../discounts" >
              <MenuItem navLogo={Discounts} navName="Kampanyalar" />
            </Link>
          </li>
          <li className="s-nb-menu-item">
            <Link to="../marketplace" >
              <MenuItem navLogo={Cart} navName="İlanlar" />
            </Link>
          </li>
          <li className="s-nb-menu-item">
            <Link to="../cafeteria" >
              <MenuItem navLogo={Cafeteria} navName="Yemekhane" />
            </Link>
          </li>
        </ul>
    </div>
  )
}

export default SocialNavbar