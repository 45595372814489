import React, {useEffect, useState } from 'react'
import './RightSide.css'
import { useSelector } from 'react-redux'

import CreateNavbar from '../createNavbar/CreateNavbar';
import SponsoredAds from '../sponsoredAds/SponsoredAds';

import PostShare from '../PostShare/PostShare';
import FormShare from '../formShare/FormShare';
import MarketplaceShare from '../marketplaceShare/MarketplaceShare';
import MessagePanelUsers from '../messagePanelUsers/MessagePanelUsers';
import CreateActivity from '../createActivity/CreateActivity';


const RightSide = ({white,setWhite}) => {
  const user = useSelector((state) => state.authReducer.authData);

  const [isPost,setIsPost] = useState(false);
  const [isChats,setIsChats] = useState(false);
  const [isActivity,setIsActivity] = useState(false);
  const [isForum,setIsForum] = useState(false);
  const [isMarketing,setIsMarketing] = useState(false);
  var lastSelection = -1;

  const choosePopUp = (selection) => {
    switch (selection){
      case 1:
        setIsPost((prev) => !prev);
        setWhite((prev) => !prev);
        setIsChats(false);
        setIsActivity(false);
        setIsForum(false);
        setIsMarketing(false);
        break;

      case 2:
        setIsPost(false);
        setIsChats((prev) => !prev);
        setWhite((prev) => !prev);
        setIsActivity(false);
        setIsForum(false);
        setIsMarketing(false);
        break;

      case 3:
        setIsPost(false);
        setIsChats(false);
        setIsActivity((prev) => !prev);
        setWhite((prev) => !prev);
        setIsForum(false);
        setIsMarketing(false);
        break;

      case 4:
        setIsPost(false);
        setIsChats(false);
        setIsActivity(false);
        setIsForum((prev) => !prev);
        setWhite((prev) => !prev);
        setIsMarketing(false);
        break;

      case 5:
        setIsPost(false);
        setIsChats(false);
        setIsActivity(false);
        setIsForum(false);
        setIsMarketing((prev) => !prev);
        setWhite((prev) => !prev);
        break;

      default:
        setIsPost(false);
        setIsChats(false);
        setIsActivity(false);
        setIsForum(false);
        setIsMarketing(false);
        setWhite(false);
        break;
    }
  }

    if(isPost || isChats || isActivity || isMarketing || isForum){
      setWhite(true);
    }else{
      setWhite(false);
    }

  return (
    <div className="RightSide">
        <div className="rightSide-left-panel">
          <div className="info-header">
            <h6 className="info-text">Oluştur</h6>
          </div>
          
          <CreateNavbar choosePopUp={choosePopUp}/>
          
          <div className="info-header">
            <h6 className="info-text">Sponsorlu Kampanyalar</h6>
          </div>
          <div className="sponsored-ads-width">
            <SponsoredAds/>
          </div>

        </div>
        <div className="rightSide-right-panel">
          { isPost ? <PostShare location="rightSide" choosePopUp={choosePopUp} /> : "" }
          { isChats ? <MessagePanelUsers location="rightSide" choosePopUp={choosePopUp}/> : "" }
          { isActivity ? <CreateActivity choosePopUp={choosePopUp}/> : "" }
          { isForum ? <FormShare location="rightSide" choosePopUp={choosePopUp} /> : "" }
          { isMarketing ? <MarketplaceShare location="rightSide" choosePopUp={choosePopUp} /> : "" }
        </div>
    </div>
  );
};

export default RightSide