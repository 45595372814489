import * as UserApi from "../api/UserRequests.js";
import { encrypt } from "./EncryptAction.js";

export const updateUser = (id, FormData) => async (dispatch) => {
    dispatch({type: "UPDATING_START"})
    try {
        const {emailVerified,timestamp, ...other}  = FormData;
        const {data} = await UserApi.updateUser(id, other);
        data.emailVerified = emailVerified;
        dispatch({type: "UPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "UPDATING_FAIL"})
    }
}

export const followUser = (id, Data) => async (dispatch) => {
    dispatch({type: "FOLLOW_USER", data: id});
    UserApi.followUser(id, Data);
    
}

export const unfollowUser = (id, Data) => async (dispatch) => {
    dispatch({type: "UNFOLLOW_USER", data: id});
    UserApi.unfollowUser(id, Data);
    
}

export const getUser = (id) => async (dispatch) => {
    encrypt("sdasdadsad")
    try {
        const {data} = await UserApi.getUser(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const getSecretUser = (id) => async (dispatch) => {
    encrypt("sdasdadsad")
    try {
        const {data} = await UserApi.getSecretUser(id);
        return data
    }catch (err) {
        console.error(err)
    }
}

export const addVisitor = (id, Data) => async (dispatch) => {
    dispatch({type: "ADDING_START"})
    try {
        const {data} = await UserApi.addVisitor(id, Data);
        dispatch({type: "ADDING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "ADDING_FAIL"})
        console.error(err)
    }
}

export const getIKData = (id,navigate = null) => async (dispatch=null) => {
    
    try{
        const {data} = await UserApi.getUserIkData(id);
        
        if (dispatch !== null){
            dispatch({type: "IK_UPDATING_SUCCESS", data: data})
        }
        if (navigate){
            navigate("../home", { replace: true });
        }
        return data;
    }catch(err){
        console.error(err);
        if (navigate){
            navigate("../home", { replace: true });
        }
    }
}

export const updateUserIK = (id, FormData) => async (dispatch) => {
    dispatch({type: "IK_UPDATING_START"})
    try {
        const {data} = await UserApi.updateUserIkData(id, FormData);
        
        dispatch({type: "IK_UPDATING_SUCCESS", data: data})
    }catch (err) {
        dispatch({type: "IK_UPDATING_FAIL"})
    }
}