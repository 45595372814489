import Image1 from '../../img/profile_pitcure_pljusx.png';
import Image2 from '../../img/cover.jpg';
import Like from '../../img/Artboard – 56.png';
import logo from '../../img/CAMPUS.png';
import comment from '../../img/Artboard – 60.png';
import home from '../../img/home.png';
import noti from '../../img/noti.png';
import dislike from '../../img/Artboard – 55.png';
import share from '../../img/Artboard – 58.png';
import DefaultProfileBusiness from '../../img/PP_business.png';
import AuthPicture from '../../img/1.png';
import amazoiLogo from '../../img/3.png';
import eye from '../../img/Artboard – 65.png';
import person from '../../img/Artboard – 62.png';
import banner from '../../img/Artboard – 28.png';
import upperPanelBackground from '../../img/upper_panel_background.png';
import alarm from '../../img/Artboard – 35.svg';
import cart from '../../img/Artboard – 32.svg';
import calendar from '../../img/Artboard – 30.svg';
import users from '../../img/Artboard – 33.svg'; 
import cog from '../../img/Artboard – 37.svg';
import picture from '../../img/picture.svg';
import closeButton from '../../img/close-button.png';
import plusLogo from '../../img/plus_logo.png';
import onlineBar from '../../img/online_bar.png';
import purplePlusButton from '../../img/purple_plus_button.png';
import openedButton from '../../img/opened_button.png';
import closedButton from '../../img/closed_button.png';
import ikLarge from '../../img/IK_LARGE.png';
import addUser from '../../img/Artboard – 34.svg';
import createPost from '../../img/Artboard – 39.svg';
import chatsPng from '../../img/Artboard – 40.svg';
import createActivity from '../../img/Artboard – 41.svg';
import createForum from '../../img/Artboard – 42.svg';
import createAdvertisement from '../../img/Artboard – 43.svg';
import forum from '../../img/Artboard – 29.svg';
import discounts from '../../img/Artboard – 31.svg';
import newChat from '../../img/Artboard – 49.svg';
import cafeteria from '../../img/Artboard – 54.png';
import searchGlass from '../../img/Artboard – 59.png';
import searchMenu from '../../img/Artboard – 61.png';
import closedEye from '../../img/Artboard – 66.png';
import chatIcon from '../../img/Artboard – 50.svg';
import attention from '../../img/Artboard – 44.svg';
import closeButton2 from '../../img/Artboard – 45.svg';
import businessLogo from '../../img/businessLogo.png';
import amazoiLogoBs from '../../img/Artboard – 74.png';
import personBs from '../../img/Artboard – 78.png';
import eyeBs from '../../img/ETE.png';
import authPictureBs from '../../img/Artboard – 77.png';
import closedEyeBs from '../../img/Artboard – 79.png';
import pencil from '../../img/edit_icon.png';
import trash from '../../img/ios-trash.svg';
import chatT from '../../img/chat.png';
import cinemaT from '../../img/cinema.png';
import danceT from '../../img/dance.png';
import holidayT from '../../img/holiday.png';
import meetT from '../../img/meet.png';
import musicT from '../../img/music.png';
import partyT from '../../img/party.png';
import sporT from '../../img/spor.png';
import studyingT from '../../img/studiying.png';
import travelT from '../../img/travel.png';


export const defaultProfileImage = Image1;

export const defaultCoverPicture = Image2;

export const Heart = Like;

export const Logo = logo;

export const Comment = comment;

export const Home = home;

export const Noti = noti;

export const Dislike = dislike;

export const Share = share;

export const defaultProfileBusiness = DefaultProfileBusiness;

export const authPicture = AuthPicture;

export const AmazoiLogo = amazoiLogo;

export const Person = person;

export const Eye = eye;

export const Banner = banner;

export const UpperPanelBackground = upperPanelBackground;

export const Alarm = alarm;

export const Cart = cart;

export const Calendar = calendar;

export const SettingsIcon = cog;

export const Users = users;

export const Picture = picture;

export const CloseButton = closeButton;

export const PlusLogo = plusLogo;

export const OnlineBar = onlineBar;

export const PurplePlusButton = purplePlusButton;

export const OpenedButton = openedButton;

export const ClosedButton = closedButton;

export const IKLarge = ikLarge;

export const AddUser = addUser;

export const CreatePost = createPost;

export const ChatsPng = chatsPng;

export const CreateActivity = createActivity;

export const CreateForum = createForum;

export const CreateAdvertisement = createAdvertisement;

export const Forum = forum;

export const Discounts = discounts;

export const NewChat = newChat;

export const Cafeteria = cafeteria;

export const SearchGlass = searchGlass;

export const SearchMenu = searchMenu;

export const ClosedEye = closedEye;

export const ChatIcon = chatIcon;

export const Attention = attention;

export const CloseButton2 = closeButton2;

export const BusinessLogo = businessLogo;

export const AmazoiLogoBs = amazoiLogoBs;

export const PersonBs = personBs;

export const EyeBs = eyeBs;

export const AuthPictureBs = authPictureBs;

export const ClosedEyeBs = closedEyeBs;

export const Pencil = pencil;

export const Trash = trash;

export const ChatT = chatT;

export const CinemaT = cinemaT;

export const DanceT = danceT;

export const HolidayT = holidayT;

export const MeetT = meetT

export const MusicT = musicT;

export const PartyT = partyT;

export const SporT = sporT;

export const StudyingT = studyingT;

export const TravelT = travelT;