import React, {useEffect, useState } from 'react';
import "./BusinessRightSide.css";
import { useSelector } from 'react-redux'

import SponsoredAds from '../sponsoredAds/SponsoredAds';

import DiscountShare from '../discountShare/DiscountShare';

import BusinessCreateNavbar from '../businessCreateNavbar/BusinessCreateNavbar';

const BusinessRightSide = ({white,setWhite}) => {
    const user = useSelector((state) => state.authReducer.authData);

    const [isDiscount,setIsDiscount] = useState(false);

    const choosePopUp = (selection) => {
        switch (selection){

            case 1:
            setWhite((prev) => !prev);
            setIsDiscount((prev) => !prev);
            break;

            default:
            setIsDiscount(false);
            setWhite(false);
            break;
        }
    }

    if(isDiscount){
        setWhite(true);
    }else{
        setWhite(false);
    }

    return (
        <div className="BusinessRightSide">
            <div className="rightSide-left-panel">
                <div className="info-header">
                <h6 className="info-text">Oluştur</h6>
                </div>
                
                <BusinessCreateNavbar choosePopUp={choosePopUp}/>
                
                <div className="info-header">
                <h6 className="info-text">Sponsorlu Kampanyalar</h6>
                </div>
                <div className="sponsored-ads-width">
                <SponsoredAds/>
                </div>

            </div>
            <div className="rightSide-right-panel">
                { isDiscount ? <DiscountShare location="rightSide" choosePopUp={choosePopUp} /> : "" }
            </div>
        </div>
    )
}

export default BusinessRightSide