import React, {useState,useRef} from 'react';
import "./MarketplaceShare.css";

import { v4 } from "uuid";
import { defaultProfileImage,defaultProfileBusiness,CloseButton,PlusLogo} from '../standardImages/StandardImages'
import { useDispatch,useSelector} from "react-redux";
import {uploadImage,} from "../../actions/UploadAction";
import { UilTimes } from "@iconscout/react-unicons";
import {addAd} from "../../actions/UploadAction.js";

const MarketplaceShare = ({location, choosePopUp}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const loading = useSelector((state) => state.postReducer.loading);
    const [image,setImage] = useState(null);
    const imageRef = useRef();
    const desc = useRef();
    const price = useRef();
    const checkRemove = useRef(); 

    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]){
            let img = event.target.files[0];
            setImage(img);
        }
    };

    const handleUpload = async (event) => {
        event.preventDefault();

        if (image === null || image === undefined) {
            return;
        }

        //post data
        const newPost = {
            ownerId: user._id,
            username: user.username,
            description: desc.current.value,
            price: price.current.value,
            checkRemove: false
        };

        if(newPost.description === "" || newPost.price === "" ){
            return
        }

        if (image){
            const fileName = "ads/images/" + v4() + image.name;
            const imageDat = {
                fileName : fileName,
                file : image,
            }
            //newPost.image = fileName;
            try{
                //dispatch(uploadImage(imageDat))
                const imageUrl = await uploadImage(imageDat);
                imageUrl().then((url) =>{
                    newPost.image = url;
                    dispatch(addAd(newPost))
                    choosePopUp(5)
                })
            }catch (err) {
                console.error(err)
            }
        }else{
            newPost.image = "";
            dispatch(addAd(newPost))
            choosePopUp(5)
        }
        resetShare();
    };

    const resetShare = () => {
        setImage(null);
        desc.current.value = "";
        price.current.value = "";
        checkRemove.current.value = false;
        imageRef.current.value = "";
    };

    /*
    <div className="marketplace-checkRemove">
                    <h6>Satıldıktan sonra kaldır</h6>
                    <input type="checkbox" className="marketplace-remove-check settings-profile-birthday" ref={checkRemove} />
                </div>
                */

    return (
        <div className="MarketplaceShare">
            <div className="forum-close-button">
                <div className="header-post-create">
                    <h6>İlan Oluştur</h6>
                </div>

                <img src={CloseButton} alt="" onClick={() => choosePopUp(5)}/>
            </div>

            <div className="marketplace-post-create-container">

                <div className="marketplace-post-image">
                    {!image 
                        ? (<> <img className="photo-image" src={PlusLogo} alt="" onClick={() => imageRef.current.click()}/>
                            <p>Medya, Fotoğraf, Video ekleyin</p> </>) 

                    : (
                        <div className="previewImage">
                            <UilTimes className="close-btn-preview" onClick={() => {
                                                                                setImage(null)
                                                                                imageRef.current.value = ""}} />
                            <img src={URL.createObjectURL(image)} alt="preview" />
                        </div>
                    )}
                
                    <div style={{display: "none"}}>
                        <input type="file" ref={imageRef} onChange={onImageChange} required />
                    </div>
                </div>

                <div className="marketplace-desc-area">
                    <h6>İlanınız İçin bir şeyler yazın</h6>
                    <textarea type="text" className="post-desc forum-desc settings-profile-textarea" placeholder="Buraya yazabilirsiniz" required
                    ref = {desc}/>
                </div>
                    
                <div className="marketplace-price-area">
                    <h6>İlanınız İçin fiyat girin</h6>
                    <input type="number" className="settings-profile-birthday" ref={price} placeholder="Buraya yazabilirsiniz" />  
                </div>

                <button className="button post-share-btn" onClick={handleUpload} disabled={loading}>Paylaş</button>
            </div>
        </div>
    )
}

export default MarketplaceShare