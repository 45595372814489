import React, { useState,useEffect} from 'react'
import "./TinyDiscount.css";
import { useSelector, useDispatch } from 'react-redux';

import DiscountModal from '../discountModal/DiscountModal';

const TinyDiscount = ({data}) => {
    const user = useSelector((state) => state.authReducer.authData);
    const [modalOpened,setModalOpened] = useState(false);

    return (
        <div className="TinyPost">
            <img src={data.mediaUrl} alt="" onClick={() => setModalOpened((prev) => !prev)}/>

            <DiscountModal data={data} modalOpened={modalOpened} setModalOpened={setModalOpened} />
        </div>
    )
}

export default TinyDiscount