import React, {useEffect,useRef,useState} from 'react';
import "./MyActivities.css"

import ActivityTinyCard from '../activityTinyCard/ActivityTinyCard';
import {useParams} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import { getPersonalActivities } from '../../actions/ActivityAction';

import ViewportList from "react-viewport-list";

const MyActivities = () => {
    const params =useParams();
    const dispatch =useDispatch();
    const user = useSelector((state) => state.authReducer.authData);
    const ref = useRef(null);

    const [myActivities,setActivities] = useState([]);
    const [loading,setLoading] = useState(true);

    useEffect(() => {
        
        const aas = getPersonalActivities(user._id)
        aas().then((activities) =>{
         setActivities(activities);
         setLoading(false);
     });
 
     },[])
    
    /*myActivities.map((post,id) => {
                return <ActivityTinyCard data={post} key={id} />
            }
            )*/

  return (
    <div className="MyActivities">
        <div className="marketplace-header">
            <h6>Katıldığım Etkinlikler</h6>
        </div>

        <div className="my-activities-container" ref={ref}>
        {loading 
            ? "Fetching posts...."
            : <ViewportList viewportRef={ref} items={myActivities} itemMinSize={10} margin={8}>
            {(post,id) => {
                return <ActivityTinyCard data={post} key={id} />
            }}
            </ViewportList>
            
            }
        </div>
    </div>
  )
}

export default MyActivities