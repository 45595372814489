import React, {useEffect, useState } from 'react'

import "./BusinessProfileSide.css";
import LogoSearch from "../logoSearch/LogoSearch";
import ProfileCard from "../profileCard/ProfileCard";

import Toolbar from '../toolbar/Toolbar';
import BusinessNavbar from '../businessNavbar/BusinessNavbar';

import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom';

import Notifications from '../notifications/Notifications';

const BusinessProfileSide = () => {
    const user = useSelector((state) => state.authReducer.authData);

    const [isNotifications,setNotifications] = useState(false);


    const choosePopUp = (selection) => {
        switch (selection){

          case 1:
            setNotifications((prev) => !prev);    
            break;

          default:
            setNotifications(false);
            break;
        }
    }

    /*
    <LogoSearch />
            <ProfileCard location="homepage" person={user} />
            */

    return(
        <div className="BusinessProfileSide">

            <div className="left-left-panel">
                { isNotifications ? <Notifications/> : "" }
            </div>


            <div className="left-main-panel">
                <div className="info-header business-profile-side">
                    <h6 className="info-text">Bildirimler</h6>
                </div>
                <Link to={"../business/profile/"+user._id} className="profile-side-profile">
                {  <ProfileCard location='homepage' person={user} />  }
                </Link>
                <Toolbar choosePopUp={choosePopUp}/>
                <div className="info-header">
                <h6 className="info-text">Sosyal</h6>
                </div>
                <BusinessNavbar/>
            </div>


            
        </div>
    )
}

export default BusinessProfileSide