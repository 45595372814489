import React, {useState} from 'react'
import BusinessRightSide from "../../../components/businessRightSide/BusinessRightSide";
import BusinessProfileSide from "../../../components/businessProfileSide/BusinessProfileSide";
import Discounts from "../../../components/discounts/Discounts";
import UpperPanel from '../../../components/upperPanel/UpperPanel'
import "./BusinessHome.css";

const BusinessHome = () => {
    const [white,setWhite] = useState(false);

    /*
    <BusinessProfileSide/>
            <div className="BusinessHomeDiscounts">
                <PostShare location="businessHome"/>
                { <Discounts/> }
            </div>
            <RightSide/>
    */

    return(
        <div className="BusinessHome">
            <div className="Home-upper">
                <UpperPanel white={white}/>
            </div>

            <div className="Home-lower business-home">
                <BusinessProfileSide/>
                { <Discounts/> }
                <BusinessRightSide white={white} setWhite={setWhite}/> 
            </div>
        </div>
    )
}

export default BusinessHome;